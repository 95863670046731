import axios from "axios";
import { getCustomerToken } from "../../Functions/Auth/getToken";
import { IoBody } from "react-icons/io5";
const Url = require("../../backendURL");
const accountId = localStorage.getItem("userId");

/*************************************
 * Item
 ***********************************/

/** Get All
 ************************/

export const getAllItems = async () => {
  try {
    const serviceRes = await getAllServices();
    const productRes = await getAllProducts();
    const membershipRes = await getAllMemberships();
    const item = [...serviceRes, ...productRes, ...membershipRes];
    return item;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Business
 ************************/

export const getAllItemsByBusiness = async (businessId) => {
  let item;

  try {
    const serviceRes = await getAllServicesByBusinessId(businessId);
    const productRes = await getAllProductsByBusinessId(businessId);
    const membershipRes = await getAllMembershipByBusinessId(businessId);
    const item = [...serviceRes, ...productRes, ...membershipRes];
    return item;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Paginated Get By Business
 ************************/
export const getPaginatedItemsByBusiness = async ({
  businessID,
  pageNumber,
}) => {
  console.log(businessID, pageNumber);

  try {
    const res = await axios.get(
      `${Url}memberships/business/paginated/get-by-id`,
      {
        params: {
          businessID,
          pageNumber,
        },
      },
    );

    if (res.status === 200) {
      const data = res.data;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching memberships by business.", err);
  }
};

/** Get items from id array
 ************************/

export const getItemsByIdArray = async (ids) => {
  try {
    const promises = ids.map(async (id) => {
      return await getMembershipById(id);
    });

    const items = await Promise.all(promises);
    return items;
  } catch (err) {
    console.log(err); // Rethrow error to handle it outside
  }
};

/** Get By Category
 ************************/

export const getAllItemsByCategory = async (category) => {
  try {
    const serviceRes = await getServicesByCategory(category);
    const productRes = await getProductsByCategory(category);
    const membershipRes = await getMembershipByCategory(category);
    let data = [...serviceRes, ...productRes, ...membershipRes];

    data = data.sort(
      (a, b) => (b?.numSubscribers || 0) - (a.numSubscribers || 0),
    );

    let itemSP = [];
    let itemNormal = [];

    Promise.all(
      data.map((d) => {
        if (d?.isSellerPlus) {
          itemSP.push(d);
        } else {
          itemNormal.push(d);
        }
      }),
    );

    return [...itemSP, ...itemNormal];
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Set user Location
 ************************/

export const patchUserLocation = async (address) => {
  try {
    const token = await getCustomerToken();
    const response = await axios.patch(
      `${Url}accounts/setAddress/${accountId}`,
      { address },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log("patch", response);
    if (response.status == 200) {
      // const data = await response.data.doc
      // return data
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By location
 ************************/
export const getNearItems = async (r) => {
  try {
    const token = await getCustomerToken("/user");
    const businessIds = await axios.post(
      `${Url}accounts/businessNearMe/${accountId}`,
      { radius: r },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    // seller+ acc
    let accSP = [];
    let acc = [];
    let accBSP = [];
    let accB = [];
    if (businessIds.status == 200) {
      const businessRes = businessIds.data.docs;
      const itemsPromises = businessRes.map(async (business) => {
        const itemsResponse = await getAllMembershipByBusinessId(business._id);
        // if seller + => accSeller
        if (business.isSellerPlus) {
          accSP.push(...itemsResponse);
          accBSP.push(business);
        } else {
          // normal acc should randmized
          acc.push(...itemsResponse);
          accB.push(business);
        }
      });
      await Promise.all(itemsPromises);
      const itemResult = [...accSP, ...acc];
      const businessResult = [...accBSP, ...accB];

      return [itemResult, businessResult];
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Location: Only Business
 ************************/
export const getNearBusinesses = async (r) => {
  try {
    const token = await getCustomerToken("/user");
    const businessIds = await axios.post(
      `${Url}accounts/businessNearMe/${accountId}`,
      { radius: r },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    let accBSP = [];
    let accB = [];
    if (businessIds.status == 200) {
      let businessRes = businessIds.data.docs;
      businessRes = businessRes.filter(
        (business) =>
          business?.avatarImg !== "sample" && business?.avatarImg !== "-1",
      );
      Promise.all(
        businessRes.map((business) => {
          if (business.isSellerPlus) {
            accBSP.push(business);
          } else {
            accB.push(business);
          }
        }),
      );

      const businessResult = [...accBSP, ...accB];

      return businessResult;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Service
 ***********************************/

/** Get All
 ************************/

export const getAllServices = async () => {
  try {
    const response = await axios.get(Url + "services");
    if (response.status == 200) {
      return response.data.docs;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Id
 ************************/

export const getServiceById = async (id) => {
  try {
    const response = await axios.get(`${Url}services/${id}`);
    if (response.status == 200) {
      const data = await response.data.doc;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Business
 ************************/

export const getAllServicesByBusinessId = async (id) => {
  try {
    const response = await axios.get(`${Url}services/business/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Category
 ************************/

export const getServicesByCategory = async (category) => {
  try {
    const response = await axios.get(`${Url}services/search/${category}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Checkout
 ************************/

export const checkoutService = async (data) => {
  let promisedData = await data;
  try {
    const token = await getCustomerToken(
      `/productDetail/${promisedData?.itemType}/${promisedData?.business}/${promisedData?._id}`,
    );
    const response = await axios.post(
      `${Url}stripe/create-checkout-session`,
      promisedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status == 200) {
      return response.data.link;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw "Create Checkout Session failed";
  }
};

export const handleFreeTrialMembershipCheckout = async(body) => {

  const token = await getCustomerToken(`/customer/productDetail/${body?.itemType}/${body?.business}/${body?._id}`)
  const res = await axios.post(`${Url}stripe/membershipFTCheckoutSession`,body,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
    if (res.status == 200) {
      return res.data.link;
    } else {
      return [];
    }
}

/*************************************
 * Product
 ***********************************/

/** Get All
 ************************/

export const getAllProducts = async () => {
  try {
    const response = await axios.get(Url + "products");
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Id
 ************************/

export const getProductsById = async (id) => {
  try {
    const response = await axios.get(`${Url}products/${id}`);
    if (response.status == 200) {
      const data = await response.data.doc;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Business
 ************************/

export const getAllProductsByBusinessId = async (id) => {
  try {
    const response = await axios.get(`${Url}products/business-products/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Category
 ************************/

export const getProductsByCategory = async (category) => {
  try {
    const response = await axios.get(`${Url}products/search/${category}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Checkout
 ************************/

export const checkoutProduct = async (data) => {
  let promisedData = await data;
  try {
    const token = await getCustomerToken(
      `/productDetail/${promisedData?.itemType}/${promisedData?.business}/${promisedData?._id}`,
    );
    const response = await axios.post(
      `${Url}stripe/productCheckoutSession`,
      promisedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status == 200) {
      return response.data.link;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw "some error happened";
  }
};

/*************************************
 * Membership
 ***********************************/

/** Get All
 ************************/

export const getAllMemberships = async () => {
  try {
    const response = await axios.get(Url + "memberships");
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Id
 ************************/

export const getMembershipById = async (id) => {
  try {
    const response = await axios.get(`${Url}memberships/${id}`);
    if (response.status == 200) {
      const data = await response.data.doc;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get All Membership by Query
 ************************/

export const getMembershipByQuery = async (keyword) => {
  try {
    const response = await axios.get(`${Url}memberships/searchText/${keyword}`);
    if (response.status == 200) {
      let data = response.data.docs;
      data = await data.sort(
        (a, b) => (b?.numSubscribers || 0) - (a?.numSubscribers || 0),
      );

      let itemSP = [];
      let itemNormal = [];

      Promise.all(
        data.map((d) => {
          if (d.isSellerPlus) {
            itemSP.push(d);
          } else {
            itemNormal.push(d);
          }
        }),
      );

      let sortedData = [...itemSP, ...itemNormal];
      return sortedData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Business
 ************************/

export const getAllMembershipByBusinessId = async (id) => {
  try {
    const response = await axios.get(`${Url}memberships/business/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get By Category
 ************************/

export const getMembershipByCategory = async (category) => {
  try {
    const response = await axios.get(`${Url}memberships/search/${category}`);
    if (response.status == 200) {
      const data = await response.data.docs;

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Checkout
 ************************/

export const checkoutMembership = async (body) => {
  try {
    const token = await getCustomerToken();
    const response = await axios.post(
      `${Url}stripe/membershipCheckoutSession`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status == 200) {
      return response.data.link;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw "some error happened";
  }
};
