import React, { useEffect, useState, useContext } from "react";
import { getFavoriteSellers } from "../../../../CustomerStateManagement/FeedState";
import BusinessCard from "../BusinessComponents/BusinessCard";
import SearchBusinessCard from "../../../SearchResult/Components/SearchBusinessCard";
import { FeedContext } from "../../../../Routes/CustomerRoutes";

const FavoriteSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

  useEffect(() => {
    const fetchSellers = async () => {
      setLoading(true);
      try {
        const res = await getFavoriteSellers();
        setSellers(res);
      } catch (error) {
        console.error("Error fetching favorite sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  return (
    <div
      className="c-favoriteSellers"
      style={sidebar ? { width: "calc(100vw - 200px)" } : { width: "100vw" }}
    >
      <div className="c-favoriteSellers__title">Check out some of our favs</div>

      <div className="c-favoriteSellers__cards">
        {sellers?.map((seller, index) => (
          <BusinessCard key={index} business={seller?.properties} />
        ))}
      </div>
    </div>
  );
};

export default FavoriteSellers;
