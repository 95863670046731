/*******************************************************************************************************
 * Function/File Purpose: This file contains all API Calls needed for the Subscription Feed page
 * Author: Isaac Radford
 * Date: 11/7/2024
 *
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * ********************************
 */

import axios from "axios";
import { getAdminToken, getCustomerToken } from "../../Functions/Auth/getToken";

// Needed variables
const Url = require("../../backendURL");
const accountId = localStorage.getItem("userId");

/*************************************
 * Name: Get products
 * Purpose: This function is responsible for generating everything on the home page
 * Gets the parameters from SubscriptionFeed.jsx
 ***********************************/
export const getProducts = async ({
  route,
  query,
  location,
  page,
  filters,
}) => {
  try {
    const response = await axios.get(`${Url}feed/${route}`, {
      params: {
        query,
        location,
        page,
        filters,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching products", err);
    return [];
  }
};

/*************************************
 * Name: GenerateProductRow
 * Purpose: This function is responsible for generating everything on the home page
 * Gets the parameters from SubscriptionFeed.jsx
 ***********************************/
export const generateProductRow = async ({
  route,
  query,
  filters,
  location,
}) => {
  try {
    const response = await axios.get(`${Url}feed/${route}`, {
      params: {
        query,
        page: 1, // Pagination needs to get passed in but isn't necessary on this function
        location,
        filters,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching products", err);
    return [];
  }
};

/*************************************
 * Name: getFavoriteSellers
 * Purpose: This returns our favorite sellers that we're currently pushing on the platform (June's Place, Whips, Crush)
 * Intended for subscription feed + anywhere we need to fill white space
 ***********************************/
export const getFavoriteSellers = async () => {
  try {
    const response = await axios.get(`${Url}feed/businesses/favorite-sellers`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching favorite sellers", err);
  }
};

/*************************************
 * Name: getTopRatedSubscriptions
 * Purpose: Returns top subscriptions near the user
 * Intended for subscription feed + anywhere we need to fill white space
 ***********************************/
export const getTopRatedSubscriptions = async ({ location }) => {
  try {
    const response = await axios.get(
      `${Url}feed/subscriptions/top-rated-subscriptions`,
      {
        params: {
          location,
        },
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching products", err);
    return [];
  }
};

/*************************************
 * Name: getRecentReviews
 * Purpose: Returns recent good (above 4 star) reviews made by users
 * Intended for subscription feed + anywhere we need to fill white space
 ***********************************/
export const getRecentReviews = async () => {
  try {
    const response = await axios.get(
      `${Url}feed/subscriptions/recent-reviews`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching products", err);
    return [];
  }
};

/*************************************
 * Name: getProductsByCategory
 * Purpose: This returns 10 products by category, however mongo wants to return them
 * For the subcategory page, should probably prioritize seller + and top rated
 ***********************************/
export const getProductsByCategory = async ({
  category,
  page,
  location,
  filters,
}) => {
  try {
    const response = await axios.get(`${Url}feed/membership/category`, {
      params: {
        category,
        page,
        location,
        filters,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching products", err);
    return [];
  }
};

/*************************************
 * Name: getBusinessesByCategory
 * Purpose: This returns 10 products by category, however mongo wants to return them
 * For the subcategory page, should probably prioritize seller + and top rated
 ***********************************/
export const getBusinessesByCategory = async (category, query, location) => {
  try {
    const response = await axios.get(
      `${Url}feed/business/category/${category}`,
      {
        params: {
          query,
          location,
        },
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching products", err);
    return [];
  }
};

/*************************************
 * Name: Get promoted events
 *
 * Purpose: This returns promoted events/businesses/items near the user
 *
 * Intended for managing promoted items at top of the feed
 ***********************************/
export const getPromotions = async (location) => {
  try {
    const response = await axios.get(`${Url}feed/promotions`, {
      params: {
        location: location,
      },
    });
    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching promotions", err);
    return [];
  }
};

/*************************************
 * Name: Get nearby memberships
 * Purpose: This returns nearby Memberships that match the inputted query and filters
 * Intended for Subscription feed and search bar
 ***********************************/
export const getNearbyMemberships = async ({
  query,
  location,
  page,
  filters,
}) => {
  try {
    const response = await axios.get(`${Url}feed/subscriptions`, {
      params: {
        query,
        location,
        page,
        filters,
      },
    });
    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    return [];
  }
};

/*************************************
 * Name: Get nearby sellers
 * Purpose: This returns Sellers with info for the feed on the left side of map + Search bar
 * Intended for business feed map + Search Bar
 ***********************************/
export const getNearbySellers = async ({ query, location, page, filters }) => {
  try {
    const response = await axios.get(`${Url}feed/businesses/nearby-sellers`, {
      params: {
        query,
        location,
        page,
        filters,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching Subitt Sellers.", err);
    return [];
  }
};

/*************************************
 * Name: Search nearby sellers
 * Purpose: This returns Sellers with info for the feed on the left side of map + Search bar
 * Intended for business feed map + Search Bar
 ***********************************/
export const searchNearbyMemberships = async ({
  query,
  location,
  page,
  filters,
}) => {
  try {
    const response = await axios.get(`${Url}feed/search/nearby-memberships`, {
      params: {
        query,
        location,
        page,
        filters,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching Subitt Sellers.", err);
    return [];
  }
};

/*************************************
 * Name: Search nearby sellers
 * Purpose: This returns Sellers with info for the feed on the left side of map + Search bar
 * Intended for business feed map + Search Bar
 ***********************************/
export const searchNearbySellers = async ({
  query,
  location,
  page,
  filters,
}) => {
  try {
    const response = await axios.get(`${Url}feed/search/nearby-sellers`, {
      params: {
        query,
        location,
        page,
        filters,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching Subitt Sellers.", err);
    return [];
  }
};

/*************************************
 * Name: Get nearby seller pins
 * Purpose: This returns Sellers in geojson format for generating pins
 * Intended for business feed map
 ***********************************/
export const getNearbySellerPins = async ({ query, location, range }) => {
  try {
    const response = await axios.get(
      `${Url}feed/businesses/nearby-sellers/pins`,
      {
        params: {
          query,
          location,
          range,
        },
      },
    );

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching Subitt Sellers.", err);
    return [];
  }
};

/*************************************
 * Name: Get Nearby Places
 * Purpose: This returns Places from the Google Places API in geojson format for Mapbox to format as Markers
 * Intended for business feed map
 ***********************************/
export const getNearbyPlaces = async ({ query, location, filters }) => {
  try {
    const response = await axios.get(`${Url}feed/businesses/nearby-places`, {
      params: {
        query,
        location,
        filters,
      },
    });

    if (response.status === 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.error("Error fetching nearby places from Google", err);
    return [];
  }
};

/*************************************
 * Name: addOrUpdateWaitlist
 * Purpose: This returns Places from the Google Places API in geojson format for Mapbox to format as Markers
 * Intended for business feed map
 ***********************************/

export const addOrUpdateWaitlist = async (business, userId) => {
  try {
    const response = await axios.put(`${Url}waitlist/add-or-update`, business, {
      params: {
        userId,
      },
    });

    if (response.status === 200 || response.status === 201) {
      return response.data.count;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error adding to waitlist:", error);
  }
};
