import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { createRecommendation } from "../CustomerStateManagement/CustomerState";
import { AccountContext } from "../Routes/CustomerRoutes";
import { isLoggedIn } from "../CustomerStateManagement/CustomerState";
import loginIllustration from "../../Assets/loginIllustration.png";

const RecommendationForm = ({ showHeader }) => {
  const [userInfo, setUserInfo] = useContext(AccountContext); // Get current data
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    let login = isLoggedIn();
    if (login != null) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [isLoggedIn]);

  const [formData, setFormData] = useState({
    user: {
      userId: localStorage.getItem("userId") || "", // Get user ID if they're signed in, doesn't matter if not
      name: userInfo
        ? `${userInfo.firstName || ""} ${userInfo.lastName || ""}`.trim()
        : "",
      email: userInfo?.email || "",
    },
    recommendation: {
      businessName: "",
      businessContact: "",
      explanation: "",
      //   location: ""
    },
    contact: loggedIn ? true : false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.includes("user.")) {
      const userKey = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        user: {
          ...prevData.user,
          [userKey]: value,
        },
      }));
    } else if (name.includes("contact")) {
      setFormData((prevData) => ({
        ...prevData,
        contact: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        recommendation: {
          ...prevData.recommendation,
          [name]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await createRecommendation(formData);
      setFormData({
        user: {
          userId: localStorage.getItem("userId") || "", // Get user ID if they're signed in, doesn't matter if not
          name: userInfo
            ? `${userInfo.firstName || ""} ${userInfo.lastName || ""}`.trim()
            : "",
          email: userInfo?.email || "",
        },
        recommendation: {
          businessName: "",
          businessContact: "",
          link: "",
          explanation: "",
          //   location: ""
        },
        contact: false,
      });

      if (res.msg) {
        toast.success(
          "We've submitted your recommendation, check back in soon!",
          {
            className: "c-toastPopup",
            duration: 5000,
            iconTheme: {
              primary: "#e5704c",
              secondary: "#fff",
            },
          },
        );
      } else {
        toast.error(
          "There was an error submitting the recommendation. Please try again, or try again later!",
          {
            className: "c-toastPopup",
            duration: 5000,
            iconTheme: {
              primary: "#e5704c",
              secondary: "#fff",
            },
          },
        );
      }
    } catch (error) {
      toast.error(
        "There was an error submitting the recommendation. Please try again, or try again later!",
        {
          className: "c-toastPopup",
          duration: 5000,
          iconTheme: {
            primary: "#e5704c",
            secondary: "#fff",
          },
        },
      );
      console.error(error);
    }
  };

  return (
    <form className="c-form c-recommendationForm">
      {showHeader && (
        <div className="c-recommendationForm__header">
          <img
            src={loginIllustration}
            alt="Simple illustration of two users interacting between an orange screen"
          />

          <div>
            <h3>Have a business you'd like to see on Subitt?</h3>
            <p className="c-form__header">
              Let us know! We'll reach out to them, and if you want, we'll let
              you know when they join.
            </p>
          </div>
        </div>
      )}
      <div className="c-recommendationForm__content">
        {/* <label>Business Info:</label> */}
        <span className="c-recommendationForm__business">
          <label>
            What's their name?
            <input
              type="text"
              name="businessName"
              placeholder="Business Name"
              value={formData.recommendation.businessName}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            How can we get in touch with them?
            <input
              type="text"
              name="businessContact"
              placeholder="Phone #, Email, Website, etc."
              value={formData.recommendation.businessContact}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Tell us what you want us to know about them!
            <textarea
              className="c-recommendationForm__textArea"
              name="explanation"
              placeholder="What they do, why you like them, etc."
              value={formData.recommendation.explanation}
              onChange={handleChange}
              required
            />
          </label>
        </span>

        {loggedIn && (
          <>
            <span className="c-recommendationForm__contact">
              <input
                name="contact"
                type="checkbox"
                checked={formData.contact}
                onChange={handleChange}
              />
              <label>
                Do you want us to contact you if this business joins?
              </label>
            </span>
          </>
        )}
        <div className="c-recommendationForm__submit" onClick={handleSubmit}>
          Submit
        </div>
      </div>
    </form>
  );
};

export default RecommendationForm;
