import { createContext, useState, useEffect } from "react";


export const ChatboxContext = createContext({});


export function ChatboxContextProvider({ children }) {

  const [chatOption, setChatOption] = useState(null);

  const [chatOptions, setChatOptions] = useState();

  const [userId, setUserId] = useState();

  const [messages, setMessages] = useState([]);

  const [isBusinessSide, setIsBusinessSide] = useState(null);
  
  const [onConvos, setOnConvos] = useState(true); 

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 

  const [newRealTimeMessage, setNewRealTimeMessage] = useState(null);


  return (
    <ChatboxContext.Provider
      value={{
        chatOption,
        setChatOption,
        messages,
        setMessages,
        chatOptions,
        setChatOptions,
        userId,
        setUserId,
        isBusinessSide,
        setIsBusinessSide,
        onConvos,
        setOnConvos,
        isMobile,
        setIsMobile,
        newRealTimeMessage,
        setNewRealTimeMessage
      }}
    >
      {children}
    </ChatboxContext.Provider>
  );
}