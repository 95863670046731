import React from "react";
import MotionWrapper from "./MotionWrapper";

export default function ContactUs() {


  return (
    <div className="landingPage_contactus">
      <MotionWrapper>
        <h1>Need to know more? Call or email us</h1>
        <h1>615-512-6757 server@subitt.io</h1>
      </MotionWrapper>
    </div>
  );
}