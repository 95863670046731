import React from "react";
import CustomerNavBar from "./CustomerNavBar";

const CustomerHeader = () => {
  return (
    <header>
      <CustomerNavBar />
    </header>
  );
};

export default CustomerHeader;
