import React from 'react'

const TypeTab = ({type,setType,subscriptionLength}) => {

  const colorSchema = {
    "service":"#407B1B",
    "product":"#CA0303",
    "membership":"#063CA6"
  }

  return (
    <ul className='p-mySubscription__tabLists'>
    <li className={type === "all" ? 'p-mySubscription__tab--checked' :'p-mySubscription__tab'} onClick={()=>setType("all")}
    style={type=="all" ? {} : {borderBottom:"#e5704c solid"}}
    >All ({subscriptionLength[0]})</li>
    <li className={type === "product" ? 'p-mySubscription__tab--checked' :'p-mySubscription__tab'} onClick={()=>setType("product")}
    style={type === "product" ? 
    {background:colorSchema[type], color:"white"}:{borderBottom:`${colorSchema["product"]} solid`}}>Products ({subscriptionLength[2]})</li>
    <li className={type === "service" ? 'p-mySubscription__tab--checked' :'p-mySubscription__tab'} onClick={()=>setType("service")}
    style={type === "service" ? 
    {background:colorSchema[type],color:"white"}:{borderBottom:`${colorSchema["service"]} solid`}}>Services ({subscriptionLength[1]})</li>
    <li className={type === "membership" ? 'p-mySubscription__tab--checked' :'p-mySubscription__tab'} onClick={()=>setType("membership")}
    style={type === "membership" ? 
    {background:colorSchema[type],color:"white"}:{borderBottom:`${colorSchema["membership"]} solid`}}>Membership ({subscriptionLength[3]})</li>
   </ul>
  )
}

export default TypeTab