import React, { useContext, useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import {
  editAccountInfo,
  getAccountById,
} from "../../../CustomerStateManagement/CustomerState";
import { AccountContext } from "../../../Routes/CustomerRoutes";

const Profile = () => {
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [profile, setProfile] = useState({
    firstName: userInfo?.firstName || "",
    lastName: userInfo?.lastName || "",
    email: userInfo?.email || "",
    phone: userInfo?.phone || "",
  });

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const inputPhoneNumber = profile?.phone || "";
    let formattedPhoneNumber = "";

    // Remove all non-digit characters from the input
    const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, "");

    // Format the phone number as (XXX) XXX-XXXX
    if (cleanedPhoneNumber.length >= 10) {
      formattedPhoneNumber = `(${cleanedPhoneNumber.slice(
        0,
        3,
      )}) ${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6, 10)}`;
    } else {
      formattedPhoneNumber = cleanedPhoneNumber; // If the phone number is not complete, keep it as is
    }

    // Update the state with the formatted phone number
    setProfile((prevProfileInfo) => ({
      ...prevProfileInfo,
      phone: formattedPhoneNumber, // Use formattedPhoneNumber here
    }));
  }, [profile?.phone]);

  const updateInfo = async (e) => {
    e.preventDefault();
    try {
      // Updates location
      await editAccountInfo(profile);

      setUserInfo((prevProfileInfo) => ({
        ...prevProfileInfo,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phone: profile.phone,
      }));

      toast.success("Your Profile is correctly changed");
    } catch (err) {
      console.log(err);
      toast.error("Unable to update Profile.");
    }
  };

  return (
    // <div className="l-inner">
    <div>
      {/*<h4 className='m-margin__vertical--24'>*/}
      <Toaster />
      <form>
        <div className="settings-dropdown">
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">First Name</label>
            <input
              className="c-input"
              placeholder={userInfo?.firstName}
              value={profile?.firstName}
              onChange={(e) =>
                setProfile((prev) => ({ ...prev, firstName: e.target.value }))
              }
              style={{ width: "300px" }}
            />
          </div>

          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Last Name</label>
            <input
              className="c-input"
              placeholder={userInfo?.lastName}
              value={profile?.lastName}
              onChange={(e) =>
                setProfile((prev) => ({ ...prev, lastName: e.target.value }))
              }
              style={{ width: "300px" }}
            />
          </div>

          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Email Address</label>
            <input
              className="c-input"
              placeholder={userInfo?.email}
              value={profile?.email}
              onChange={(e) =>
                setProfile((prev) => ({ ...prev, email: e.target.value }))
              }
              style={{ width: "300px" }}
            />
          </div>

          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Phone Number</label>
            <input
              className="c-input"
              placeholder={userInfo?.phone}
              value={profile?.phone}
              onChange={(e) =>
                setProfile((prev) => ({ ...prev, phone: e.target.value }))
              }
              style={{ width: "300px" }}
            />
          </div>

          <button
            type="submit"
            className="c-smallButton--black"
            onClick={(e) => updateInfo(e)}
            disabled={processing}
          >
            {processing ? "Processing..." : "register"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
