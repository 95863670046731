/****************************************************************
 * File: StarRating.jsx
 * Purpose: Received a rating and converts it to a percentage.
 * A star is generated that is filled based on the percentage
 * calculated from the rating provided.
 ****************************************************************/
import React from "react";

const StarRating = ({ rating }) => {
  const starPercentage = rating * 100;
  const starPercentageStyle = `${starPercentage}%`;
  return (
    <div className="star-rating">
      <div className="stars-outer">
        <div
          className="stars-inner"
          style={{ width: `${starPercentageStyle}` }}
        ></div>
      </div>
    </div>
  );
};

export default StarRating;
