import React, { useState, useContext, useEffect, useRef } from "react";
import AddressChangePopup from "./AddressChangePopup";
import { RiMapPin2Fill } from "react-icons/ri";

import { AccountContext } from "../Routes/CustomerRoutes";
const NavBarAddress = () => {
  const [userInfo] = useContext(AccountContext);
  const [addressPopup, setAddressPopup] = useState(false);
  const popupRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   // Check if the click is outside of the popup form
  //   if (popupRef.current && !popupRef.current.contains(event.target)) {
  //     setAddressPopup(false);
  //   }
  // };

  // useEffect(() => {
  //   if (addressPopup) {
  //     // Add event listener to detect outside clicks
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     // Clean up event listener when popup is closed
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [addressPopup]);

  const handleClick = (e) => {
    setAddressPopup((prevState) => !prevState);
  };

  return (
    <>
      {addressPopup && (
        <div ref={popupRef}>
          <AddressChangePopup closePopup={setAddressPopup} />
        </div>
      )}

      <div className="c-navAddress" onClick={handleClick}>
        <RiMapPin2Fill size={20} />
        {userInfo?.addresses && (
          <p className="c-navAddress__address">
            {userInfo?.addresses[0]?.line1}
          </p>
        )}
      </div>
    </>
  );
};

export default NavBarAddress;
