/* 
  This is the parent component for the Calendar. In other words, this component
  will call the Small calendar found in the left and the big calendar that will
  contain the weekly view and monthly view on the right
*/

import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import SmallCalendar from "../SmallCalendar";
import BigCalendar from "./BigCalendar";

import "./Style/Calendar.css";

import Spinner from './Spinner';

//Date context that will be provided to all the components of the calendar
export const DateContext = createContext();

function Calendar() {
  const Url = require("../../../../backendURL");
  const businessId = localStorage.getItem('businessId')
  const [emptySpots, setEmptySpots] = useState([])
  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loaded, setLoaded] = useState(false)
  const [loadedDetails, setLoadedDetails] = useState(false)
  let date = new Date();

  // This state will be passed using the DateContext
  const [d, setD] = useState(date);




  // Integration
  useEffect(() => {
    getAppointments();

  }, []);

  const getAppointments = async () => {
    try {
      let res = await axios.get(`${Url}appointments/businesses/${businessId}`);
      if (res.status === 200) {
        setAppointmentData(res.data.doc);

        getEmptySpots(res.data.doc[0].business)
        getServiceDetails(res.data.doc)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getServiceDetails = async (data) => {
    const Jwt = localStorage.getItem("token")
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Jwt}`
    }
    // Create empty array to temporary store the appointments
    let temporaryAppointmentDetails = []
    Promise.all(data.map(async (d)=> {
      try {
        const [accountRes, serviceRes] = await Promise.all([
          axios.get(`${Url}accounts/${d.customer}`,headers),
          axios.get(`${Url}products/${d.service}`)
        ])

        if (accountRes.status === 200 && serviceRes.status === 200) {
          const serviceDetailOb = {
            customerName: `${accountRes.data.user.firstName} ${accountRes.data.user.lastName}`,
            email: accountRes.data.user.email,
            serviceName: serviceRes.data.doc.productName,
            dateTime: d.dateTime
          }
          // push to the empty array
          temporaryAppointmentDetails.push(serviceDetailOb)
        }
      } catch (err) {
        console.log(err)
      }
    })).then(()=> {
      setAppointmentDetails(temporaryAppointmentDetails)
      setLoadedDetails(true)
    })
  }

  
  const getEmptySpots = async (serviceId) => {
    try {
      const res = await axios.get(`${Url}products/business-products/${serviceId}`)
      if (res.status === 200) {
        let emptySpotsArray = []
        res.data.docs.map((d)=>{
          const emptySpots = Object.keys(d.appointmentSlots).filter(key => d.appointmentSlots[key] === 'Open')
          emptySpots.map((x) => {
            const [date, time] = x.split(', ')
            const filteredEmptySpots = {
              date: date,
              time: time
            }

            emptySpotsArray.push(filteredEmptySpots)
          }
          
          )
        }
        )
        setEmptySpots(emptySpotsArray)
        setLoaded(true)
      }
    } catch (error) {
      
    }
  }

  return (
    <div>

      <div className="calendarContainer">
        <DateContext.Provider value={{ d, setD }}>
          <div className="calLeft">
            {/* <SmallCalendar date={d}></SmallCalendar> */}
            <div className="calendarStatus">
              <h2>Status</h2>
              <div className="color">
                <div
                  className="colorSquare"
                  style={{ backgroundColor: "#62B453" }}
                ></div>
                <span>Open Spot</span>
              </div>
              <div className="color">
                <div
                  className="colorSquare"
                  style={{ backgroundColor: "#E1473D" }}
                ></div>
                <span>Coming Appointment</span>
              </div>
              <div className="color">
                <div
                  className="colorSquare"
                  style={{ backgroundColor: "blue" }}
                ></div>
                <span>Reserved for subscriber</span>
              </div>
              {/* <div className="color">
                <div
                  className="colorSquare"
                  style={{ backgroundColor: "#22689E" }}
                ></div>
                <span>Completed</span>
              </div> */}
            </div>
            {/* Once make this comment out because there is an Availability page */}
            <p>Go to Availability page to create new spots for customers</p>
          </div>
          <div className="calright">
                {/*The loaded state will make sure to wait until we get the data for
                 the appoinments bofore rendering the BigCalendar*/}
                {loaded && loadedDetails?<BigCalendar
      apptData={appointmentData.length > 0 ? appointmentData : "empty"} emptySpots={emptySpots} appointmentData={appointmentDetails}></BigCalendar>:<Spinner/>}
   
          </div>
        </DateContext.Provider>
      </div>
    </div>
  );
}

export default Calendar;
