/*******************************************************************************************************
 * Function/File Purpose: Spencer made this file for profile holder and other information like Wishlist, Messages, Notification, and Be a Seller. (Written by Aoi)
 * Author: Spencer
 * Date: Don't know
 *
 * **********************************
 * Contributor: Aoi kuriki
 * Date: 2023/6/19
 * What was Done: get rid of useEffect and format with fixDateFormat
 * *******************************
 * Constributor:
 * Date:
 * What was done:
 * ********************************
 */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { fixDateFormat } from "../../../utilities/global_function";
import PopUp from "../../../../Component/Layer/PopUp";
import {
  customerSignOut,
  deleteAccount,
} from "../../../CustomerStateManagement/CustomerState";
import toast, { Toaster } from "react-hot-toast";
import useWindowSize from "../../../../Functions/Hooks/useWindowSize";
import Wishlist from "./Wishlist";
import UpdateAvatarImage from "./UpdateAvatarImage";
import Avatar from "./AvatarImage";

import { AccountContext } from "../../../Routes/CustomerRoutes";

export default function UserCard() {
  const isAdmin = localStorage.getItem("isAdmin");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userInfo, setUserInfo] = useContext(AccountContext);

  const isMobile = useWindowSize();

  const handleDeleteAccount = async () => {
    const res = await deleteAccount();
    console.log(res);
    if (res) {
      toast.success("Your account is correctly being deleted");
      await customerSignOut();
    } else {
      toast.error("Couldn't delete your account successfully");
    }
  };

  return (
    <div className="userDetailsCard">
      <Toaster />
      <div className="userDetailsInfo">
        <Avatar avatarImg={userInfo?.accountDetail?.avatarImg}/>
        <UpdateAvatarImage />  
        
        <h1>
          {userInfo?.firstName} {userInfo?.lastName}
        </h1>
        <h3>Member Since {fixDateFormat(userInfo?.created)}</h3>
      </div>
      <hr></hr>
      {/* <p>{userInfo?.address?.line1}</p> */}
      {/* Some other options which may use later */}

      <div className="userDetailsInfoSelection">
        <Link to="/wishlist">Wishlist</Link>
        <hr></hr>
        <Link to="/reviews">Reviews</Link>
        <hr></hr>
        <Link to="/chatbox">Messages</Link>
        {/* <Link to="/Coming">Notifications</Link> */}
        <hr></hr>
        <Link to="/user">Settings</Link>
        <hr></hr>
        <Link
          className="userDetailsInfoLastSelection"
          to="/businessportal/landingPage"
        >
          Become a seller?
        </Link>
        <hr></hr>
        <p
          style={{ fontSize: 14, color: "red" }}
          onClick={() => setDeleteOpen(true)}
        >
          Delete Account
        </p>
        {isAdmin == "true" && (
          <>
            <hr></hr>
            <Link to="/admin">Admin Page</Link>
          </>
        )}
      </div>
      {deleteOpen && (
        <PopUp
          setOpen={setDeleteOpen}
          cStyle={
            isMobile
              ? { width: "80%", display: "flex", flexDirection: "column" }
              : { width: "50%", display: "flex", flexDirection: "column" }
          }
        >
          <h4>Account Delete Confirmation</h4>
          <p style={{ marginBottom: 24 }}>This action cannot be undone.</p>
          <p>
            By clicking the delete button below, you will be unable to access to
            this account. Any active subscriptions are immediately cancelled and
            cannot be redeemed through Subitt or with businesses you are
            currently subscribed to. For this reason we strongly advise{" "}
            <b>cancelling</b> your subscriptions <b>first</b> and waiting until
            they expire, <b>then</b> deleting your account. To do this, go to
            your MySubscriptions page, and select cancel on all subscriptions
            there.
          </p>
          <label>
            Please be advised that Subitt will retain certain information, like
            your email and name, for the purposes of retaining purchase history.
            We do this in accordance with applicable laws including for tax and
            accounting and fraud prevention purposes.
          </label>
          <button
            className="c-smallButton"
            style={{ backgroundColor: "red", margin: "16px auto" }}
            onClick={() => handleDeleteAccount()}
          >
            Delete
          </button>
        </PopUp>
      )}
    </div>
  );
}
