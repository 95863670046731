import React from "react"
import ContentLoader from "react-content-loader"

const ItemCardSkelton2 = (props) => (
  <ContentLoader 
    speed={2}
    width={300}
    height={400}
    viewBox="0 0 300 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="32" ry="32" width="300" height="260" /> 
    <rect x="45" y="274" rx="5" ry="5" width="195" height="22" /> 
    <rect x="15" y="310" rx="5" ry="5" width="160" height="22" /> 
    <rect x="197" y="310" rx="5" ry="5" width="70" height="22" /> 
    <rect x="15" y="345" rx="5" ry="5" width="131" height="22" /> 
    <circle cx="28" cy="285" r="13" />
  </ContentLoader>
)

export default ItemCardSkelton2