import React, { useEffect } from 'react'
import { customerStripeAccess } from '../../../CustomerStateManagement/CustomerState'
import { Toaster, toast } from 'react-hot-toast'

const StripeLink = () => {

  useEffect(() => { 
    link()
  })

  const link = async() => {
    try{
    const StripeLink = await customerStripeAccess()
    window.open(StripeLink, '_blank')
    }catch(err){
      toast.error(err)
    }
    
  }
  return (
    <div className='redirectContainer'>
      <Toaster />
      <h2>Redirecting you to Stripe page...</h2>
      <button className='orangeBtn' onClick={()=>link()}>Click if it does not automatically let you redirected</button>
    </div>
  )
}

export default StripeLink