/**************************************************************************************
 * File: Wishlist.jsx
 * Purpose: Displays items from a user's wishlist. These items are then able to be 
 * sorted and displayed in chronological order of when they were added to the user's
 * wishlist or alphabetical order from az or za. The number of items per page defaults 
 * to 10, but can be changed based on a dropdown menu. Pages are generated based on the
 * amount of items per page. User's can delete or add items from this page based on the 
 * HeartButton
**************************************************************************************/
import React, { useState, useEffect, Suspense } from "react";
import {
  getAccountById,
  getWishlist,
} from "../../../CustomerStateManagement/CustomerState";
import UserCard from "./UserCard";
import ItemCard from "../../HomePage/Components/ItemCard";
import {
  getServiceById,
  getProductsById,
  getMembershipById,
} from "../../../CustomerStateManagement/Item";
import ItemCardSkelton2 from "../../../utilities/ItemCardSkelton2";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { scrollToTop } from "../../../../GlobalComponents/Utils/scroll";

const Wishlist = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [productData, setProductData] = useState([]);
  const [azData, setAZData] = useState([]);
  const [zaData, setZAData] = useState([]);
  const [dateAscendingData, setDateAscendingData] = useState([]);
  const [dateDescendingData, setDateDescendingData] = useState([]);

  const [priceLowToHigh, setPriceLowToHigh] = useState([]);
  const [priceHighToLow, setPriceHighToLow] = useState([]);

  const [display, setDisplay] = useState("Date Ascending");

  const [pageLength, setPageLength] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [openDropdown, setOpenDropdown] = useState(null)

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName)
  }


  useEffect(() => {
    const fetchData = async () => {
      const res = await getAccountById();
      setData(res);

      const wishlist = await getWishlist();    

      for (let index in wishlist) {
        if (wishlist[index.itemType] !== "other") {
          if (wishlist[index].itemType === "service") {
            const serviceResponse = await getServiceById(
              wishlist[index].itemId,
            );
            if (serviceResponse.length != 0) {
              productData.push(serviceResponse);
            }
          } else if (wishlist[index].itemType === "membership") {
            const membershipResponse = await getMembershipById(
              wishlist[index].itemId,
            );
            if (membershipResponse.length != 0) {
              productData.push(membershipResponse);
            }
          } else if (wishlist[index].itemType === "product") {
            const productResponse = await getProductsById(
              wishlist[index].itemId,
            );
            if (productResponse.length != 0) {
              productData.push(productResponse);
            }
          }
        } else {
          try {
            const serviceResponse = await getServiceById(
              wishlist[index].itemId,
            );
            if (serviceResponse.length != 0) {
              productData.push(serviceResponse);
            }
          } catch (error) {
            try {
              const membershipResponse = await getMembershipById(
                wishlist[index].itemId,
              );
              if (membershipResponse.length != 0) {
                productData.push(membershipResponse);
              }
            } catch (error) {
              try {
                const productResponse = await getProductsById(
                  wishlist[index].itemId,
                );
                if (productResponse.length != 0) {
                  productData.push(productResponse);
                }
              } catch (error) {
                console.error("Error", error);
              }
            }
          }
        }
      }
      sortData(pageLength)
    };

    scrollToTop();

    document.addEventListener('scroll', () => {
      setOpenDropdown(null)
    })

    if (localStorage.getItem('userId') === null) {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
      fetchData();
    }
    

  }, []);

  const organizePages = (data, pageLength) => {
    let page = []
    let pages = []
    for (let index in data) {
      page.push(data[index])
      if (page.length >= pageLength) {
        pages.push(page)
        page = []
      }
    }
    pages.push(page)

    return pages      
  }

  const sortData = (pageLength) => {
    const sortedAZ = [...productData].sort((a, b) =>
      a.name.localeCompare(b.name),
    );     

    const sortedZA = [...sortedAZ].reverse();

    const sortedLowToHigh = [...productData].sort((a, b) => 
      a.subTypes[0].price - b.subTypes[0].price
    )

    const sortedHighToLow = [...sortedLowToHigh].reverse()

    console.log('Sorted pricing', sortedLowToHigh)

    const dataDescending = [...productData].reverse();

    setAZData(organizePages(sortedAZ, pageLength))
    setZAData(organizePages(sortedZA, pageLength))
    setDateAscendingData(organizePages(productData, pageLength))
    setDateDescendingData(organizePages(dataDescending, pageLength))
    setPriceLowToHigh(organizePages(sortedLowToHigh, pageLength))
    setPriceHighToLow(organizePages(sortedHighToLow, pageLength))
    
    setLoading(false);
  };

  function handleDisplay(option) {
    setLoading(true);
    setDisplay(option);
    setCurrentPage(1)
    setTimeout(() => {
      setLoading(false);
      return;
    }, 200);
  }

  function handlePageChange(page) {
    setLoading(true);
    if (page != "" && page < azData.length + 1 && page > 0) {
      setCurrentPage(page);
    }
    
    setTimeout(() => {
      setLoading(false);
      return;
    }, 200);
  }

  function handlePageLengthChange(pageLength) {
    setLoading(true)
    setPageLength(pageLength);
    setCurrentPage(1);
    sortData(pageLength)
    setDisplay(display)
    setTimeout(() => {
      setLoading(false);
      return;
    }, 200);
  }

  return (
    <>
      <div className="userDetailsContainer l-inner">
        <div className="userDetailsCard">
          <UserCard data={data} />
        </div>
      <div className="userDetailsSettings">
      {/*Option with homepage classnames*/}
      <div className="p-subscriptionFeed__filters p-wishlist-filters">
        <div className="p-subscriptionFeed__filters__filtering p-wishlist-filters__filtering" >
          <div className="p-subscriptionFeed__filters__dropdown">
            <button className="filter-button" onClick={() => toggleDropdown("Date")}>
              <p>Date: <strong>{display === 'Date Ascending' ? 'Ascending' : display === 'Date Descending' ? 'Descending' : ''}</strong></p>
              <h1>|</h1>
              <IoIosArrowDown size={17.5} />
            </button>
            {openDropdown === "Date" && (
            <div className="dropdown-content p-subscriptionFeed__filters__sort__content">
              <div className="p-subscriptionFeed__filters__name">Sort By:</div>
              <label className="p-wishlist-radioInput">
                <input type="radio" name="sort" value="Ascending"
                onChange={() => handleDisplay('Date Ascending')}
                checked={display === 'Date Ascending'}/>                
                Ascending
              </label>
              <label className="p-wishlist-radioInput">
                <input type='radio' name='sort' value='Descending'
                onChange={() => handleDisplay('Date Descending')}
                checked={display === 'Date Descending'}/>
                Descending
              </label>
            </div>
            )}
          </div>        
          <div className="p-subscriptionFeed__filters__dropdown">
          <button className="filter-button" onClick={() => toggleDropdown('Name')}>
            <p>Name: <strong>{display === "A→Z" ? "A→Z" : display === "Z→A" ? "Z→A" : ""}</strong></p>
            <h1>|</h1>
            <IoIosArrowDown size={17.5} />
          </button>
          {openDropdown === "Name" && (
          <div className="dropdown-content p-subscriptionFeed__filters__sort__content">
            <div className="p-subscriptionFeed__filters__name">Sort By:</div>
            <label className="p-wishlist-radioInput">
              <input type="radio" name="sort" value="A→Z"
              onChange={() => handleDisplay("A→Z")}
              checked={display === 'A→Z'}/>
              A→Z
            </label>
            <label className="p-wishlist-radioInput">
              <input type="radio" name="sort" value="Z→A"
              onChange={() => handleDisplay("Z→A")}
              checked={display === 'Z→A'}/>
              Z→A
            </label>
           </div>
          )}
          </div>

          <div className="p-subscriptionFeed__filters__dropdown">
          <button className="filter-button" onClick={() => toggleDropdown('Price')}>
            <p>Price: <strong>{display === "Low→High" ? "Low→High" : display === "High→Low" ? "High→Low" : ""}</strong></p>
            <h1>|</h1>
            <IoIosArrowDown size={17.5} />
          </button>
          {openDropdown === "Price" && (
          <div className="dropdown-content p-subscriptionFeed__filters__sort__content">
            <div className="p-subscriptionFeed__filters__name">Sort By:</div>
            <label className="p-wishlist-radioInput">
              <input type="radio" name="sort" value="Low→High"
              onClick={() => handleDisplay("Low→High")}
              checked={display === 'Low→High'}/>
              Low→High
            </label>
            <label className="p-wishlist-radioInput">
              <input type="radio" name="sort" value="High→Low" 
              onClick={() => handleDisplay("High→Low")}
              checked={display === 'High→Low'}/>
              High→Low
            </label>
          </div>
          )}
          </div>
        </div>


      {/*Option without homepage classnames*/}
      {/* <div className="wishlist-container">
        <div className="wishlist-filters">
          <div>
            <button className="wishlist-dropdown-button" onClick={() => toggleDropdown("Date")}>Date</button>
            {openDropdown === "Date" && (
            <div className="wishlist-dropdown-menu">
              <div className="wishlist-dropdown-button" onClick={() => handleDisplay('Date Ascending')}>Ascending</div>
              <div className="wishlist-dropdown-button" onClick={() => handleDisplay('Date Descending')}>Descending</div>
            </div>
            )}
          </div>        
        
          <div className="wishlist-filters">
          <button className="wishlist-dropdown-button" onClick={() => toggleDropdown('Name')}>Name</button>
          {openDropdown === "Name" && (
          <div className="wishlist-dropdown-menu">
            <div className="wishlist-dropdown-button" onClick={() => handleDisplay("A→Z")}>A→Z</div>
            <div className="wishlist-dropdown-button" onClick={() => handleDisplay("Z→A")}>Z→A</div>
          </div>
          )}
          </div>

          <div className="wishlist-filters">
          <button className="wishlist-dropdown-button" onClick={() => toggleDropdown('Price')}>Price</button>
          {openDropdown === "Price" && (
          <div className="wishlist-dropdown-menu">
            <div className="wishlist-dropdown-button" onClick={() => handleDisplay("Low→High")}>Low→High</div>
            <div className="wishlist-dropdown-button" onClick={() => handleDisplay("High→Low")}>High→Low</div>
          </div>
          )}
          </div>
        </div> */}

        <select
            className="p-wishlist__pageLength__dropdown"
            onChange={(e) => handlePageLengthChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value="20" selected>20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>    
        </div> 
          
          <div>
            {loading ? (
              <div className="l-cardLayout--flexStart">
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
                <div className="c-skeletonBox">
                  <ItemCardSkelton2 />
                </div>
              </div>
            ) : isLoggedIn === false ? (<p>Please sign in to save items to your wishlist.</p>) : productData.length == 0 ? (
              <p>
                No items in wishlist. Click on a{" "}
                <svg
                  style={{
                    height: "1em",
                    fill: "white",
                    stroke: "#e5704c",
                    strokeWidth: "50",
                    overflow: "visible",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                </svg>{" "}
                to add something to your wishlist!
              </p>
            ) : display == "Date Ascending" ? (
              <>
              <div className="l-cardLayout--flexStart">
              {dateAscendingData[currentPage - 1].map((data, index) => (
                <Suspense key={index} fallback={<ItemCardSkelton2 />}>
                  <ItemCard key={index} item_data={data} />
                </Suspense>
              ))}
              </div>
              <div className="l-cardLayout--flexStart" style={{width: 'fit-content'}} >
                <IoIosArrowBack style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage - 1)}/>
                {dateAscendingData.map((_, index) => (
                <button key={index} className={index === currentPage - 1 ? 'p-wishlist__paginationMenu__active' : 'p-wishlist__paginationMenu'} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}
                <IoIosArrowForward style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage + 1)}/>
              </div>  
              </>
            ) : display == "Date Descending" ? (
              <>
              <div className="l-cardLayout--flexStart">
              {dateDescendingData[currentPage - 1].map((data, index) => (
                <Suspense key={index} fallback={<ItemCardSkelton2 />}>
                  <ItemCard key={index} item_data={data} />
                </Suspense>
              ))}
              </div>
              <div className="l-cardLayout--flexStart" style={{width: 'fit-content'}}>
                <IoIosArrowBack style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage - 1)}/>
                {dateDescendingData.map((_, index) => (
                  <button key={index} className={index === currentPage - 1 ? 'p-wishlist__paginationMenu__active' : 'p-wishlist__paginationMenu'} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}<IoIosArrowForward style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage + 1)}/>
              </div>
              </>
            ) : display == "A→Z" ? (
              <>
              <div className="l-cardLayout--flexStart">
              {azData[currentPage - 1].map((data, index) => (
                <Suspense key={index} fallback={<ItemCardSkelton2 />}>
                  <ItemCard key={index} item_data={data} />
                </Suspense>
              ))}
              </div>
              <div className="l-cardLayout--flexStart" style={{width: 'fit-content'}}>
                <IoIosArrowBack style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage - 1)}/>
                {azData.map((_, index) => (
                  <button key={index} className={index === currentPage - 1 ? 'p-wishlist__paginationMenu__active' : 'p-wishlist__paginationMenu'} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}<IoIosArrowForward style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage + 1)}/>
              </div>
              </>
            ) : display == "Z→A" ? (
              <>
              <div className="l-cardLayout--flexStart">
              {zaData[currentPage - 1].map((data, index) => (
                <Suspense key={index} fallback={<ItemCardSkelton2 />}>
                  <ItemCard key={index} item_data={data} />
                </Suspense>
              ))}
              </div>
              <div className="l-cardLayout--flexStart" style={{width:'fit-content'}}>
                <IoIosArrowBack style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage - 1)}/>
                {zaData.map((_, index) => (
                  <button key={index} className={index === currentPage - 1 ? 'p-wishlist__paginationMenu__active' : 'p-wishlist__paginationMenu'} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}<IoIosArrowForward style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage + 1)}/>
              </div>
              </>
            ) : display == "Low→High" ? (
              <>
              <div className="l-cardLayout--flexStart">
              {priceLowToHigh[currentPage - 1].map((data, index) => (
                <Suspense key={index} fallback={<ItemCardSkelton2 />}>
                  <ItemCard key={index} item_data={data} />
                </Suspense>
              ))}
              </div>
              <div className="l-cardLayout--flexStart" style={{width: 'fit-content'}}>
                <IoIosArrowBack style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage - 1)}/>
                {priceLowToHigh.map((_, index) => (
                  <button key={index} className={index === currentPage - 1 ? 'p-wishlist__paginationMenu__active' : 'p-wishlist__paginationMenu'} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}<IoIosArrowForward style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage + 1)}/>
              </div>
              </>
            ) : display == "High→Low" ? (
              <>
              <div className="l-cardLayout--flexStart">
              {priceHighToLow[currentPage - 1].map((data, index) => (
                <Suspense key={index} fallback={<ItemCardSkelton2 />}>
                  <ItemCard key={index} item_data={data} />
                </Suspense>
              ))}
              </div>
              <div className="l-cardLayout--flexStart" style={{width: 'fit-content'}}>
                <IoIosArrowBack style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage - 1)}/>
                {priceHighToLow.map((_, index) => (
                  <button key={index} className={index === currentPage - 1 ? 'p-wishlist__paginationMenu__active' : 'p-wishlist__paginationMenu'} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}<IoIosArrowForward style={{margin: 'auto', cursor: "pointer"}} onClick={() => handlePageChange(currentPage + 1)}/>
              </div>
              </>
            ) : (
              <p>Error in loading wishlist.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wishlist;
