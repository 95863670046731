import React, { useContext, useEffect, useState } from 'react'
import { ItemDetailContext } from '../../ItemDetail'

import ItemImgs from './ItemImgs'
import ItemCategory from '../ItemCategory'
import ItemContent from './ItemContent'

const ItemInformation = () => {
  const [data] = useContext(ItemDetailContext)
  // console.log("data in Info page",data)
  

  useEffect(() => {
    
  })


  return (
    <section className='p-itemDetail__sectionContainer p-itemDetail__itemInformation'>
      <ItemCategory categories={data?.category} />
      <div className='p-itemDetail__contentsPart'>
        <ItemImgs />
        <ItemContent />
      </div>
    </section>
  )
}

export default ItemInformation