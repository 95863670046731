import { useState } from "react";
import subittLogo from "../../../Assets/subittLogo-white.png";
import {
  LPHeroSlide1,
  LPHeroSlide2,
  LPHeroSlide3,
  LPHeroSlide4,
  LPHeroSlide5,
  LPHeroSlide6,
  LPHeroSlide7,
  LPHeroSlide8,
} from "../../../Assets/LandingPageAssets";
import { FaBars, FaTimes } from "react-icons/fa";
import MotionWrapper from "./MotionWrapper";

export default function BusinessHero({ navigate }) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="landingPage__heroContainer">
      <div className="landingPage__topSection">
        <div className={`landingPage__logo`}>
          <img
            src={subittLogo}
            alt={`subittLogo`}
            onClick={() => navigate("feed")}
          ></img>
        </div>
        <div
          className={`landingPage__menuIcon ${showMenu ? "active" : ""}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          <FaBars />
        </div>
        <div className={`landingPage__navLinks ${showMenu ? "active" : ""}`}>
          <ul>
            <li
              onClick={() => {
                navigate("businessportal/landingPage");
                setShowMenu(false);
              }}
            >
              Sell on Subitt
            </li>
            <li
              onClick={() => {
                navigate("login");
                setShowMenu(false);
              }}
            >
              Sign In
            </li>
            <li
              className="landingPage__menuCloseIcon"
              onClick={() => setShowMenu(false)}
            >
              <FaTimes />
            </li>
          </ul>
        </div>
      </div>
      <div className="landingPage__heroContent">
        <div className="landingPage__heroInfo">
          <p>Welcome to subitt</p>
          <h1>
            Discover, customize, and manage your subscriptions in one place
          </h1>
          <p>The marketplace for subscriptions</p>
          <div className="landingPage_hero_align">
            <button onClick={() => navigate("feed")}>
              Browse Subscriptions
            </button>
          </div>
        </div>
        <MotionWrapper>
          <div className="landingPage__imageCarousel">
            <div className="imageContainer">
              {/* by the time the first 8 images have completely scrolled out of view, 
              the next set of 8 images (which are identical to the first set) begin to appear. 
              This makes the animation loop smoothly without any abrupt jumps. */}
              <div className="carouselColumnWrapper">
                <div className="carouselColumn">
                  <img
                    src={LPHeroSlide1}
                    alt="Slide 1"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide2}
                    alt="Slide 2"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide3}
                    alt="Slide 3"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide4}
                    alt="Slide 4"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide1}
                    alt="Slide 1"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide2}
                    alt="Slide 2"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide3}
                    alt="Slide 3"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide4}
                    alt="Slide 4"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                </div>
              </div>
              <div className="carouselColumnWrapper">
                <div className="carouselColumn">
                  <img
                    src={LPHeroSlide5}
                    alt="Slide 5"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide6}
                    alt="Slide 6"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide7}
                    alt="Slide 7"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide8}
                    alt="Slide 8"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide5}
                    alt="Slide 5"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide6}
                    alt="Slide 6"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide7}
                    alt="Slide 7"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                  <img
                    src={LPHeroSlide8}
                    alt="Slide 8"
                    loading="lazy"
                    className="businessSlideImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </MotionWrapper>
      </div>
    </div>
  );
}
