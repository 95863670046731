import toast from "react-hot-toast";

export const customerAddressValidation = (address) => {
  if (!address || typeof address !== "object") {
    toast.error("Invalid address input.");
    return false;
  }

  const { line1, line2, city, state, postal_code, country = "US" } = address;

  if (!line1 || typeof line1 !== "string" || line1.trim() === "") {
    toast.error("Address Line 1 is required.");
    return false;
  }

  if (!city || typeof city !== "string" || city.trim() === "") {
    toast.error("City is required.");
    return false;
  }

  if (!state || typeof state !== "string" || state.trim() === "") {
    toast.error("State is required.");
    return false;
  }

  const postalCodeRegex = /^\d{5}(-\d{4})?$/; // US ZIP code format (5 or 9 digits)
  if (!postal_code || !postalCodeRegex.test(postal_code)) {
    toast.error("Postal code is required and must be a valid US ZIP code.");
    return false;
  }

  if (country !== "US") {
    toast.error("Country must be 'US'.");
    return false;
  }

  return true;
};