import React, { useState } from "react";
import { PhoneAppDemo1, PhoneAppDemo2, PhoneAppDemo3 } from '../../../Assets/LandingPageAssets/index';
import MotionWrapper from "./MotionWrapper";
import AppleStoreBadge from '../../../Assets/AppleStoreBadgeWhite.svg';

export default function FeatureSection() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    { src: PhoneAppDemo1, title: <h3><span className="highlight">Discover</span> Subscriptions</h3>, subtitle: "Discover new subscriptions near you from local businesses" },
    { src: PhoneAppDemo2, title: <h3><span className="highlight">Manage</span> Subscriptions</h3>, subtitle: "Keep all of your subscriptions together in one place" },
    { src: PhoneAppDemo3, title: <h3><span className="highlight">Save Money with</span> Subscriptions</h3>, subtitle: "Discover new subscriptions near you from coffee shops to pet care" }
  ];

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="landingPage_feature_container">
      <MotionWrapper>
        <div className="feature_carousel">
          <div className="carousel_inner" style={{ transform: `translate(-${currentIndex * 100}%)` }}>
            {images.map((image, index) => (
              <div
                key={index}
                className={`carousel_item ${index === currentIndex ? "active" : ""}`}
              >
                <div className="carousel_caption">
                  {image.title}
                  <p>{image.subtitle}</p>
                </div>
                <img src={image.src} alt={image.title} />
              </div>
            ))}
          </div>
          <button className="carousel-control prev" onClick={prevSlide}>
            &#10094;
          </button>
          <button className="carousel-control next" onClick={nextSlide}>
            &#10095;
          </button>
          <div className="download_app_container">
            <h3>Download Now</h3>
            <a href="https://apps.apple.com/us/app/subitt/id6502962948" className="download_app_store">
              <img src={AppleStoreBadge} alt="Download on the App Store (Apple)" />
            </a>
          </div>
        </div>
      </MotionWrapper>
    </div>
  );
}
