import React, { useEffect, useState } from 'react';
import "./Styles/Login.css";
import SignIn from './Components/SignIn';
import logo from "../../../Assets/SubittLogoForInsert.png"
import persona from "../../../Assets/loginIllustration.png"
import SignUp from './Components/SignUp';




const Login = () => {

  const [signIn, setSignIn] = useState(true)
  const path = localStorage.getItem("lastLoc")
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <section className='l-inner'>
    <div className='p-login__container'>
      {
        signIn ? (
          <SignIn trigger={setSignIn}/>
        ) : (
          <SignUp trigger={setSignIn}/>
        )
      }
      
      <section className='p-login__info'>
        <div className='p-login__info--inner'>
          <img className="p-login__info--logo" src={logo} alt='logo' />
          <h4>Subitt is the online marketplace for subscriptions. Start <span style={{fontSize:"inherit", fontStyle:"italic", fontWeight:"bold"}}>subscribing</span> how you want to!</h4>
          <div className="p-login__info--persona">
            <img src={persona} alt='persona' />
          </div>
        </div>
      </section>
    </div>
    </section>
  )
}

export default Login