import React, { useState, useEffect, useContext } from "react";
import Rating from "@mui/material/Rating";
import {
  createReview,
  getReviewsByItemId,
  getReviewsByUserId,
} from "../../../../CustomerStateManagement/ReviewState";
import EditIcon from "@mui/icons-material/Edit";
import { ReviewContext } from "../../ItemDetail";


export default function NewReview({ itemId, businessId }) {
  const [reviews, setReviews, hasReviewed, setHasReviewed] =
    useContext(ReviewContext);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewRating, setReviewRating] = useState(0);
  const [reviewBody, setReviewBody] = useState("");
  const [bodyCharCount, setBodyCharCount] = useState(0);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const maxBodyCharLimit = 2500;
  const maxTitleCharLimit = 100;

  const [titleMessage, setTitleMessage] = useState(null);
  const [bodyMessage, setBodyMessage] = useState(null);
  const [ratingMessage, setRatingMessage] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const testReviewBodyLength = () => {
    let testBody = "";

    for (let index = 0; index < maxBodyCharLimit + 5; index++) {
      testBody += index.toString();
    }

    return testBody;
  };

  const testReviewTitleLength = () => {
    let testTitle = "";

    for (let index = 0; index < maxTitleCharLimit + 5; index++) {
      testTitle += index.toString();
    }

    return testTitle;
  };

  //useEffect for testing validation of body length and title length
  useEffect(() => {
    //for testing the body and title length
    //setReviewBody(testReviewBodyLength())
    //setReviewTitle(testReviewTitleLength())

    if (localStorage.getItem("userId") === null) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (hasReviewed) {
      alert("You have already reviewed this item.");
      return;
    }

    //frontend validation
    if (
      reviewTitle.trim() === "" &&
      reviewBody.trim() === "" &&
      reviewRating === 0
    ) {
      setTitleMessage("Please fill out the title for your review.");
      setBodyMessage("Please fill out the body of your review.");
      setRatingMessage("Please fill out the rating of this product.");
      return;
    }

    if (
      reviewTitle.trim() === "" ||
      reviewTitle.length > maxTitleCharLimit ||
      reviewBody.trim() === "" ||
      reviewBody.length > maxBodyCharLimit ||
      reviewRating === 0 ||
      reviewRating === null
    ) {
      if (reviewTitle.trim() === "") {
        setTitleMessage("Please fill out the title for your review.");
      } else if (reviewTitle.length > maxTitleCharLimit) {
        setTitleMessage(
          `Title cannot exceed ${maxTitleCharLimit} characters in length.`,
        );
      } else {
        setTitleMessage(null);
      }

      if (reviewBody.trim() === "") {
        setBodyMessage("Please fill out the body of your review.");
      } else if (reviewBody.length > maxBodyCharLimit) {
        setBodyMessage(
          `Body of review cannot exceed ${maxBodyCharLimit} characters in length.`,
        );
      } else {
        setBodyMessage(null);
      }

      if (reviewRating === 0 || reviewRating === null) {
        setRatingMessage("Please add a rating to your review.");
      } else {
        setRatingMessage(null);
      }
      return;
    }

    const reviewData = {
      title: reviewTitle,
      maxTitleCharLimit: maxTitleCharLimit,
      rating: reviewRating,
      body: reviewBody,
      maxBodyCharLimit: maxBodyCharLimit,
      date: Date.now(),
      accountId: localStorage.getItem("userId"),
      itemId: itemId,
      businessId: businessId,
    };

    try {
      const response = await createReview(reviewData);

      //backend validation
      if (response.msg != undefined) {
        if (response.msg.title) {
          setTitleMessage(response.msg.title);
        } else {
          setTitleMessage(null);
        }
        if (response.msg.body) {
          setBodyMessage(response.msg.body);
        } else {
          setBodyMessage(null);
        }
        if (response.msg.rating) {
          setRatingMessage(response.msg.rating);
        } else {
          setRatingMessage(null);
        }
      } else {
        setReviewTitle("");
        setReviewRating(0);
        setReviewBody("");
        setBodyCharCount(0);
        setTitleCharCount(0);

        //clear any error messages
        setTitleMessage(null);
        setBodyMessage(null);
        setRatingMessage(null);

        setReviews((prevReviews) => [response, ...prevReviews]);
        setHasReviewed(true);
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    if (newTitle.length <= maxTitleCharLimit) {
      setReviewTitle(newTitle);
      setTitleCharCount(newTitle.length);
    }
  };

  const handleBodyChange = (e) => {
    const newBody = e.target.value;
    if (newBody.length <= maxBodyCharLimit) {
      setReviewBody(newBody);
      setBodyCharCount(newBody.length);
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <div>
          {hasReviewed ? (
            <>
              <h6>Write a Review</h6>
              <p>
                You have already reviewed this item. Click on the <EditIcon />{" "}
                to edit your current review.
              </p>
            </>
          ) : (
            <div>
              <h6>Write a Review</h6>
              <br></br>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {ratingMessage ? (
                  <label for="rating" style={{ color: "red" }}>
                    {ratingMessage}
                  </label>
                ) : (
                  <></>
                )}
                <div className="ratingContainer">
                  <Rating
                    name="rating"
                    className="rating"
                    defaultValue={0}
                    precision={0.5}
                    size="large"
                    sx={{
                      // Resizing the icons
                      "& .MuiRating-iconFilled": {
                        color: "#e5704c",
                        fontSize: "1.5rem",
                      },
                      "& .MuiRating-iconEmpty": {
                        color: "lightgray",
                        fontSize: "1.5rem",
                      },
                      "& .MuiRating-iconHover": {
                        color: "#e5704c",
                        fontSize: "1.5rem",
                      },
                    }}
                    value={reviewRating}
                    onChange={(e, newValue) => setReviewRating(newValue)}
                  />
                </div>
                <div>
                  {titleMessage ? (
                    <label for="review-title" style={{ color: "red" }}>
                      {titleMessage}
                    </label>
                  ) : (
                    <></>
                  )}
                  <input
                    id="review-title"
                    type="text"
                    value={reviewTitle}
                    onChange={handleTitleChange}
                    placeholder="Title"
                  />
                </div>
                <div>
                  {bodyMessage ? (
                    <label for="review-body" style={{ color: "red" }}>
                      {bodyMessage}
                    </label>
                  ) : (
                    <></>
                  )}

                  <textarea
                    id="review-body"
                    rows={5}
                    value={reviewBody}
                    onChange={handleBodyChange}
                    placeholder="Body"
                  />
                  <p className="review-charLimit">
                    {bodyCharCount}/{maxBodyCharLimit}
                  </p>
                </div>
                <button
                  className="u-Btn--orange"
                  type="submit"
                  // disabled={hasReviewed}
                >
                  Submit Review
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <p>Please sign in to write a review.</p>
      )}
    </>
  );
}
