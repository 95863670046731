import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { ChatboxContext } from "./ChatboxContext";
import { MdOutlineMessage, MdKeyboardArrowLeft } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { getDateForMessages } from "./Date";
import {
  getCustomerToken,
  getBusinessToken,
} from "../../Functions/Auth/getToken";

import axios from "axios";

const Messages = () => {
  const Url = require("../../backendURL");

  const {
    messages,
    setMessages,
    chatOption,
    isBusinessSide,
    userId,
    onConvos,
    setOnConvos,
    isMobile,
  } = useContext(ChatboxContext);

  const [open, setOpen] = useState(false);
  const [imageClass, setImageClass] = useState(
    "p-chatbox__messages__image__popup",
  );

  const divUnderMessages = useRef();

  // Fetching messages based upon the user and the chatOption (conversation)
  const fetchMessages = async () => {
    if (userId == localStorage.getItem("userId")) {
      // Just explicit check without pre-existing state
      const token = await getCustomerToken("/chatbox");
      const messagesData = await axios.get(
        `${Url}chatbox/get-messages/buyer/${chatOption._id}/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMessages(messagesData.data);
    } else {
      const token = await getBusinessToken();
      const messagesData = await axios.get(
        `${Url}chatbox/get-messages/seller/${chatOption._id}/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMessages(messagesData.data);
    }
  };

  useEffect(() => {
    if (chatOption && userId) {
      fetchMessages(); // Initial fetch
    }
  }, [chatOption]);

  // For autoscroll for when messages changes
  useEffect(() => {
    const div = divUnderMessages.current;
    if (div) {
      div.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages]);

  // Button for going back to conversations while in mobile view
  function handleMobileButton(e) {
    e.preventDefault();
    setOnConvos(true);
  }

  // For triggering zoom-in animation for when an image is clicked on
  function triggerAnimationZoomIn() {
    setOpen(true);
    setImageClass("p-chatbox__messages__image__popup--zoomIn");
  }

  // For triggering zoom-out animation for when an zoom-in message is clicked out of
  function triggerAnimationZoomOut() {
    setImageClass("p-chatbox__messages__image__popup--zoomOut");
    setTimeout(() => {
      setOpen(false);
    }, 900);
  }

  return (
    <main
      className={
        onConvos && isMobile
          ? "p-chatbox__messages--hide"
          : "p-chatbox__messages"
      }
    >
      {isMobile ? (
        <button
          className="p-chatbox__messages__backButton"
          onClick={handleMobileButton}
        >
          <MdKeyboardArrowLeft />
          Back to Conversations
        </button>
      ) : (
        ""
      )}
      <h4
        className={
          userId == localStorage.getItem("userId")
            ? "p-chatbox__conversations__title--buyer"
            : "p-chatbox__conversations__title--seller"
        }
      >
        Messages
      </h4>
      <p
        className={
          userId == localStorage.getItem("userId")
            ? "p-chatbox__conversations__itemTitle--buyer"
            : "p-chatbox__conversations__itemTitle--seller"
        }
      >
        {chatOption ? `Item: ${chatOption.subscription_name}` : ""}
      </p>
      <div className="p-chatbox__messages__messageContainer">
        {chatOption ? (
          messages.length > 0 ? (
            messages.map((message) => (
              <div
                className={
                  message.sender == userId
                    ? "p-chatbox__messages--ownMessageContainer"
                    : "p-chatbox__messages--othersMessageContainer"
                }
                key={message._id}
              >
                <div
                  className={
                    message.sender === userId &&
                    userId == localStorage.getItem("userId")
                      ? "p-chatbox__messages--ownMessageContainer--ownMessageBuyer"
                      : message.sender === userId
                      ? "p-chatbox__messages--ownMessageContainer--ownMessageSeller"
                      : "p-chatbox__messages--othersMessageContainer--othersMessage"
                  }
                >
                  <p className="p-chatbox__messages__messageContainer--name">
                    {message.sender === userId
                      ? "You:"
                      : userId === chatOption.buyer_id
                      ? chatOption.business_name
                      : chatOption.buyer_name}
                  </p>
                  <p>{message.text}</p>
                  {/* <p>{userId}</p> */}
                  {message.image_key ? (
                    <img
                      onClick={triggerAnimationZoomIn}
                      className="p-chatbox__messages__image"
                      src={`${Url}images/${message.image_key}`}
                      alt="message image"
                    />
                  ) : (
                    ""
                  )}
                  {message.image_key && open ? (
                    <div onClick={triggerAnimationZoomOut} className="overlay">
                      <section
                        className="l-popupForm"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img
                          className={imageClass}
                          src={`${Url}images/${message.image_key}`}
                          alt="message image"
                        />
                      </section>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    message.sender == userId
                      ? "p-chatbox__messages--ownMessageDateContainer"
                      : "p-chatbox__messages--othersMessageDateContainer"
                  }
                >
                  <p>{getDateForMessages(message.date_now_exclusion)}</p>
                  {message.seen ? (
                    <FaRegCheckCircle className="p-chatbox__messages--ownMessageDateContainer__icon" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>There are no messages in this chat yet.</p>
          )
        ) : (
          <div className="p-chatbox__messages__noConvo">
            <MdOutlineMessage className="p-chatbox__messages__noConvo__icon" />
            <p>
              Select a conversation to see past messages and to send messages.
            </p>
          </div>
        )}
        <div ref={divUnderMessages}></div>
      </div>
    </main>
  );
};

export default Messages;
