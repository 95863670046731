/*******************************************************************************************************
 * Function/File Purpose: This component is for displaying business information and all items from this business. The mai usage of this page is by QR code in the physical store, customer will access to this page.
 * Author: Aoi Kuriki
 * Date: 6/2/2023
 * 
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * *******************************
 * Contributor:
 * Date:
 * What was done:
 * ********************************
 */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RelatedItems from './Components/RelatedItems';
import BusinessBannerInfo from '../ProductDetailPage/Components/BusinessBannerInfo';

const CustomerStoreFront = () => {

  const { businessId } = useParams()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []); 

  return (
    <div className="l-inner">
      <BusinessBannerInfo businessId={businessId} />
      <RelatedItems businessId={businessId} />
    </div>
  );
}

export default CustomerStoreFront