import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import MotionWrapper from './MotionWrapper';

const questions = [
    { id: 1, question: "What is Subitt?", answer: "Subitt is an online marketplace for all things subscriptions. We make it easy for you to see and manage your subscriptions while finding additional subscription options for things you already use at discounted prices."},
    { id: 2, question: "How do I subscribe?", answer: "You can subscribe by selecting a product and choosing a subscription plan. The subscription plans will differ per industry and seller so be sure to explore multiple options!"},
    { id: 3, question: "How do I manage subscriptions?", answer: "You can manage your subscriptions through the My Subscriptions page. This page will list all the subscriptions you subscribed to using Subitt. We are currently working on allowing you to list subscriptions from other platforms so you can manage them all in one place!"},
    { id: 4, question: "What can I subscribe to?", answer: "You can subscribe to a variety of products and services. All kinds of sellers are listing their offerings on Subitt, as long as they are recurring. You may even find things you never thought you could subscribe to before. Make sure to explore all types of sellers!"},
    { id: 5, question: "How do pickups work?", answer: "Pickups are subscriptions you can physically pick-up from the store. Using the Subitt app, simply scan the QR code placed in the store countertop when picking up your subscription. No need to even carry your wallet!"},
];

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  
  const toggleFAQ = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };

  return (
    <div className='faqSectionWrapper'>
      <div className="faqSection">
        <h2 className='faqSectionHeader'>You May Be Wondering</h2>
        {questions.map((item, index) => (
          <MotionWrapper key={item.id} delay={index * 0.2}>
            <div className={`faqItem ${openFAQ === item.id ? 'open' : ''}`}>
              <div className='faqQuestion' onClick={() => toggleFAQ(item.id)}>
                {item.question}
                <FaChevronRight className={`arrowIcon ${openFAQ === item.id ? 'rotated' : ''}`} />
              </div>
              <div className={`faqAnswer ${openFAQ === item.id ? 'open' : ''}`}>{item.answer}</div>
            </div>
          </MotionWrapper>
        ))}
      </div>
    </div>
  )
};

export default FAQ;
