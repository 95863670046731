const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  
  export function getDateForConvo(timestamp) {
    const date = new Date(timestamp);
    const month = date.getMonth();
    const day = date.getDate();
    const namedMonth = months[month];
    const formattedDate = `${namedMonth}-${day}`;
  
    return formattedDate;
  }
  
  export function getDateForMessages(timestamp) {
    const date = new Date(timestamp);
    let amOrPm;
    const month = date.getMonth(); // JavaScript months are 0-indexed
    const namedMonth = months[month];
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
  
    if (hours < 12) {
      amOrPm = "AM";
    } else {
      amOrPm = "PM";
    }
  
    let newHours;
  
    if (hours == 12) {
      newHours = 12;
    } else {
      newHours = hours % 12;
    }
  
    const formattedDate = `${namedMonth}-${day} ${newHours}:${minutes} ${amOrPm}`;
  
    return formattedDate;
  }