import React, { useState, useEffect } from "react";
import { TbMapPinFilled } from "react-icons/tb";
import { downArrow } from "../../icons/business/icons";

const Filters = ({ setParentFilters }) => {
  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  // Search Filters
  const [filters, setFilters] = useState({
    distance: 250,
    // cost: ["$", "$$", "$$$"],
    // discount: 0,
    // rating: 4,
    // sort: "Recommended",

    // + Businesses / Subscriptions Only
  });

  // Update the parent filters whenever any of the filters are updated
  useEffect(() => {
    setParentFilters(filters);
  }, []);

  const toggleDropdown = (filterName) => {
    setOpenDropdown(openDropdown === filterName ? null : filterName);
  };

  // Distance slider
  const handleSliderChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      distance: value,
    }));
    setShowUpdateButton(true);
  };

  // Submit button when filter is changed
  const handleFilterSubmit = () => {
    setShowUpdateButton(false);
    toggleDropdown(null);
    setParentFilters(filters);
  };

  return (
    <div className="c-filter">
      <div className="c-filter__dropdown">
        <div
          className={
            openDropdown === "range"
              ? `c-filter__dropdown__toggle c-filter__dropdown__toggle__active`
              : `c-filter__dropdown__toggle`
          }
          onClick={() => toggleDropdown("range")}
        >
          <TbMapPinFilled
            color={openDropdown === "range" ? "white" : "black"}
            size={23}
          />
          <p>
            {filters.distance === 1000
              ? "Max range"
              : filters?.distance + " miles"}
          </p>
          {downArrow}
        </div>

        {openDropdown === "range" && (
          <div className="c-filter__dropdown__box">
            <h1 className="c-filter__dropdown__box__title">Range:</h1>
            <p>
              {" "}
              {filters.distance === 1000 ? `Max` : `${filters.distance} miles`}
            </p>

            {/* <label
                htmlFor="range"
                className="c-filter__dropdown__box__input"
            ></label> */}
            <input
              type="range"
              min="0"
              max="500"
              step="10"
              list="input-range-markers"
              value={filters.distance === 1000 ? 1000 : filters.distance}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                handleSliderChange(value === 500 ? 1000 : value); // If they turn it all the way up, set it to max range
              }}
            />

            <div className="c-filter__dropdown__buttons">
              <div
                onClick={() => toggleDropdown(null)}
                className="c-filter__dropdown__buttons__cancel"
              >
                Cancel
              </div>

              {showUpdateButton && (
                <div
                  className="c-filter__dropdown__buttons__submit"
                  onClick={handleFilterSubmit}
                >
                  Update
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
