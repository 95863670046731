import React from "react";
import { fixTimeFormat } from "../../../utilities/global_function";
import { Link } from "react-router-dom";
import { confirmTA } from "../../../CustomerStateManagement/SubscriptionsRoute";

const ConfirmAppt = ({
  nextAppointment,
  apptId,
  serviceId,
  businessId,
  subTypeId,
  subTypeIndex,
  setConfirm,
  subscriptionId,
}) => {
  return (
    <div onClick={() => setConfirm(false)} className="overlay">
      <section
        className="confirmContainer"
        onClick={(e) => e.stopPropagation()}
      >
        <p>
          Would you confirm your next Appointment schedule with reserved time
          below?
        </p>
        <p>{fixTimeFormat(nextAppointment)}</p>
        <div className="buttons">
          <button
            style={{ backgroundColor: "#979797" }}
            onClick={() => setConfirm(false)}
          >
            Exit
          </button>
          <button
            style={{ backgroundColor: "#D2532A" }}
            onClick={() => confirmTA(apptId)}
          >
            Confirm
          </button>
          <Link
            to={`/TimeAvailability/${serviceId}/${businessId}`}
            className="subscriptionLink"
            state={{
              serviceId: serviceId,
              businessId: businessId,
              subTypeId: subTypeId,
              index: subTypeIndex,
              isFirst: false,
              apptId: apptId,
            }}
          >
            <button style={{ backgroundColor: "#7F7CBE" }}>Other Time</button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default ConfirmAppt;
