import React from "react";
import "./Styles/Atom.css"

const Subtitle = ({ subtitle }) => {
  return (
  <h2 className="subtitleTemplate">{subtitle}</h2>
  );
};

export default Subtitle;
