import React, { useState } from "react";
import UserSettingsCard from "./UserSettingsCard";
import { BsPersonFill } from "react-icons/bs";
import { FaBell, FaHistory, FaStripeS, FaDollarSign } from "react-icons/fa";
import { MdSubscriptions, MdLocalShipping, MdLocationOn } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { customerStripeAccess } from "../../../CustomerStateManagement/CustomerState";
import Profile from "./Profile";
import Security from "./Security";
import ShippingInfo from "./ShippingInfo";

export default function UserSettings() {
  const [active, setActive] = useState(null);
  const handleClick = (section) => {
    if (active != section) {
      setActive(section);
    } else {
      setActive(null);
    }
  };
  return (
    <div className="userDetailsSettings">
      <div className="userDetailsSettingsCategory">
        <h5 className="p-accountHeader">Account Settings</h5>
        <div className="userDetailsSettingsCards">
          <button
            style={{ backgroundColor: "transparent" }}
            onClick={() => handleClick("profile")}
          >
            <UserSettingsCard
              icon={<BsPersonFill />}
              header="Profile"
              subheader="Edit user information, usernames, emails, and notifications"
              link={active == "profile" ? "open" : "close"}
            />
          </button>
          <button
            style={{ backgroundColor: "transparent" }}
            onClick={() => handleClick("security")}
          >
            <UserSettingsCard
              icon={<IoIosLock />}
              header="Security"
              subheader="Change passwords, deactivate, or delete account"
              link={active == "security" ? "open" : "close"}
            />
          </button>
          <button
            style={{ backgroundColor: "transparent" }}
            onClick={() => handleClick("shipping")}
          >
            <UserSettingsCard
              icon={<MdLocationOn />}
              header="Address Settings"
              subheader="Edit your address information, change or delete old addresses"
              link={active == "shipping" ? "open" : "close"}
            />
          </button>
          {/* <UserSettingsCard
            icon={<FaBell />}
            header="Notifications"
            subheader="Disable or select which notifications you'd like to receive"
            link="user/notifications"
          /> */}
        </div>
      </div>
      {active == "profile" ? <Profile /> : <></>}
      {active == "security" ? <Security /> : <></>}
      {active == "shipping" ? <ShippingInfo /> : <></>}

      <div className="userDetailsSettingsCategory">
        <h5>Subscription Settings</h5>
        <div className="userDetailsSettingsCards">
          <UserSettingsCard
            icon={<MdSubscriptions />}
            header="Current Subscriptions"
            subheader="View and edit your current subscriptions"
            link="mySubscriptions"
          />
          <UserSettingsCard
            icon={<FaHistory />}
            header="Subscription History"
            subheader="View all prior and current subscriptions, transaction history"
            link="subscriptionsHistory"
          />
        </div>
      </div>
      <div className="userDetailsSettingsCategory">
        <h5>Billing Settings</h5>
        <div className="userDetailsSettingsCards">
          <UserSettingsCard
            icon={<FaStripeS />}
            header="User Portal"
            subheader="Direct method to changing banking information through Stripe"
            link="user/customerStripeRedirect"
          />
          {/* <UserSettingsCard
            icon={<FaDollarSign />}
            header="Billing Info"
            subheader="Edit your card information, add new cards, removed old ones"
            link="Coming"
          /> */}
        </div>
      </div>
    </div>
  );
}
