import React from "react";
import { Link } from "react-router-dom";
const Url = require("../../../../../backendURL");

const PromotionCard = ({ promotion, exampleImage }) => {
  return (
    <Link
      className="c-promoCard"
      to={`${promotion?.promoted?.url}`}
      style={{
        backgroundColor:
          promotion?.theme?.color?.length > 0
            ? `${promotion?.theme?.color}`
            : "FFF",
      }}
    >
      <div className="c-promoCard__info">
      <h1
        className="c-promoCard__title"
        style={{
          color:
            promotion?.theme?.subColor?.length > 0
              ? `${promotion?.theme?.subColor}`
              : "000",
        }}
      >
        {promotion?.display?.title}
      </h1>
      <h2 className="c-promoCard__subtitle">{promotion?.display?.subtitle}</h2>
      {/* {promotion?.startDate && (
        <p className="c-promoCard__startDate">
          Available starting {promotion?.startDate}
        </p>
      )} */}
      {/* {promotion?.endDate && (
        <p className="c-promoCard__endDate">Ends {promotion?.endDate}</p>
      )} */}

        {promotion?.display?.buttonText && (
        <div
          className="c-promoCard__button"
          style={{
            backgroundColor:
              promotion?.theme?.subColor?.length > 0
                ? `${promotion?.theme?.subColor}`
                : "FFF",
          }}
        >
          {promotion?.display?.buttonText}
        </div>
      )}
      </div>
      {promotion?.display?.image && (
        <img
          className="c-promoCard__image"
          src={
            !exampleImage
              ? `${Url}images/${promotion?.display?.image}`
              : `${exampleImage}`
          }
          alt={`${promotion?.display?.title} promotion`}
        />
      )}

    </Link>
  );
};
export default PromotionCard;
