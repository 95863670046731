import React from 'react'
import { IoMdClose } from 'react-icons/io'

const PopUp = ({children, setOpen,cStyle={}}) => {
  return (
    <div onClick={()=>setOpen(false)} className="overlay">
    <section 
      className='l-popupForm' 
      onClick={(e) => e.stopPropagation()}
      style={cStyle}
      >
      {children}
      <span onClick={()=>setOpen(false)} className='l-popupForm--close'><IoMdClose /></span>
    </section>
    </div>
  )
}

export default PopUp