import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Categories from "../../Components/NewCategories";
import SEO from "../../../Component/Atom/SEO";

import { FeedContext } from "../../Routes/CustomerRoutes";

const AllCategories = () => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

  return (
    <div className="p-allCategories">
      <SEO title="Browse" />
      <div className="p-allCategories__categoryList">
        {Categories.map((category, index) => (
          <Link
            to={`${category.route}`}
            key={index}
            className="p-allCategories__category"
          >
            <div className="p-allCategories__category__header">
              <span className="p-allCategories__category__icon">
                {category.icon}
              </span>
              <h2 className="p-allCategories__category__name">
                {category.name}
              </h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
