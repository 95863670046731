import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeartButton from "../../../../../Component/Atom/HeartButton";
import subittBanner from "../../../../../Assets/subittBanner.jpg";
import { AccountContext } from "../../../../Routes/CustomerRoutes";
import { calculateDistance } from "../../../../../Functions/Formulas/calculateDistance";
import { formatPrice } from "../../../../../Functions/Global/formatPrice";
import { DiscountConvert } from "../../../../utilities/global_function";
import { intervalFormat } from "../../../../../Functions/Global/intervalFormat";
import { FaStar } from "react-icons/fa";
const Url = require("../../../../../backendURL");

const SubscriptionCard = ({ product }) => {
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const {
    _id,
    avatarImg,
    averageRating,
    reviewCount,
    category,
    location,
    isClosed,
    membershipType,
    itemType,
    name,
    images,
    business,
    businessName,
    subTypes,
    numSubscribers,
    unitLabel,
  } = product;

  const [distance, setDistance] = useState(0);
  const [selectedSubTypeIndex, setSelectedSubTypeIndex] = useState(0);
  const [selectedSubType, setSelectedSubType] = useState();
  const [price, setPrice] = useState();
  const [minCount, setMinCount] = useState();
  const [discount, setDiscount] = useState();
  const [coupon, setCoupon] = useState();
  const [couponInterval, setCouponInterval] = useState();
  const [interval, setInterval] = useState();
  const [intervalCount, setIntervalCount] = useState();
  const [freeTrial, setFreeTrial] = useState(false);
  const [unit, setUnit] = useState();

  // coupon : "tCl0hdW6"
  // couponInterval:1
  // couponPercent:5
  // interval:"month"
  // interval_count:1
  // price:20000
  // stripePriceId:"U2FsdGVkX18087Zznin8/Zuark+/y/eArBDlzpyyBXPx30Gj5kcWZACMN0Nor+ti"
  // unit:10

  // (price / unit) * minimum

  useEffect(() => {
    setSelectedSubType(subTypes[selectedSubTypeIndex]);
    setPrice(selectedSubType?.price);
    setDiscount(selectedSubType?.discount);
    setCoupon(selectedSubType?.coupon);
    setCouponInterval(selectedSubType?.couponInterval);
    setInterval(selectedSubType?.interval);
    setIntervalCount(selectedSubType?.interval_count);
    // setUnit(selectedSubType?.units);
    setUnit(selectedSubType?.unit);
    setMinCount(selectedSubType?.minCount);
  }, [selectedSubType, selectedSubTypeIndex]);

  useEffect(() => {
    if (subTypes?.some((subtype) => subtype.isFreeTrialEnabled)) {
      setFreeTrial(true);
    } else {
      setFreeTrial(false);
    }
  }, [subTypes]);

  // Update distance to user
  useEffect(() => {
    if (
      !userInfo?.address?.geometry?.coordinates ||
      !location?.geometry?.coordinates
    )
      return;

    setDistance(
      calculateDistance(
        location?.geometry?.coordinates,
        userInfo?.address?.geometry?.coordinates,
      ).toFixed(1),
    );
  }, [userInfo?.address?.geometry?.coordinates]);

  return (
    <Link
      to={`/itemDetail/${itemType}/${business}/${_id}`}
      className="c-subscriptionCard"
    >
      {/* <HeartButton
        liked={liked}
        wishlist={wishlist}
        className={"p-itemCard__img--heartButton"}
        data={data}
      /> */}

      {/* Image section */}
      <div className="c-subscriptionCard__top">
        {images[0] ? (
          <img
            src={`${Url}images/${images[0]}`}
            alt={`${name} thumbnail`}
            onError={(e) => {
              e.target.src = subittBanner;
            }}
          />
        ) : (
          <img
            src={subittBanner}
            alt={`$Default Subitt thumbnail`}
            onError={(e) => {
              e.target.src = subittBanner;
            }}
          />
        )}
      </div>

      {/* Content section */}
      <div className="c-subscriptionCard__content">
        <div className="c-subscriptionCard__line1">
          <div className="c-subscriptionCard__name">
            <p>{name}</p>
          </div>

          {freeTrial && (
            <p className="c-subscriptionCard__freeTrial">Free trial</p>
          )}

          {/* Subtype selection */}
          {/* <div className="c-subscriptionCard__subtypes">
            {subTypes?.slice(0, 3).map((subtype, index) => (
              <div
                key={index}
                className={`c-subscriptionCard__subtypeButton ${
                  selectedSubTypeIndex === index ? "subtype__active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedSubTypeIndex(index);
                }}
              >
                {subtype?.couponInterval}
                {subtype?.interval?.charAt(0).toUpperCase()}
              </div>
            ))}

            {subTypes?.length > 1 && (
              <Link
                to={`/itemDetail/${itemType}/${business}/${_id}`}
                className="c-subscriptionCard__subtypeButton"
              >
                +
              </Link>
            )}
          </div> */}
        </div>

        <div className="c-subscriptionCard__line2">
          {/* Average Rating (default to 5) */}
          <div className="c-subscriptionCard__avgRating">
            <FaStar size={15} color="#ff642d" />
            <p>{`${averageRating || 0} (${reviewCount || 0} review${
              reviewCount === 1 ? "" : "s"
            })`}</p>
          </div>
          {/* )} */}

          {/* Distance to user */}
          {location?.geometry?.coordinates &&
            userInfo?.address?.geometry?.coordinates && (
              <>
                <p>&bull;</p>
                <p className="c-subscriptionCard__distance">{`${distance} mi`}</p>
              </>
            )}

          {/* Num of subscribers */}
          {numSubscribers > 0 && (
            <>
              <p className="c-subscriptionCard__subscribers">
                &bull;{" "}
                {`${numSubscribers} sub${numSubscribers === 1 ? "" : "s"}`}
              </p>
            </>
          )}
        </div>

        <div className="c-subscriptionCard__line3">
          <p className="c-subscriptionCard__businessName">{businessName}</p>
          <div className="c-subscriptionCard__price">
            {selectedSubType?.minCount ? (
              <>
                ${formatPrice((price / selectedSubType.unit) * minCount)}{" "}
                {intervalFormat(intervalCount, interval)}
              </>
            ) : (
              <>
                ${formatPrice(price)} / {unit} {unitLabel}
              </>
            )}
            {/* {unitLabel} */}
          </div>

          {/* <p className="c-subscriptionCard__interval">
            {interval?.charAt(0).toUpperCase() + interval?.slice(1)}ly
            Subscription
          </p> */}
        </div>
        {/* <p className="c-subscriptionCard__description">{description}</p> */}
      </div>

      {/* {freeTrial && (
        <div className="c-subscriptionCard__line4">
          <p>Free trial</p>
        </div>
      )} */}
    </Link>
  );
};

export default SubscriptionCard;
