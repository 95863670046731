import axios from "axios";
import { toast } from "react-hot-toast";
import { getCustomerToken } from "../../Functions/Auth/getToken";
const Url = require("../../backendURL");
const accountId = localStorage.getItem("userId")




/*************************************
 * My Subscription
 ***********************************/

/** Get All
************************/
export const getObjectsForMySubscription = async(canceled=false) => {
  const serviceRes = await getSubscriptionsByAccount(canceled)
  const productRes = await getOrdersByAccountForMySubscription(canceled)
  const membershipRes = await getPassesByAccountForMySubscription(canceled)
  const MySubscriptionObject = [...serviceRes, ...productRes, ...membershipRes]
  return MySubscriptionObject

}
/*************************************
 * Subscription - Service
 ***********************************/

/** Get All
************************/

export const getSubscriptionsByAccount = async (canceled) => {
  try {
    const token = await getCustomerToken("/MySubscriptions")
    // calling subscription object
    const res = await axios.get(`${Url}subscriptions/mySubscriptions/${accountId}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })

    if (res.status === 200) {
      const dataTemporaryContainer = await Promise.all(res.data.docs.map(async (d) => {
        try {
          if(canceled ? d.renewalDate === "cancelled" || d.renewalDate === "Cancelled" : d.renewalDate !== "cancelled" && d.renewalDate !== "Cancelled"){
  
          const [serviceRes, appRes] = await Promise.all([
            axios.get(`${Url}services/${d.service}`),
            axios.get(`${Url}appointments/${d.appointment}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              }
            })
          ])

          if (appRes.status === 200 && serviceRes.status === 200) {
            const combineObject = {
              recurring: d,
              appointment: appRes.data.docs,
              item: serviceRes.data.doc,
              type:"service"
            }
            return combineObject;
          }
        } 
        }catch (err) {
          console.log("MultiRequest Part", err)
          return []
        }
      }));

       // Filter out undefined results
      const filteredData = dataTemporaryContainer.filter(item => item !== undefined);

      return filteredData;
    } else {
      console.log("Error in API call, status:", res.status);
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

/** Get All Histories
************************/



/** Cancel
************************/

export const cancelSubscription=async (subittSubscriptionId)=>{

  try{
    const token = await getCustomerToken("/MySubscriptions")
    const res = await axios.patch(`${Url}accounts/deleteSubscription/${accountId}/${subittSubscriptionId}`,{},{
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    if(res.status === 200){
      window.location.reload();
      // return { success: true };
    }
  }catch(err){
    console.log(err)
    return { success: false, error: err };
  }
}

/** Confirm
************************/

export const confirmTA = async (appointmentId) => {

  try {
    const token = await getCustomerToken("/MySubscriptions")
    const res = await axios.patch(`${Url}appointments/confirmAppointment/${appointmentId}`,{},{
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    if(res.status == 200){
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    }
} catch (error) {
  console.error('Error fetching data:', error);
}
}

/** Reschedule
************************/

export const rescheduleTA =async (appointmentId,selectedTime)=>{

  try {
    const token = await getCustomerToken("/MySubscriptions")
    await axios.patch(`${Url}appointments/chooseNewAppointment/${appointmentId}`,{"dateTime":selectedTime},
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
} catch (error) {
  console.log('Error fetching data:', error);
}
}

/*************************************
 * Order - Product
 ***********************************/

/** Get All
************************/


export const getOrdersByAccountForMySubscription = async(canceled) => {
  try{
    const token = await getCustomerToken("/MySubscriptions")
    // calling subscription object
    const res = await axios.get(`${Url}orders/mySubscriptions/${accountId}`,{
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
    if (res.status === 200) {
      const dataTemporaryContainer = await Promise.all(res.data.docs.map(async (d) => {
        try {
          if(canceled ? d.renewalDate === "cancelled" || d.renewalDate === "Cancelled" : d.renewalDate !== "cancelled" && d.renewalDate !== "Cancelled"){
          const [productRes] = await Promise.all([
            axios.get(`${Url}products/${d.product}`)
          ])
          if (productRes.status === 200) {
            const combineObject = {
              recurring: d,
              item: productRes.data.doc,
              type:"product"
            }
            return combineObject;
          }
        }
        } catch (err) {
          console.log("MultiRequest Part", err)
          return []
        }
      }));

        // Filter out undefined results
      const filteredData = dataTemporaryContainer.filter(item => item !== undefined);

      return filteredData;
    } else {
      console.log("Error in API call, status:", res.status);
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}


/** Cancel
************************/

/** Cancel
************************/

export const cancelOrder=async (passId)=>{

  try{
    const token = await getCustomerToken("/MySubscriptions")
    const res = await axios.patch(`${Url}accounts/deleteOrder/${accountId}/${passId}`,{},{
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    if(res.status === 200){
      window.location.reload()
      // return { success: true };
    }
  }catch(err){
    console.log(err)
    return { success: false, error: err };
  }
}


/*************************************
 * Pass - Membership
 ***********************************/

/** Get All
************************/


export const getPassesByAccountForMySubscription = async(canceled) => {
  try{
    const token = await getCustomerToken("/MySubscriptions")
    // calling subscription object
    const res = await axios.get(`${Url}passes/mySubscriptions/${accountId}`,{
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
    if (res.status === 200) {
      const dataTemporaryContainer = await Promise.all(res.data.docs.map(async (d) => {
        try {
          if(canceled ? d.renewalDate === "cancelled" || d.renewalDate === "Cancelled" : d.renewalDate !== "cancelled" && d.renewalDate !== "Cancelled"){
          const [membershipRes] = await Promise.all([
            axios.get(`${Url}memberships/${d.membership}`)
          ])
          if (membershipRes.status === 200) {
            const combineObject = {
              recurring: d,
              item: membershipRes.data.doc,
              type:"membership"
            }
            return combineObject;
          }
        }
        } catch (err) {
          console.log("MultiRequest Part", err)
          return []
       }
      }));
  // Filter out undefined results
      const filteredData = dataTemporaryContainer.filter(item => item !== undefined);

      return filteredData;
    } else {
      console.log("Error in API call, status:", res.status);
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
}

/** Cancel
************************/

export const cancelPass=async (passId)=>{

  try{
    const token = await getCustomerToken("/MySubscriptions")
    const res = await axios.patch(`${Url}accounts/deletePass/${accountId}/${passId}`,{},{
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    if(res.status === 200){
      window.location.reload()
      // return { success: true };
    }
  }catch(err){
    console.log(err);
    return { success: false, error: err};
  }
}



/*************************************
 * Refresh TA
 ***********************************/

export const refreshTA =async (serviceId, subId)=>{

  try {
    const token = await getCustomerToken("/MySubscriptions")
    await axios.patch(`${Url}services/refreshTA/${serviceId}/${subId}`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
} catch (error) {
  console.error('Error fetching data:', error);
  return []
}
}

/*************************************
 * Use Pick Up
 ***********************************/

export const redeemItem =async (passId)=>{

  try {
    const token = await getCustomerToken()
    const res = await axios.patch(`${Url}passes/customerRedeemPass/${passId}`,{},
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
    if(res.status === 200){
      return res.status
    }
} catch (error) {
  console.error('Error fetching data:', error);
  return []
}
}

/*************************************
 * Update SubType
 ***********************************/

export const patchSubTypes = async(type,recurringId, newSubTypeId) => {

  const itemTypeSub = {
    service:"subscriptions",
    product:"orders",
    membership:"passes"
  }

  try{
    const token = await getCustomerToken("/MySubscriptions")
    const res = await axios.patch(`${Url}${itemTypeSub[type]}/changeTier/${recurringId}/${newSubTypeId}`,{},
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
    if(res.status === 200){
      return true
    }
  }catch(err){
    console.log(err)
    return false
  }

}