import toast from "react-hot-toast"

export const emailValidation = (email) => {

  if(!email){
    toast.error("email is not filled")
    return false
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    toast.error("Invalid email format");
    return false;
  }
  return true
}