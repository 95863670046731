import axios from "axios";
import { getBusinessToken } from "../../Functions/Auth/getToken";

const Url = require("../../backendURL");
const businessId = localStorage.getItem("businessId");

// Check if the user is a business Owner and set the varibles for that
export const getBusinessOwner = async () => {
  const userId = localStorage.getItem("userId");
  try {
    const response = await fetch(Url + "business/owner-businesses/" + userId);
    if (response.status == 200) {
      const data = await response.json();
      if (data.docs[0]) {
        localStorage.setItem("IsOwner", true);
      }
      return data.docs;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/** Get Business By Id
 ***********************************/

export const getBusinessById = async (businessId) => {
  try {
    const response = await axios.get(Url + "business/" + businessId);

    if (response.status == 200) {
      const data = response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

// export const BusinessSetUpChecker = async() => {
//   try{
//     const isStripe = await
//   }catch(err){
//     console.log(err)
//     return false
//   }
// }

/** Edit Business Password
 ***********************************/

export const editBusinessPassword = async (data) => {
  const token = await getBusinessToken();
  try {
    const response = await axios.patch(
      `${Url}business/editPassword/${businessId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status == 200) {
      return response.status;
    }
  } catch (error) {
    if (error.response.status === 404) {
      throw error.response.data.err;
    } else {
      throw `error ocurred`;
    }
  }
};

/** Edit Business Profile
 ***********************************/

export const editBusiness = async (updatedbusinessObject) => {
  const token = await getBusinessToken();
  try {
    let res = await axios.patch(
      `${Url}business/edit/${businessId}`,
      updatedbusinessObject,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log("edit business end", res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }
};


export const editBusinessCategory = async (categories) => {
  const token = await getBusinessToken();
  const businessId = localStorage.getItem("businessId");

  try {
    const res = await axios.patch(
      `${Url}business/edit/categories/${businessId}`,
      { categories },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const patchBusinessLocation = async (location) => {
  const token = await getBusinessToken();

  try {
    const body= {
      location
    }
    let res = await axios.patch(
      `${Url}business/setLocation/${businessId}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }
};

export const accessStripe = async () => {
  try {
    const token = await getBusinessToken();
    const res = await axios.get(`${Url}business/newLink/${businessId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      await window.open(res.data.url, "_blank");
    }
  } catch (err) {}
};

export const getStripeStatus = async () => {
  try {
    const token = await getBusinessToken();
    const res = await axios.get(`${Url}business/requirements/${businessId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      const errors = res.data.docs;
      let errorList = [];
      if (errors.length !== 0) {
        errors.forEach((error) => {
          let parts = error.split(".");
          let key = parts[parts.length - 1];
          errorList.push(key);
        });
      }
      return errorList;
    }
  } catch (err) {
    console.log(err);
  }
};

/*--------------------- Product ---------------------*/

/*************************************
 * Get all Products for a Business
 ***********************************/

export const getAllProductsByBusinessId = async (id) => {
  const token = await getBusinessToken();
  try {
    const response = await axios.get(`${Url}products/business-products/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      let data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Get shipping rate options for product creation
 ***********************************/

export const getShippingRate = async (data) => {
  const token = await getBusinessToken();
  try {
    const response = await axios.patch(
      `${Url}products/productRateSelection`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status == 200) {
      let data = await response.data.docs;
      return data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw "could not get shipping rate";
  }
};

/*---------------------  Business ---------------------*/

/*************************************
 * Get StripeLink
 ***********************************/

export const getStripeLink = async (id) => {
  try {
    const response = await fetch(`${Url}services/business/${id}`);
    if (response.status == 200) {
      const data = await response.json();
      return data.doc;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*---------------------  Service ---------------------*/

/*************************************
 * Get all Services for a Business
 ***********************************/

export const getAllServicesByBusinessId = async (id) => {
  const token = await getBusinessToken();

  try {
    const response = await axios.get(`${Url}services/business/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      let data = await response.data.docs;

      return await adjustSubCount(data);
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Add Availability to service
 ***********************************/

export const AddServiceTA = async (objectId, subTypeId, data) => {
  const token = await getBusinessToken();

  try {
    await axios.patch(`${Url}services/addTA/${objectId}/${subTypeId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error.response.data.msg;
  }
};

/*************************************
 * Get subcount for one item
 ***********************************/

export const getSubCount = async (serviceId, subCountId) => {
  const token = await getBusinessToken();

  try {
    const response = await axios.get(
      `${Url}services/getSlots/${serviceId}/${subCountId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.status == 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Adjust subcount for multi services
 ***********************************/

export const adjustSubCount = async (data) => {
  let returnContainer = [];

  // for each service object, get subcounts and add into objects. It may make loading time slow

  await Promise.all(
    data.map(async (d) => {
      const serviceId = d._id;
      const subCountId = d.subTypes[0]._id;

      try {
        const response = await axios.get(
          `${Url}services/getSlots/${serviceId}/${subCountId}`,
        );
        if (response.status == 200) {
          const subCounts = response.data;
          d.total = subCounts.total;
          d.open = subCounts.open;
          returnContainer.push(d);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    }),
  );
  return returnContainer;
};

/*---------------------  Time Availability ---------------------*/

/*************************************
 * Refresh TA
 ***********************************/

export const refreshTA = async (serviceId, subId) => {
  const token = await getBusinessToken();
  try {
    await axios.patch(`${Url}services/refreshTA/${serviceId}/${subId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*---------------------  Orders ---------------------*/

/*************************************
 * get Orders by business Id
 ***********************************/

export const getOrdersByBusinessId = async (id) => {
  const token = await getBusinessToken();
  try {
    const response = await axios.get(`${Url}orders/business/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      let data = await response.data.docs;

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * get Orders rate
 ***********************************/

export const getOrdersRate = async (id) => {
  const token = await getBusinessToken();
  try {
    const response = await axios.patch(`${Url}orders/getRates/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      let data = await response.data.docs;

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const getAllBusiness = async () => {
  try {
    const res = await axios.get(`${Url}business`);
    if (res.status === 200) {
      return res.data.docs;
    }
  } catch (err) {
    console.log(err);
  }
};

export const createNewBusiness = async (business) => {
  console.log('Business received', business)
  try {
    const res = await axios.post(`${Url}business/createBusiness`, business)
    console.log('Res received', res)
    if (res.status === 400) {
      console.log('400 error:', res.data)
      return res.data.msg
    } else if (res.status === 200) {
      return res
    } 
  } catch (error) {
    console.log('Error', error)
    return (error.response)
  }
}

export const navigateStripe = async () => {
  const businessId = localStorage.getItem("businessId");
  const token = await getBusinessToken();
  try {
    const res = await axios.get(`${Url}business/newLink/${businessId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      await window.open(res.data.url, "_blank");
    }
  } catch (err) {}
};
