import React, { Suspense, useEffect, useState } from "react";
import "./Styles/MySubscriptions.css";
import SubscriptionCard from "./Components/SubscriptionCard";
import toast, { Toaster } from "react-hot-toast";
import {
  confirmTA,
  getObjectsForMySubscription,
} from "../../CustomerStateManagement/SubscriptionsRoute";
import AppointmentDashboard from "./Components/AppointmentDashboard";
import Overview from "./Components/Overview";
import OrderDashboard from "./Components/OrderDashboard";
import { CustomerInterceptApiCall } from "../../utilities/checkCustomerJWT";
import TypeTab from "../../../Component/Atom/TypeTab";

const MySubscription = () => {
  const [type, setType] = useState("all");
  // original Data array of subscriptions
  const [originalSubscriptions, setOriginalSubscriptions] = useState([]);
  // using
  const [subscriptions, setSubscriptions] = useState([]);
  const [service, setService] = useState([]);
  const [product, setProduct] = useState([]);
  const [membership, setMembership] = useState([]);

  const [subscriptionLength, setSubscriptionLength] = useState([0, 0, 0, 0]);

  const [totalCost, setTotalCost] = useState(0);
  const [totalSaving, setTotalSaving] = useState(0);

  const [loading, setLoading] = useState(true);
  const [openAppointmentDash, setOpenAppointmentDash] = useState(false);
  const [openShippingDash, setOpenShippingDash] = useState(false);

  // Call all subscriptions for the customer
  useEffect(() => {
    const getSubscriptions = async () => {
      const res = await getObjectsForMySubscription();
      setOriginalSubscriptions(res);
      setSubscriptions(res);
      getLength(res);
      setLoading(false);
    };
    getSubscriptions();
  }, []);

  const getLength = (subscriptions) => {
    let serviceCount = 0;
    let productCount = 0;
    let membershipCount = 0;

    subscriptions.forEach((subscription) => {
      switch (subscription?.type) {
        case "service":
          serviceCount++;
          setService((prevServiceSubscriptions) => [
            ...prevServiceSubscriptions,
            subscription,
          ]);
          break;
        case "product":
          productCount++;
          setProduct((prevProductSubscriptions) => [
            ...prevProductSubscriptions,
            subscription,
          ]);
          break;
        case "membership":
          membershipCount++;
          setMembership((prevMembershipSubscriptions) => [
            ...prevMembershipSubscriptions,
            subscription,
          ]);
          break;
        default:
          // Handle other types if needed
          break;
      }
    });
    const totalLength = subscriptions.length;
    setSubscriptionLength([
      totalLength,
      serviceCount,
      productCount,
      membershipCount,
    ]);
  };

  const confirmAppt = async (appointmentId) => {
    try {
      await confirmTA(appointmentId);
      toast.success("Your next appointment correctly confirmed");
      setTimeout(window.location.reload(), 4000);
    } catch (err) {
      console.log("confirm appt error", err);
    }
  };

  // Filter subscription by the type
  useEffect(() => {
    if (type !== "all") {
      const filteredSubscriptions =
        originalSubscriptions.length > 0
          ? originalSubscriptions.filter((item) => item?.type === type)
          : [];
      setSubscriptions(filteredSubscriptions);
    } else {
      setSubscriptions(originalSubscriptions);
    }
  }, [type]);

  useEffect(() => {
    // aggregate Total for dashboard
    let totalCosts = 0;
    let savingAmounts = 0;
    if (subscriptions.length > 0) {
      subscriptions.forEach((subscription) => {
        totalCosts += subscription.recurring.totalAmount;
        savingAmounts += subscription.recurring.discountedAmount;
      });
    }
    setTotalCost(totalCosts);
    setTotalSaving(savingAmounts);
  }, [subscriptions]);

  return (
    <section className="l-inner">
      <div className="p-mySubscription__container">
        <Toaster />
        {/* Page Title */}
        <h4 className="p-mySubscription__title">My Subscriptions</h4>

        {/* Section Tab Lists */}
        {/* <TypeTab
          type={type}
          setType={setType}
          subscriptionLength={subscriptionLength}
        /> */}

        {/* Main Container */}
        <div className="p-mySubscription__main">
          {/* Subscription Cards */}
          <div className="p-mySubscription__managements">
            {loading ? (
              // Loading component
              <p>Loading...</p>
            ) : subscriptions.length > 0 ? (
              // List of SubscriptionCard components
              subscriptions.map((subscription) => (
                <SubscriptionCard
                  key={subscription?.id}
                  data={subscription}
                  type={subscription?.type}
                />
              ))
            ) : (
              // No item component
              <p>No subscriptions available.</p>
            )}
          </div>

          {/* Summary Dashboard Section */}
          {/* <div className="p-mySubscription__summaries">
            {/* Overview Card */}
            {/* <Overview totalCost={totalCost} totalSaving={totalSaving} /> */}

            {/* Appointment Dashboard Section */}
            {/* {["service", "all"].includes(type) && ( */}
            {/* //   <AppointmentDashboard */}
            {/* //     service={service}
            //     openAppointmentDash={openAppointmentDash}
            //     setOpenAppointmentDash={setOpenAppointmentDash}
            //   />
            // )} */}

            {/* Order Dashboard section */}
            {/* {["product", "all"].includes(type) && (
            //   <OrderDashboard
            //     product={product}
            //     openShippingDash={openShippingDash}
            //     setOpenShippingDash={setOpenShippingDash}
            //   />
            // )} */}
          {/* </div> */} 
        </div>
      </div>
    </section>
  );
};

export default MySubscription;
