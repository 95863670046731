export const intervalFormat = (intervalCount, interval) => {

  const key = intervalCount + interval;
  const subTypesIntervalPicker = {
    "1week": "Weekly",
    "2week": "2 Weekly",
    "1month": "Monthly",
    "2month": "2 Monthly",
    "3month": "3 Monthly",
    "4month": "4 Monthly",
    "6month": "Half-Yearly",
    "1year": "Yearly",
  };
  return subTypesIntervalPicker[key];
}