import React from "react";
import { Switch } from "../../../../GlobalComponents";
import AccountDetailContainer from "./AccountDetailContainer";

const Notifications = () => {
  return (
    <AccountDetailContainer subtitle="Notifications">
      <div className="notificationsContainer">
        <table>
          <tr>
            <th>
              <p>
                Notified when Subscription is nearing renewal via Email, or Text
                Message
              </p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when product has arrived or has been Serviced</p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when Subscription is nearing fulfillment date</p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when beginning a new subscription</p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when canceling a subscription</p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when Business Owner messages you </p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when Business Owner makes an announcement</p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
          <tr>
            <th>
              <p>Notified when Business Owner makes a price change</p>
            </th>
            <td>
              <Switch />
            </td>
          </tr>
        </table>
      </div>
    </AccountDetailContainer>
  );
};

export default Notifications;
