import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import Promotions from "./SubscriptionComponents/Promotions";
import Filters from "../../../Components/Filters";
import { Footer } from "../../../../GlobalComponents";
import { FeedContext } from "../../../Routes/CustomerRoutes";
import { AccountContext } from "../../../Routes/CustomerRoutes";
import { feedData } from "./FeedContent/FeedGenerator";

const SubscriptionFeed = () => {
  const [userInfo, setUserInfo] = useContext(AccountContext); // Get current data
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [filters, setfilters] = useState({});

  const [index, setIndex] = useState(0);
  const [feedRows, setFeedRows] = useState([]);
  const loadMoreTrigger = useRef(null);

  // Function to add next row from RowData to the FeedRows array, which will let it be rendered
  const loadNextRow = useCallback(() => {
    setFeedRows((prev) => {
      const nextIndex = prev.length;
      if (nextIndex >= feedData.length) return prev; // Stop if all rows are loaded

      // Load the next 3 rows
      const newRows = feedData.slice(nextIndex, nextIndex + 3);
      return [...prev, ...newRows];
    });
  }, [filters]);

  // Load initial row on load and if filters get changed
  useEffect(() => {
    setFeedRows([]);
    setIndex(0);
    loadNextRow();
  }, [userInfo?.address?.geometry?.coordinates, filters]);

  useEffect(() => {
    // Load another row when loadMoreTrigger is visible
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadNextRow();
        }
      },
      { threshold: 0.4 },
    );

    if (loadMoreTrigger.current) {
      observer.observe(loadMoreTrigger.current);
    }

    return () => {
      if (loadMoreTrigger.current) {
        observer.unobserve(loadMoreTrigger.current);
      }
    };
  }, []);

  return (
    <div className="p-subscriptionFeed">
      {/* Promotion cards */}
      <Promotions />

      {/* Filter Section */}
      <div className="p-subscriptionFeed__filters">
        <Filters setParentFilters={setfilters} />
      </div>

      {/* Feed section */}
      <div className="p-subscriptionFeed__feed">
        {feedRows.map((row, i) => {
          const { component: RowComponent, ...rowProps } = row;
          return <RowComponent key={i} {...rowProps} filters={filters} />;
        })}

        {/* Load More Intersection Observer */}
        {/* IMPORTANT: The intersection observer can be a dangerous thing to have around */}
        {/* If it's constantly in view of the viewport, it will constantly be making 1000s of calls a minute */}
        {/* To stop this, this intersection observer doesn't make calls by itself, it instead loads rows and the rows make the calls */}
        <div ref={loadMoreTrigger} style={{ height: "10px", width: "100vw" }} />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default SubscriptionFeed;
