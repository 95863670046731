import React, { useEffect, useMemo, useState } from "react";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import ExImg from "../../../../Assets/freshOrange.jpg";
import {
  DiscountConvert,
  SubTypesConvert,
  fixDateFormat,
  fixTimeFormat,
  monthlyConvert,
  priceFormat,
} from "../../../utilities/global_function";
import {
  cancelOrder,
  cancelPass,
  cancelSubscription,
  patchSubTypes,
  redeemItem,
} from "../../../CustomerStateManagement/SubscriptionsRoute";
import ConfirmAppt from "./ConfirmAppt";
import Cancel from "./Cancel";
import { Tooltip } from "react-tooltip";
import PopUp from "../../../../Component/Layer/PopUp";
import { useNavigate } from "react-router-dom";
import {
  colorSchema,
  colorSchemaLight,
} from "../../../../scss/StateSchema/itemColor";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { MdOutlineMessage } from "react-icons/md";
import { getCustomerToken } from "../../../../Functions/Auth/getToken";
import useWindowSize from "../../../../Functions/Hooks/useWindowSize";

const SubscriptionCard = React.memo(({ data, type, historyPage = false }) => {
  const Url = require("../../../../backendURL");
  const navigate = useNavigate();
  const isMobile = useWindowSize();

  console.log(data)
  const [isNextDiscount, setIsNextDiscount] = useState(false);
  // num of done transactions for claim discount
  const [filledCount, setFilledCount] = useState(0);
  // num of transactions needs for claim discount
  const [emptyCount, setEmptyCount] = useState(0);

  // service state manager
  const [apptDate, setApptDate] = useState("");
  const [apptTime, setApptTime] = useState("");

  const [subTypeIndex, setSubtypeIndex] = useState(0);
  const [updateSubTypeIndex, setUpdateSubTypeIndex] = useState(0);

  // item action Manger
  const [cancel, setCancel] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [cancelItem, setCancelItem] = useState(false);
  // [recurringId,type]
  const [cancelInfo, setCancelInfo] = useState([null, null, null]);
  const [use, setUse] = useState(false);
  const [useInfo, setUseInfo] = useState({});
  // state for userid ammon
  const [userId, setUserId] = useState(null);
  const [progress, setProgress] = useState(false);

  const [unitUsed, setUnitUsed] = useState(data?.recurring?.unitUsed || 0);

  /////// NEW /////////
  const [subTypeData, setSubTypeData] = useState({})

  useEffect(() => {
    // Discount Handler
    if (data?.item?.subTypes[subTypeIndex]?.couponInterval != 0) {
      const transactionCalculate = () => {
        const numOfTransactionDoneForDiscount =
          data?.recurring?.timesPurchased %
          data?.item?.subTypes[subTypeIndex]?.couponInterval;
        setFilledCount(numOfTransactionDoneForDiscount);
        setEmptyCount(
          data?.item?.subTypes[subTypeIndex]?.couponInterval -
            numOfTransactionDoneForDiscount,
        );
        if (numOfTransactionDoneForDiscount === 0) {
          setIsNextDiscount(true);
        } else {
          setIsNextDiscount(false);
        }
      };
      transactionCalculate();
    }
    // Appointment Handler
    if (type == "service") {
      const dateString = data?.appointment?.dateTime;
      const [appointDate, appointTime] = dateString.split(", ");
      setApptDate(appointDate);
      setApptTime(appointTime);
    }
    // General functions
    getSubTypeIndex();
  }, [data, type]);

  // ammon useEffect runs only once when component mounts
  useEffect(() => {
    // grabbing the user id for the addChatBox function
    const userIdd = localStorage.getItem("userId");
    setUserId(userIdd);
  }, []);

  const getSubTypeIndex = () => {
    // find subtype index from item object
    const subscribingIndex = data?.item?.subTypes.findIndex(
      (subType) => subType?._id === data?.recurring?.subTypeID,
    );
    setSubtypeIndex(subscribingIndex);
    setUpdateSubTypeIndex(subscribingIndex);
    setSubTypeData(data?.item?.subTypes[subscribingIndex])
  };

  const renderFilledCircles = () => {
    // discount transaction timing - fill
    const circles = [];
    for (let i = 0; i < filledCount; i++) {
      circles.push(
        <BsCircleFill key={i} size="12px" color={colorSchema[data?.type]} />,
      );
    }
    return circles;
  };

  const renderEmptyCircles = () => {
    // discount transaction timing - empty
    const circles = [];
    for (let i = 0; i < emptyCount; i++) {
      circles.push(
        <BsCircle size={"12px"} key={i} color={colorSchema[data?.type]} />,
      );
    }
    return circles;
  };

  const deleteItem = async (id, type) => {
    // cancel item it will be patch as cancel not delete
    if (type == "service") {
      await await cancelSubscription(id);
    }
    if (type == "product") {
      return await cancelOrder(id);
    }
    if (type == "membership") {
      return await cancelPass(id);
    } else {
    }

    // Redirect to Google Forms Cancellation survey
    // window.location.href = "https://forms.gle/ftpLDseSCSnXcFk58";
    return;
  };

  const Stamp = () => {
    // membership pick up stamp generator
    return (
      <div className="subscriptionCard__usePickUp--stamps" key={data?._id}>
        {Array.from(
          { length: (data?.recurring?.quantity || 1) * subTypeData?.unit },
          (_, index) => (
            <div
              key={index}
              className={
                index < unitUsed
                  ? "subscriptionCard__usePickUp--stampFilled"
                  : "subscriptionCard__usePickUp--stampUnfilled"
              }
            >
              {index < unitUsed ? <p>{"\u2713"}</p> : <p></p>}
            </div>
          ),
        )}
      </div>
    );
  };

  const redeemPickUp = async (passId, unit, used) => {
    // use the pick up ticket from user side, now it is not used
    setProgress(true);
    if ((data?.recurring?.quantity || 1) * subTypeData?.unit > unitUsed) {
      try {
        const res = await redeemItem(passId);
        if (res === 200) {
          toast.success("Thank you for your pick up!");
          setUnitUsed(unitUsed + 1);
        }
        setProgress(false);
      } catch (err) {
        console.log(err);
        setProgress(false);
      }
    } else {
      toast.error("You already redeemed all units");
      setProgress(false);
    }
  };

  // function will create new chatbox for user when they click message link if one isn't already created in the DB
  // Ammon, Stephen
  function addChatBox(event) {
    event.preventDefault();
    const subId = event.currentTarget.dataset.subid;
    const bizId = event.currentTarget.dataset.bizid;
    const userIddd = userId;
    (async function ooozeefect() {
      const token = await getCustomerToken("/mySubscriptions");
      async function chatAlreadyExists(userIddd, subId) {
        const res = await fetch(
          `${Url}chatbox/get-chatboxes/buyer/${userIddd}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        let chatExistsFlag = false;
        const UserChats = await res.json();
        UserChats.forEach((chat) => {
          if (subId === chat.subscription_id) {
            chatExistsFlag = true;
          }
        });
        return chatExistsFlag;
      }
      if (subId !== null && bizId !== null && userIddd != null) {
        const chatExists = await chatAlreadyExists(userIddd, subId);
        if (!chatExists) {
          const res = await fetch(`${Url}chatbox/new-chatbox/buyer`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              subscription_id: subId,
              business_id: bizId,
              buyer_id: userIddd,
              message_log: [],
              last_message_sent: Date.now(),
              membership: data.recurring.membership,
            }),
          });
        }
      }
      navigate("/chatbox", { replace: true });
    })();
  }

  const updateSubTypes = async () => {
    setProgress(true);
    const res = await patchSubTypes(
      type,
      data?.recurring?._id,
      data?.item?.subTypes[updateSubTypeIndex]?._id,
    );
    if (res) {
      toast.success("Successfully update your item plan");
      setProgress(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setProgress(false);
      toast.error("Couldn't change the plan");
    }
  };

  ///////////// NEW AOiI will fucking refactor LATER ////
  const discountPercentage = useMemo(() => {
    if (!data?.item?.volumetricDiscounts || !subTypeData) {
      return 0
    };
    const discount = data?.item?.volumetricDiscounts
      .filter(vol => data?.recurring?.quantity * subTypeData?.unit  >= vol.quantity)
      .sort((a, b) => b.quantity - a.quantity)[0];
    return discount?.percentage || 0;
  }, [data,subTypeData]);

  const price = useMemo(() => {
    if (!subTypeData) {
      return 0
    };
    const price = subTypeData?.minCount ? 
    subTypeData?.price * (100 - discountPercentage )/100 * data?.recurring?.quantity
    :
    subTypeData.price
    return price || 0;
  }, [data,subTypeData]);

  const discountPrice = useMemo(() => {
    if (!subTypeData || subTypeData?.couponInterval === 0) {
      return 0
    };
    const discountPrice = price * subTypeData?.couponPercent /100
    return discountPrice || 0;
  }, [data,subTypeData]);

  return (
    <article key={data?.recurring?._id} className="subscriptionCard__card">
      <div className="subscriptionCard__imgContainer">
        <img
          src={`${Url}images/${data?.item?.images[0]}`}
          alt="subscription item Image"
        />
      </div>
      <div className="subscriptionCard__infoContainer">
        <h6
          onClick={() =>
            navigate(
              `/productDetail/${data?.item?.itemType}/${data?.item?.business}/${data?.item?._id}`,
            )
          }
          className="subscriptionCard__title"
        >
          {data?.item?.name}
        </h6>
        <p
          className="subscriptionCard__itemType"
          style={{ color: colorSchema[type] }}
        >
          {type}
          {type === "service" || type === "membership"
            ? ` - ${data?.item?.[`${type}Type`]}`
            : ""}
        </p>
        <h5 className="subscriptionCard__price">
          $
          {isNextDiscount == true
            ? priceFormat(price - discountPrice)
            : priceFormat(price)}
          <span className="subscriptionCard__subType">
            {" "}
            per{" "}
            {SubTypesConvert(
              data?.item?.subTypes[subTypeIndex]?.interval_count,
              data?.item?.subTypes[subTypeIndex]?.interval,
            )}
          </span>
        </h5>
        {isNextDiscount && (
          <p className="subscriptionCard__discountRatio">
            Was{" "}
            <span>
              ${priceFormat(data?.item?.subTypes[subTypeIndex]?.price)}{" "}
            </span>{" "}
            Save $
            {priceFormat(
              data?.item?.subTypes[subTypeIndex]?.price *
                (data?.item?.subTypes[subTypeIndex]?.couponPercent / 100),
            )}{" "}
            ({data?.item?.subTypes[subTypeIndex]?.couponPercent}%)
          </p>
        )}

        {data?.item?.subTypes[subTypeIndex]?.couponInterval > 0 && (
          <>
            <p className="subscriptionCard__discountInterval">
              Applies {DiscountConvert(data?.couponInterval)}
            </p>
            <div className="subscriptionCard__discount">
              <div className="subscriptionCard__discount--circles">
                {}
                {renderFilledCircles()}
                {renderEmptyCircles()}
              </div>
              {!isNextDiscount && (
                <p className="subscriptionCard__discountRatio">
                  $
                  {priceFormat(
                    data?.item?.subTypes[subTypeIndex]?.price *
                      (1 -
                        data?.item?.subTypes[subTypeIndex]?.couponPercent /
                          100),
                  )}{" "}
                  Save $
                  {priceFormat(
                    data?.item?.subTypes[subTypeIndex]?.price *
                      (data?.item?.subTypes[subTypeIndex]?.couponPercent / 100),
                  )}{" "}
                  ({data?.item?.subTypes[subTypeIndex]?.couponPercent}%)
                </p>
              )}
            </div>
          </>
        )}

        {/* If this is called in History page or subscription page */}
        {historyPage ? (
          <>
            <div className="l-flex__column--start">
              <div className="l-flex__row--between" style={{ width: "50%" }}>
                <p>Time Purchased:</p>
                <p>{data?.recurring?.timesPurchased}</p>
              </div>
              <div className="l-flex__row--between" style={{ width: "50%" }}>
                <p>Saved Amount:</p>
                <p>${priceFormat(data?.recurring?.discountedAmount)}</p>
              </div>
              <div className="l-flex__row--between" style={{ width: "50%" }}>
                <p>Purchase Date:</p>
                <p>{priceFormat(data?.recurring?.discountedAmount)}</p>
              </div>
            </div>
          </>
        ) : (
          <>
            {type == "service" && (
              <div className="subscriptionCard__uniqueInfo--container">
                <div className="subscriptionCard__uniqueInfo--dateInfo">
                  <div className="subscriptionCard__uniqueInfo--dateWrapper">
                    <p className="subscriptionCard__uniqueInfo--dateLabel">
                      Next Appointment
                    </p>
                    <p className="subscriptionCard__uniqueInfo--date">
                      {fixDateFormat(apptDate)}, {fixTimeFormat(apptTime)}
                    </p>
                  </div>
                  <div className="subscriptionCard__uniqueInfo--dateWrapper">
                    <p className="subscriptionCard__uniqueInfo--dateLabel">
                      Next Billing Date
                    </p>
                    <p className="subscriptionCard__uniqueInfo--date">
                      {fixDateFormat(data?.recurring?.renewalDate)}
                    </p>
                  </div>
                </div>
                <div className="subscriptionCard__buttons">
                  {data?.recurring?.canSetAppt ? (
                    <button
                      className="subscriptionCard__button--black"
                      onClick={() => setConfirm(true)}
                    >
                      Schedule
                    </button>
                  ) : (
                    <>
                      <button
                        className="subscriptionCard__button--black"
                        style={{ backgroundColor: "#979797" }}
                        id="confirmAlert"
                      >
                        Confirm
                      </button>
                      <Tooltip anchorSelect="#confirmAlert">
                        You can confirm next appointment <br /> only after your
                        invoice is paid on Stripe
                      </Tooltip>
                    </>
                  )}
                  <button
                    className="subscriptionCard__button--white"
                    onClick={() => {
                      setCancelInfo([
                        data?.recurring?._id,
                        type,
                        data?.item?.name,
                      ]);
                      setCancelItem(true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {type == "product" && (
              <div className="subscriptionCard__uniqueInfoContainer">
                <div className="subscriptionCard__uniqueInfo--dateInfo">
                  <div className="subscriptionCard__uniqueInfo--dateWrapper">
                    <p className="subscriptionCard__uniqueInfo--dateLabel">
                      Next Shipping Date
                    </p>
                    <p className="subscriptionCard__uniqueInfo--date">
                      ShippingDate here
                    </p>
                  </div>
                  <div className="subscriptionCard__uniqueInfo--dateWrapper">
                    <p className="subscriptionCard__uniqueInfo--dateLabel">
                      Next Billing Date
                    </p>
                    <p className="subscriptionCard__uniqueInfo--date">
                      {fixDateFormat(data?.recurring?.renewalDate)}
                    </p>
                  </div>
                </div>
                <div className="subscriptionCard__buttons">
                  <button className="subscriptionCard__button--black">
                    Edit
                  </button>
                  <button
                    className="subscriptionCard__button--white"
                    onClick={() => {
                      setCancelInfo([
                        data?.recurring?._id,
                        type,
                        data?.item?.name,
                      ]);
                      setCancelItem(true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {type == "membership" && (
              <div className="subscriptionCard__uniqueInfoContainer">
                <div className="subscriptionCard__uniqueInfo--dateInfo">
                  <div className="subscriptionCard__uniqueInfo--dateWrapper">
                    <p className="subscriptionCard__uniqueInfo--dateLabel">
                      Next Billing Date
                    </p>
                    <p
                      className="subscriptionCard__uniqueInfo--date"
                      style={
                        data?.recurring?.cancelledDate
                          ? { textDecorationLine: "line-through" }
                          : {}
                      }
                    >
                      {fixDateFormat(data?.recurring?.renewalDate)}
                    </p>
                    {data?.recurring?.cancelledDate && (
                      <p style={{ color: "#EF4444" }}>
                        will be canceled at renewal date
                      </p>
                    )}
                  </div>
                </div>
                <div className="subscriptionCard__buttons">
                  <button
                    className="subscriptionCard__button--white"
                    onClick={() => {
                      setCancelInfo([data?.recurring?._id, type, data?.item?.name]);
                    setOpenEdit(false);
                    setCancelItem(true);
                    }}
                  >
                    Cancel
                  </button>
                  {data?.item?.membershipType == "Pick Up" && (
                    <button
                      className="subscriptionCard__button--white"
                      onClick={() => {
                        setUseInfo(data);
                        setUse(true);
                      }}
                    >
                      Use
                    </button>
                  )}

                  {/* // Stephen: Added link to chatbox */}
                  <Link
                    onClick={addChatBox}
                    data-subid={data?.recurring?._id}
                    data-bizid={data?.item?.business}
                    to={`/chatbox`}
                    type="button"
                  >
                    <MdOutlineMessage className="messageIcon" />
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {
        // Confirm Next Appointment
        confirm && (
          <ConfirmAppt
            nextAppointment={data?.appointment?.nextAppointment}
            apptId={data?.appointment?._id}
            serviceId={data?.recurring?.service}
            businessId={data?.appointment?.business}
            subTypeId={data?.recurring?.subTypeID}
            subTypeIndex={subTypeIndex}
            setConfirm={setConfirm}
          />
        )
      }

      {/* {
          // Cancel Appointment Option
          cancel &&
          <Cancel 
          apptId={data?.appointment?._id}
          setCancel={setCancel}/>
      } */}

      {
        // Cancel Subscription
        cancelItem && (
          <PopUp setOpen={setCancelItem}>
            <div className="subscriptionCard__cancelItem--container">
              <h6>Are you sure you want to cancel this subscription?</h6>
              <h4 className="subscriptionCard__cancelItem--name">
                {cancelInfo[2]}
              </h4>
              <div className="c-smallButtons">
                <button
                  onClick={() => deleteItem(cancelInfo[0], cancelInfo[1])}
                  className="c-smallButton"
                >
                  Cancel
                </button>
              </div>
            </div>
          </PopUp>
        )
      }

      {
        // Display Uses
        use && (
          <PopUp style={{ backgroundColor: "black" }} setOpen={setUse}>
            <div className="subscriptionCard__usePickUp--card">
              <div className="subscriptionCard__usePickUp--info">
                <div className="subscriptionCard__usePickUp--companyImg">
                  <img
                    src={`${Url}images/${data?.item.avatarImg}`}
                    alt="subscription company Image"
                  />
                </div>
                <div className="subscriptionCard__usePickUp--itemInfo">
                  <h5>{useInfo?.item?.name}</h5>
                  <p>
                    {SubTypesConvert(
                      useInfo?.item.subTypes[subTypeIndex]?.interval_count,
                      useInfo?.item.subTypes[subTypeIndex]?.interval,
                    )}
                  </p>
                  <p>{useInfo?.item?.businessName}</p>
                </div>
              </div>
              <div className="subscriptionCard__usePickUp--activity">
                <h5>
                  {useInfo?.recurring?.unitUsed} /{" "}
                  {(data?.recurring?.quantity || 1)* subTypeData?.unit}
                </h5>
                <p>
                  Expired Date: {fixDateFormat(useInfo?.recurring?.renewalDate)}
                </p>
              </div>

              <div className="subscriptionCard__usePickUp--stampsContainer">
                <Stamp />
                <label>
                  This is to track your uses for your subscription. If asked by
                  a store employee, show them this card to verify your
                  subscription to them.
                </label>
              </div>
              <button
                className="subscriptionCard__usePickUp--redeemBtn"
                onClick={() =>
                  redeemPickUp(
                    data?.recurring?._id,
                    useInfo?.item?.subTypes[subTypeIndex]?.unit,
                    unitUsed,
                  )
                }
              >
                {progress ? "Processing" : "Pick Up This Item Now"}
              </button>
            </div>
          </PopUp>
        )
      }

      {openEdit && (
        <PopUp
          setOpen={setOpenEdit}
          cStyle={{ width: isMobile ? "80%" : "50%" }}
        >
          <section className="subscriptionCard__edit">
            <h6 style={{ marginBottom: 16 }}>
              Change Your Subscription Preferences
            </h6>
            <p style={{ lineHeight: 1.1, fontSize: 14, marginBottom: 8 }}>
              You can modify your subscription preferences here. <br />
              <b>Note</b> Changing your subscription will apply immediately.
              This will clear any unclaimed uses you may have for your
              subscription. If your subscription is in the same interval then
              the changes will not apply immediately.
            </p>
            {/* <div className="subscriptionCard__edit--container">
              {data?.item?.subTypes?.map((subType, index) => (
                <div
                  className="c-variation__box subscriptionCard__edit--box"
                  style={
                    updateSubTypeIndex == index
                      ? { border: "solid #e5704c" }
                      : {}
                  }
                  onClick={() => setUpdateSubTypeIndex(index)}
                  key={index}
                >
                  <h6 className="c-variation__option">
                    {subType?.interval_count} {subType?.interval}
                    {subType?.interval_count !== 1 && "s"}
                  </h6>
                  <div className="c-variation__detail">
                    <p className="c-variation__price">
                      $
                      {data?.itemType == "product"
                        ? (
                            priceFormat(subType.price) - data?.shippingCost
                          ).toFixed(2)
                        : priceFormat(subType.price)}
                    </p>
                    <label>
                      ${" "}
                      {priceFormat(
                        subType.price /
                          monthlyConvert(
                            subType?.interval_count,
                            subType?.interval,
                          ),
                      )}{" "}
                      / mo
                    </label>
                    {type === "membership" &&
                    data?.item?.membershipType === "Pick Up" ? (
                      <>
                        {use}
                        <p>
                          {subType?.unit} unit{subType?.unit !== 1 ? "s" : ""}
                        </p>
                        <label>
                          $ {priceFormat(subType?.price / subType?.unit)} / unit
                        </label>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="c-variation__discount">
                      <p>{DiscountConvert(subType?.couponInterval)}</p>
                      <label>{subType?.couponPercent}% OFF</label>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div
              className="c-smallButtons"
              style={{ width: "fit-content", marginLeft: "auto" }}
            >
              <button
                className="c-smallButton--black"
                onClick={() => {
                  setCancelInfo([data?.recurring?._id, type, data?.item?.name]);
                  setOpenEdit(false);
                  setCancelItem(true);
                }}
              >
                Cancel Subscription
              </button>
              <button
                className="c-smallButton"
                disabled={progress}
                onClick={() => updateSubTypes()}
              >
                {progress ? "Progress" : "Update"}
              </button>
            </div>
          </section>
        </PopUp>
      )}
    </article>
  );
});

export default SubscriptionCard;
