import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FaUser, FaUserPlus } from "react-icons/fa";
import Avatar from "../Pages/AccountDetail/Components/AvatarImage";
import { customerSignOut } from "../CustomerStateManagement/CustomerState";

import { AccountContext } from "../Routes/CustomerRoutes";

const AccountDropdown = ({ isLoggedIn }) => {
  const [dropdown, showDropdown] = useState(false);
  const [userInfo] = useContext(AccountContext);

  const navigate = useNavigate();
  const handleNavClick = (route) => {
    navigate(`/${route}`);
  };

  const dropdownRef = useRef(null);

  const dropdownToggle = () => {
    showDropdown((prevState) => !prevState);
  };

  const closeDropdown = () => {
    showDropdown(false);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const DropdownMenu = () => {
    return (
      <div className="l-dropdown" ref={dropdownRef}>
        <div className="l-dropdown__columns">
          {/* Left Column */}
          <div className="l-dropdown__column l-dropdown__left">
            <div onClick={closeDropdown} className="l-dropdown__left__avatar">
              <Avatar avatarImg={userInfo?.accountDetail?.avatarImg} />
              <h6>
                {userInfo?.firstName} {userInfo?.lastName}{" "}
              </h6>
              <p>{userInfo?.email}</p>
            </div>
          </div>

          {/* Right Column */}
          <div className="l-dropdown__column l-dropdown__right">
            <Link to={"/user"} onClick={closeDropdown}>
              Account
            </Link>
            <Link to={"/chatbox"} onClick={closeDropdown}>
              Messages
            </Link>
            <Link to={"/wishlist"} onClick={closeDropdown}>
              Wishlist
            </Link>
            <Link to={"/mySubscriptions"} onClick={closeDropdown}>
              Past Subscriptions
            </Link>
            <Link to={"/wishlist"} onClick={closeDropdown}>
              Wishlist
            </Link>
            <Link to={"/businessportal/dashboard"} onClick={closeDropdown}>
              Become a seller
            </Link>
            <Link>
              <div onClick={customerSignOut}>Sign Out</div>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  // Account, Past Subscriptions, Recently Viewed, Settings, Sign Out
  // Avatar Image, Sell with us

  return (
    <>
      {isLoggedIn ? (
        <div className="customerNavIcon">
          <FaUser size={20} onClick={dropdownToggle} />
          {/* <li className="" onClick={customerSignOut}>
          Sign Out
        </li> */}
        </div>
      ) : (
        <div id="nav-link-notLoggedIn">
          {/* <FaUserPlus
            size={20}
            className="customerNavIcon"
            onClick={() => handleNavClick("login")}
          /> */}

          <div onClick={() => handleNavClick("login")} className="c-signIn">
            Sign In
          </div>
        </div>
      )}

      {dropdown && <DropdownMenu />}
    </>
  );
};

export default AccountDropdown;
