/*
============================================================
Initial Information
==========
File Content: --This page allow user to choose there ideal appointment time only for service. They choose ideal date and then display available time slot for them. After they choose that and click subscribe button, it creates appointment Object first and create checkout session url to guide them to Stripe to pay. 

Author: Aoi Kuriki
File Created Date: 2023/4/11
===================================================
Revision History
==========
Author: Aoi, Kuriki
Revised Date: 2023/7/19
Revised Points: In mobile display, I detect when user change selected date, it does not change correctly. If there is already available spot in today, it will display spot, so the reason is on useState change and it cause error only on IOS. SO for this solution,
==========
Revision 2 
Author: Aoi, Kuriki
Revised Date: 2023/8/22
Revised Points: Refactor API call and add case for first purchase and reschedule
===================================================
Make sure each function and return also have specific comment to explain, especially To Do, Problem, State, Relationship with other files.
============================================================
*/
import axios from "axios";
// import "../../BusinessPortal/SideNavPages/Calendar/Style/Calendar.css";
// import "../../BusinessPortal/SideNavPages/Availability/Style/Availability.css";
import React, { useEffect, useState, createContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../../GlobalComponents";
import { DateContextAvailability } from "../../../Business_Module/Pages/AvailabilityPage/Component/AvailabilityPage";
import SmallCalendar from "../../../pages/BusinessPortal/SideNavPages/SmallCalendar";
import "./Styles/TimeAvailability.css";
import toast, { Toaster } from "react-hot-toast";
import { createAppt } from "../../CustomerStateManagement/CustomerState";
import { navigateLink } from "../../utilities/global_function";
import {
  refreshTA,
  rescheduleTA,
} from "../../CustomerStateManagement/SubscriptionsRoute";
import {
  checkoutService,
  getServiceById,
} from "../../CustomerStateManagement/Item";

const TimeAvailability = ({}) => {
  const Url = require("../../../backendURL");
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceId, businessId, subTypeId, index, isFirst, apptId } =
    location.state;
  let date1 = new Date();

  const [d, setD] = useState(date1);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [service, setService] = useState([]);
  const [spots, setSpots] = useState([]);
  const [times, setTimes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [spotData, setSpotData] = useState({});
  const spotDataRef = useRef(spotData);
  const [processing, setProcessing] = useState(false);

  // When this page is opened, get all of TA spots including take and reserved

  useEffect(() => {
    refreshTA(serviceId, subTypeId);
    const getServiceInfo = async () => {
      const res = await getServiceById(serviceId);
      console.log(res);
      setService(res);
      setSpots(res.subTypes[index].appointmentSlots);
    };
    getServiceInfo();
  }, []);

  const navigateToCheckoutPreview = () => {
    navigate("/checkoutPreview", {
      state: {
        itemObject: service,
        type: service.itemType,
        appt: `${dateSelected.toLocaleDateString("en-US")}, ${selectedSpot}`,
        subTypeId: subTypeId,
        subTypeIndex: index,
      },
    });
  };

  const submitReschedule = async () => {
    try {
      await rescheduleTA(
        apptId,
        `${dateSelected.toLocaleDateString("en-US")}, ${selectedSpot}`,
      );
      toast.success("Your appointment is correctly rescheduled");
      setTimeout(navigateLink("mySubscriptions", navigate), 4000);
    } catch (err) {
      toast.err(err);
    }
  };

  // This functions below is to reformat available spots
  // ⚠️ Since this function is using useRef.current, while working on this code, available time spots will keep duplicated, but it will not happen on user experience side.
  useEffect(() => {
    fetchData();
  }, [spots]);

  const fetchData = async () => {
    if (spots.length !== 0) {
      // filtering spot information vy if the value is "open"
      let spotKeys = Object.keys(spots).filter((key) => spots[key] === "Open");

      // For loop is separating the key to Date and Time by ",". andstore in another object
      for (let i = 0; i < spotKeys.length; i++) {
        await makeSpotsObject(spotKeys[i]);
      }
      setSpotData(spotDataRef.current);
      setLoaded(true);
      setTimes(spotKeys);
    } else {
      return;
    }
  };

  const makeSpotsObject = async (spot) => {
    const [Date, Time] = spot.split(", ");
    let newTime = Time.replace(":00 ", " ");
    // if the date is already in the object push time to the end of array
    if (Date in spotDataRef.current) {
      spotDataRef.current = {
        ...spotDataRef.current,
        [Date]: [...spotDataRef.current[Date], newTime],
      };
    }
    // if the date is new in the object create time to the array
    else {
      spotDataRef.current = {
        ...spotDataRef.current,
        [Date]: [newTime],
      };
    }
  };

  if (!loaded) {
    return <Loading />;
  }
  return (
    // It should be submit button and onSubmit will send them to the checkout session after creating appointment object in the same function and async.

    <div className="TAContainer">
      <div className="TAServiceDisplay">
        <h2>You have a service that needs {isFirst ? "" : "Re"}scheduling</h2>

        {/* This is service info display and when we allow user to multi-subscription in the future, use map function to display but now just one box */}

        <div className="TAServiceInfoCard">
          <p>
            {service?.name !== undefined ? service?.name : service?.serviceName}
          </p>
          <p>
            {service?.subTypes[index].interval_count +
              " " +
              service?.subTypes[index].interval}
          </p>
        </div>
      </div>
      <div className="TAcalendarDisplay">
        <div className="Calendar">
          <DateContextAvailability.Provider value={{ d, setD }}>
            <SmallCalendar
              date={d}
              setDateSelected={setDateSelected}
              spotData={spotData}
            ></SmallCalendar>
          </DateContextAvailability.Provider>
        </div>

        <div className="TATimeDisplay">
          <p className="TAselectedDate">
            <span>
              Selected Date: {`${dateSelected.toLocaleDateString("en-US")}`}
            </span>
          </p>
          <div className="spotsDisplay">
            {!spotData.hasOwnProperty(
              dateSelected.toLocaleDateString("en-US").toString(),
            ) ? (
              <p>Currently there is no Available spot at this date</p>
            ) : (
              spotData[dateSelected.toLocaleDateString("en-US").toString()].map(
                (s, index) => (
                  <button
                    key={index}
                    value={s}
                    className={selectedSpot === s ? "selectedButton" : ""}
                    onClick={() => {
                      setSelectedSpot(s);
                    }}
                  >
                    {s}
                  </button>
                ),
              )
            )}
          </div>
        </div>
        {processing ? (
          <button style={{ backgroundColor: "gray" }} disabled>
            processing...
          </button>
        ) : (
          <button
            onClick={isFirst ? navigateToCheckoutPreview : submitReschedule}
            disabled={selectedSpot === null}
          >
            {isFirst ? "Proceed Checkout" : "Proceed Reschedule"}
          </button>
        )}
      </div>
      <Toaster />
    </div>
  );
};

export default TimeAvailability;
