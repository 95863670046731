import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { calculateDistance } from "../../../../../Functions/Formulas/calculateDistance";
import { AccountContext } from "../../../../Routes/CustomerRoutes";

import { FaShop, FaStar } from "react-icons/fa6";
import subittBanner from "../../../../../Assets/subittBanner.jpg";
import BlackPin from "../../../../../Assets/Icons/location_pin_black.svg";

const Url = require("../../../../../backendURL");

const BusinessCard = ({ business }) => {
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [location, setLocation] = useState(
    userInfo?.address?.geometry?.coordinates,
  );
  const [distanceToUser, setDistanceToUser] = useState();

  useEffect(() => {
    if (
      business?.location?.geometry?.coordinates &&
      userInfo?.location?.geometry?.coordinates
    ) {
      let distance = calculateDistance(
        business.location.geometry.coordinates,
        location,
      ).toFixed(1);
      setDistanceToUser(distance);
    } else {
      setDistanceToUser();
    }
  }, []);

  return (
    <Link className="c-businessCard" to={`/customerStoreFront/${business?.id}`}>
      <div className="c-businessCard__top">
        {/* <div className="c-businessCard__logo">
          <img
            src={`${Url}images/${business.avatar}`}
            alt={`${business.businessName} logo`}
          />
        </div> */}

        <div className="c-businessCard__banner">
          <img
            src={`${Url}images/${business?.banner}`}
            alt={`${business?.title} logo`}
            onError={(e) => {
              e.target.src = subittBanner;
            }}
          />
        </div>
      </div>

      <div className="c-businessCard__bottom">
        <div className="c-businessCard__info">
          {business?.title && (
            <h2 className="c-businessCard__name">{business?.title}</h2>
          )}
          <div className="c-businessCard__secondRow">
            {business?.averageRating && (
              <>
                <Rating
                  name="rating"
                  readOnly
                  className="rating c-starRating"
                  value={business?.averageRating}
                  precision={0.1}
                  size="large"
                  sx={{ width: "70px", color: "#e5704c" }}
                  icon={<FaStar />}
                  emptyIcon={<FaStar />}
                />
                <p>
                  {business?.averageRating?.toFixed(1)} ({business?.reviewCount}{" "}
                  reviews)
                </p>
              </>
            )}
            {distanceToUser && <h6>{distanceToUser} miles away</h6>}
          </div>

          {business?.categories && (
            <div className="c-businessCard__categories">
              {business?.categories?.slice(0, 5).map((category, index) => (
                <p key={index}>{category}</p>
              ))}
            </div>
          )}

          {business?.description && (
            <div className="c-businessCard__description">
              <FaShop color="#101010" size={20} />
              <p>{business?.description}</p>
            </div>
          )}

          <div className="c-businessCard__address">
            <img src={BlackPin} className="c-businessCard__address__pin" />
            <div className="c-businessCard__address__content">
              {business?.location?.line1 && (
                <div className="c-businessCard__address__line1">
                  {business?.location?.line1}
                  {business?.location?.line2 &&
                    ", " + business?.location?.line2}
                </div>
              )}

              {business?.location?.city && (
                <div className="c-businessCard__address__line2">
                  {business?.location?.city}
                  {business?.location?.state &&
                    ", " + business?.location?.state}
                </div>
              )}
            </div>
          </div>

          {/* <div className="c-businessCard__rating">Rating: {business.rating}</div> */}
        </div>
        <p className="c-businessCard__accepts">
          This location accepts subscriptions.
        </p>
      </div>
    </Link>
  );
};

export default BusinessCard;
