import React, { useEffect, useCallback, useState, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  getBusinessesByCategory,
  getProductsByCategory,
} from "../../../CustomerStateManagement/FeedState";
import SubscriptionCard from "../../Feed/Components/SubscriptionComponents/SubscriptionCard";
import BrowseBusinessCard from "../../../Components/BrowseBusinessCard";
import Filters from "../../../Components/Filters";
import toast, { Toaster } from "react-hot-toast";
import CategoryList from "../../../Components/CategoryList";
import { FeedContext } from "../../../Routes/CustomerRoutes";
import { AccountContext } from "../../../Routes/CustomerRoutes";
import RecommendationForm from "../../../Components/RecommendationForm";
import SEO from "../../../../Component/Atom/SEO";
import Sidebar from "../../../Components/Sidebar";
import { search } from "../../../../icons/business/icons";
import NoResultsPage from "../../../Components/NoResultsPage";
import useUserLocation from "../../../utilities/getLocation";

const CategoryPage = () => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [userInfo, setUserInfo] = useContext(AccountContext);

  const [totalBusResults, setTotalBusResults] = useState(0);
  const [totalSubResults, setTotalSubResults] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilters, setSearchFilters] = useState({ distance: 30 });

  const [category, setCategory] = useState();
  const [subscriptions, setSubscriptions] = useState();
  const [businesses, setBusinesses] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // Pagination for businesses + Subscriptions
  const [businessPage, setBusinessPage] = useState(1);
  const [hasMoreSubs, setHasMoreSubs] = useState(true);
  const [subscriptionPage, setSubscriptionPage] = useState(1);

  const location = useUserLocation();

  // Get category from params
  useEffect(() => {
    const catFomParams = searchParams.get("category");

    if (catFomParams?.length > 1) {
      setCategory(catFomParams);
    } else {
      setSearchParams();
    }
  }, [searchParams]);

  // Function to get subscriptions
  const fetchsubscriptions = useCallback(async () => {
    if (!location?.geometry?.coordinates) return;

    try {
      const res = await getProductsByCategory({
        category: category,
        page: subscriptionPage,
        location: location.geometry.coordinates,
        filters: searchFilters,
      });

      return res;
    } catch (error) {
      toast.error("Unable to fetch subscriptions. Please try again.");
      console.error("Error fetching data:", error);
    }
  }, [location, category, searchFilters, subscriptionPage]);

  // Call new subscriptions when page, category, searchFilters, or subscription page changes
  useEffect(() => {
    if (!location?.geometry?.coordinates) return;

    setSubscriptions([]);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetchsubscriptions();

        setSubscriptions((prevSubs) => [...prevSubs, ...(res?.docs || [])]);
        setTotalSubResults(res?.total);
        setHasMoreSubs(res.hasMore);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [location, category, searchFilters, subscriptionPage]);

  // const fetchBusinesses = useCallback(async () => {
  //   const location = userInfo?.address?.geometry || "";
  //   if (!location) return; // Temporary fix until I add in location if they're not signed in
  //   try {
  //     const res = await getBusinessesByCategory(subcategory, query, location);
  //     setBusinesses(res);
  //   } catch (error) {
  //     toast.error("Unable to fetch Businesses. Please try again.");
  //     console.error("Error fetching data:", error);
  //   }
  // });

  useEffect(() => {
    setSubscriptions([]);
    setSubscriptionPage(1);

    setBusinesses([]);
    setBusinessPage(1);
  }, []);

  // Reset if something's changed
  useEffect(() => {
    setSubscriptions([]);
    setSubscriptionPage(1);

    setBusinesses([]);
    setBusinessPage(1);
  }, [location, category, searchFilters]);

  ////////////////
  //  HANDLERS  //
  ////////////////
  // Handles click to load more businesses button
  const handleBusinessLoadMore = async (e) => {
    setBusinessPage((prevPage) => prevPage + 1);
  };

  // Handles click to load more subscriptions button
  const handleSubscriptionLoadMore = async (e) => {
    setSubscriptionPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="p-category">
      <SEO
        title={`${category?.charAt(0).toUpperCase() + category?.slice(1)}`}
      />
      <Toaster />

      {/* Sidebar: only shows on subscription piece */}
      {feedType && (
        <div
          className="l-cSidebar"
          style={!sidebar ? { width: "0px" } : { width: "200px" }}
        >
          <Sidebar />
        </div>
      )}

      <Filters setParentFilters={setSearchFilters} />

      <div className="p-feed__categories p-category__categories">
        <CategoryList />
      </div>

      <div
        className="p-category__content"
        style={sidebar ? { width: "calc(100vw - 200px)" } : { width: "100vw" }}
      >
        {/* <p>{category} businesses </p>
        <ul className="p-category__businesses">
          {businesses?.map((business, index) => (
            <BrowseBusinessCard business={business} />
          ))}
        </ul> */}

        {subscriptions?.length > 0 ? (
          <>
            <div className="p-category__title">
              <h5>{category} subscriptions</h5>
              <h6>{totalSubResults} results</h6>
            </div>
            <div className="p-category__subscriptions">
              {subscriptions?.map((product, index) => (
                <SubscriptionCard product={product} />
              ))}
            </div>

            {hasMoreSubs && (
              <div onClick={handleSubscriptionLoadMore} className="c-loadMore">
                Load more
              </div>
            )}
          </>
        ) : (
          <NoResultsPage title={category} />
        )}

        {/* Show recommendation form if there isn't that many subscriptions loaded in */}
        {(subscriptions?.length < 6 || businesses?.length < 6) && (
          <div
            className="p-category__recommendation"
            style={
              sidebar ? { width: "calc(100vw - 200px)" } : { width: "100vw" }
            }
          >
            <div className="p-category__recommendation__title">
              <h1>Have a business you want to see on Subitt?</h1>
              <p>
                Recommend a business for a chance to earn a free first month
                when they join.
              </p>
            </div>
            <RecommendationForm showHeader={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
