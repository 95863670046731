
export const DiscountConvert = (discountInterval) => {
  const discountIntervalPicker = {
    0: "No Discount",
    1: "Every Transaction",
    2: "Every Other Transaction",
    3: "Every 3rd Transaction",
    4: "Every 4th Transaction",
    5: "Every 5th Transaction",
    6: "Every 6th Transaction",
    7: "Every 7th Transaction",
    8: "Every 8th Transaction",
    9: "Every 9th Transaction",
    10: "Every 10th Transaction",
  };
  if (discountInterval > 3) {
    return `Every ${discountInterval}th Transaction`;
  } else {
    return discountIntervalPicker[discountInterval];
  }
};