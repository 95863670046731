import React from 'react'
import MotionWrapper from './MotionWrapper'

const PromoVid = () => {
  return (
    <div className='landingPage__promoVid__container'>
        <div className='promoVid__section'>
            <h3 className='promoVid_title'>Who are we?</h3>
            <iframe 
            src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7219134565213184001?compact=1" 
            height="399" 
            width="710" 
            frameborder="0" 
            allowfullscreen="" 
            title="Embedded post"
            className='promoVid'
            >
            </iframe>
        </div>
    </div>
  )
}

export default PromoVid
