import React from "react";
import { useState, useEffect, useContext } from "react";
import { ChatboxContext } from "./ChatboxContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { MdOutlineMessage } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { getDateForConvo } from "./Date";
import {
  getCustomerToken,
  getBusinessToken,
} from "../../Functions/Auth/getToken";

const Conversations = () => {
  const Url = require("../../backendURL");

  const {
    chatOption,
    setChatOption,
    chatOptions,
    setChatOptions,
    userId,
    setUserId,
    isBusinessSide,
    setIsBusinessSide,
    onConvos,
    setOnConvos,
    isMobile,
    setIsMobile,
  } = useContext(ChatboxContext);

  const [isInBusiness, setIsInBusiness] = useState();

  const [assignment, setAssignment] = useState(false);

  const [notifs, setNotifs] = useState();

  const [queryResults, setQueryResults] = useState([]);

  const location = useLocation();

  const [counter, setCounter] = useState(0);

  // For conditionally setting behavior for styles based upon the size of the screen
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 480);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // For checking if the user is on the buyer or the seller side
  useEffect(() => {
    setUserId();
    const locationChecker = () => {
      setIsInBusiness(location.pathname.includes("businessportal"));
      setAssignment(true);
    };

    locationChecker();
  }, [location.pathname]);

  // Based upon if the user is a buyer or a seller, get their id from local storage
  useEffect(() => {
    const idAssignment = () => {
      if (isInBusiness) {
        setUserId(localStorage.getItem("businessId"));
        setIsInBusiness(true);
        setIsBusinessSide(true);
      } else {
        setUserId(localStorage.getItem("userId"));
        setIsInBusiness(false);
      }
    };

    if (assignment) {
      idAssignment();
    }
  }, [assignment]);

  // Fetch user's conversations based upon their id
  const fetchConversations = async () => {
    if (isInBusiness) {
      //Local state from past hooks yes
      const token = await getBusinessToken();
      const conversationsData = await axios.get(
        `${Url}chatbox/get-chatboxes/seller/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setChatOptions(conversationsData.data);
    } else {
      const token = await getCustomerToken("/chatbox");
      const conversationsData = await axios.get(
        `${Url}chatbox/get-chatboxes/buyer/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setChatOptions(conversationsData.data);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchConversations();
    }
  }, [userId]);

  // When chatOptions exists, setQueryResults to chatOptions
  useEffect(() => {
    if (chatOptions) {
      setQueryResults(chatOptions);
    }
  }, [chatOptions]);

  // Fetch unseen messages and store them into state. This is auto-refresh
  const fetchUnseenMessages = async () => {
    setTimeout(() => {
      if (userId) {
        setCounter(counter + 1);
      }
    }, 10000);
    if (isInBusiness) {
      const token = await getBusinessToken();
      const notifData = await axios.get(
        `${Url}chatbox/get-notifs/seller/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setNotifs(notifData.data);
    } else {
      const token = await getCustomerToken("/chatbox");
      const notifData = await axios.get(
        `${Url}chatbox/get-notifs/buyer/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setNotifs(notifData.data);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUnseenMessages();
    }
  }, [counter, userId]);

  // setTimeout(() => {
  //   if (userId) {
  //     fetchUnseenMessages();
  //   }
  // }, 10000);

  // When a conversation is clicked, store the convo in state and setOnConvos to false. This is for mobile view
  // to switch between conversations and messages for that conversation
  function chatClicked(convo) {
    setChatOption(convo);
    setOnConvos(false);
  }

  const navigate = useNavigate();

  // For redirecting back to see subscribers or subscriptions
  function handleNavigation(e) {
    e.preventDefault();
    if (isInBusiness) {
      navigate("/businessportal/MySubscribers", { replace: true });
    } else {
      navigate("/mySubscriptions", { replace: true });
    }
  }

  // When a user types in a query for specific conversations, resets what queryResults equals.
  function handleQuery(e) {
    e.preventDefault();
    const filteredOptions = chatOptions.filter((option) =>
      option.subscription_name
        .toLowerCase()
        .includes(e.target.elements.query.value.toLowerCase()),
    );
    setQueryResults(filteredOptions);
  }

  return (
    <main
      className={
        !onConvos && isMobile
          ? "p-chatbox__conversations--hide"
          : "p-chatbox__conversations"
      }
    >
      {chatOptions ? (
        <h4
          className={
            isInBusiness
              ? "p-chatbox__conversations__title--seller"
              : "p-chatbox__conversations__title--buyer"
          }
        >
          Conversations
        </h4>
      ) : (
        ""
      )}
      <div className="p-chatbox__conversations__search">
        <form
          className="p-chatbox__conversations__search__form"
          onSubmit={handleQuery}
        >
          <input
            className="p-chatbox__conversations__search__input"
            type="text"
            placeholder="Search Conversations"
            name="query"
          />
          <button
            className="p-chatbox__conversations__search__button"
            type="submit"
          >
            <IoIosSearch />
          </button>
        </form>
      </div>
      <div className="p-chatbox__conversations__convoContainer">
        {chatOptions && chatOptions.length > 0 ? (
          <ul>
            {queryResults.map((convo) => {
              return notifs &&
                notifs.some((notif) => notif.chatbox_id === convo._id) ? (
                <li
                  onClick={() => chatClicked(convo)}
                  className={
                    chatOption && isInBusiness && convo._id === chatOption._id
                      ? "p-chatbox__conversations__convoContainer__conversation--sellerActive"
                      : chatOption && convo._id === chatOption._id
                      ? "p-chatbox__conversations__convoContainer__conversation--buyerActive"
                      : "p-chatbox__conversations__convoContainer__conversation"
                  }
                  key={convo._id}
                >
                  <div className="p-chatbox__conversations__convoContainer__grid">
                    <div className="p-chatbox__conversations__convoContainer__grid__messageIcon">
                      <MdOutlineMessage className="messageIconForBusinessSide" />
                    </div>
                    <div className="p-chatbox__conversations__convoContainer__grid__conversationData">
                      <p className="p-chatbox__conversations__convoContainer__conversation--notification">
                        {convo.subscription_name}
                      </p>
                      <p className="p-chatbox__conversations__convoContainer__conversation__partyName--notification">
                        {userId === convo.business_id
                          ? convo.buyer_name
                          : convo.business_name}
                      </p>
                      {convo.message_log[convo.message_log.length - 1] ? (
                        <p className="p-chatbox__conversations__convoContainer__conversation__lastMessageContent">
                          {convo.message_log[convo.message_log.length - 1] &&
                          convo.message_log[convo.message_log.length - 1].text
                            .length > 20
                            ? convo.message_log[
                                convo.message_log.length - 1
                              ].text.slice(0, 20) + "..."
                            : convo.message_log[convo.message_log.length - 1]
                                .text}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="p-chatbox__conversations__convoContainer__grid__date">
                      <p className="p-chatbox__conversations__convoContainer__conversation__partyName">
                        {getDateForConvo(convo.last_message_sent)}
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                <li
                  onClick={() => chatClicked(convo)}
                  className={
                    chatOption && isInBusiness && convo._id === chatOption._id
                      ? "p-chatbox__conversations__convoContainer__conversation--sellerActive"
                      : chatOption && convo._id === chatOption._id
                      ? "p-chatbox__conversations__convoContainer__conversation--buyerActive"
                      : "p-chatbox__conversations__convoContainer__conversation"
                  }
                  key={convo._id}
                >
                  <div className="p-chatbox__conversations__convoContainer__grid">
                    <div className="p-chatbox__conversations__convoContainer__grid__messageIcon"></div>
                    <div className="p-chatbox__conversations__convoContainer__grid__conversationData">
                      <p className="p-chatbox__conversations__convoContainer__conversation__subName">
                        {convo.subscription_name}
                      </p>
                      <p className="p-chatbox__conversations__convoContainer__conversation__partyName">
                        {userId === convo.business_id
                          ? convo.buyer_name
                          : convo.business_name}
                      </p>
                      {convo.message_log[convo.message_log.length - 1] ? (
                        <p className="p-chatbox__conversations__convoContainer__conversation__lastMessageContent">
                          {convo.message_log[convo.message_log.length - 1] &&
                          convo.message_log[convo.message_log.length - 1].text
                            .length > 20
                            ? convo.message_log[
                                convo.message_log.length - 1
                              ].text.slice(0, 20) + "..."
                            : convo.message_log[convo.message_log.length - 1]
                                .text}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="p-chatbox__conversations__convoContainer__grid__date">
                      <p className="p-chatbox__conversations__convoContainer__conversation__partyName">
                        {getDateForConvo(convo.last_message_sent)}
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
            {queryResults.length == 0 ? (
              <p
                style={{
                  textAlign: "center",
                  margin: "20px auto",
                }}
              >
                You have no conversations with the given query.
              </p>
            ) : (
              ""
            )}
          </ul>
        ) : chatOptions && chatOptions.length === 0 ? (
          <p
            style={{
              textAlign: "center",
              margin: "20px auto",
            }}
          >
            You currently have no past conversations. Click the button below to
            add conversations.
          </p>
        ) : (
          <p>Conversations loading</p>
        )}
      </div>
      <div className="p-chatbox__conversations__backToSub">
        <button
          className={
            isInBusiness
              ? "p-chatbox__conversations__backToSub__button--seller"
              : "p-chatbox__conversations__backToSub__button--buyer"
          }
          onClick={handleNavigation}
        >
          {isInBusiness ? "Back to My Subscribers" : "Back to My Subscriptions"}
        </button>
      </div>
    </main>
  );
};

export default Conversations;
