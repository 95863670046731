import { useState } from "react";
import axios from "axios";

const useImageUpload = (initialImgs = []) => {
  const [imgs, setImgs] = useState(initialImgs);
  const [previewImgs, setPreviewImgs] = useState(Array(4).fill(null));

  const handleImgChange = async (file, index) => {
    const data = file[0];

    let newImgs = [...imgs];
    newImgs[index] = data;
    setImgs(newImgs);

    let fileReader = new FileReader();
    fileReader.readAsDataURL(data);
    fileReader.onload = () => {
      const imgSrc = fileReader.result;
      let newPreviewImgs = [...previewImgs];
      newPreviewImgs[index] = imgSrc;
      setPreviewImgs(newPreviewImgs);
    };
  };

  const uploadImages = async (url) => {
    console.log('Images in ImageUploads', imgs, "to url", url)
    try {
      const results = await Promise.all(
        imgs.map(async (img, index) => {
          let data = new FormData();

          data.append("file", img, img?.name);
          let res = await axios.post(url, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          
          return { index, result: res.data.file_json };
        }),
      );
      const compiledImgs = results
        .sort((a, b) => a.index - b.index)
        .map((item) => item.result);
      return compiledImgs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  return {
    imgs,
    previewImgs,
    handleImgChange,
    uploadImages,
  };
};

export default useImageUpload;
