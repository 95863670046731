import React, { useState } from "react";
import AccountDetailContainer from "./AccountDetailContainer";
import { editPassword } from "../../../CustomerStateManagement/CustomerState";
import { Toaster, toast } from "react-hot-toast";

const Security = () => {

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmedPassword, setConfirmedPassword] = useState("")
  const [processing, setProcessing] = useState(false)


  const handleOldPassword = (event) => {
    setOldPassword(event.target.value)
  }

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value)
  }

  const handleConfirmedPassword = (event) => {
    setConfirmedPassword(event.target.value)
  }


  const updatePassword = async(e) => {
    e.preventDefault()
    setProcessing(true)


    if(newPassword === confirmedPassword){
      try{
        await editPassword(oldPassword,newPassword)
        toast.success("Your password is correctly changed")
        setTimeout(() => {
          setProcessing(false)
          window.location.reload();
        }, 1000); 
      }catch(error){
        setProcessing(false)
        toast.error(error)
      }
      
    }else{
      setProcessing(false)
      toast.error("the new Password does not match with confirmedPassword.")
    }
  }
  return (

      <div className="settings-dropdown">
        {/* <h4 className='m-margin__vertical--24'>Profile Setting</h4> */}
          <Toaster />
            <div className='c-inputWrapper__vertical'>
            <label className='c-input__label'>Old Password</label>
            <input 
            type="password"
            placeholder="Your Old Password"
            onChange={handleOldPassword}
            value={oldPassword}
            className="c-input"
            style={{width:"300px"}}
             />
             </div>

            <div className='c-inputWrapper__vertical'>
            <label className='c-input__label'>New Password</label>
            <input 
            type="password"
            placeholder="Your New Password" 
            onChange={handleNewPassword}
            value={newPassword}
            className="c-input"
            style={{width:"300px"}}
            />
            </div>

            <div className='c-inputWrapper__vertical'>
            <label className='c-input__label'>Confirm Password</label>
            <input 
            type="password"
            placeholder="Confirm New Password" 
            onChange={handleConfirmedPassword}
            value={confirmedPassword}
            className="c-input"
            style={{width:"300px"}}
            />
            </div>

            <button 
            type='button' 
            className='c-smallButton--black'
            onClick={(e) => updatePassword(e)}
            disabled={processing}
            >{processing ? "Processing...":"register"}
            </button>
      </div>
  );
};

export default Security;
