import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { generateProductRow } from "../../../../CustomerStateManagement/FeedState";
import SubscriptionCard from "./SubscriptionCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SubscriptionSkeleton from "./SubscriptionSkeleton";
import { FeedContext } from "../../../../Routes/CustomerRoutes";
import { AccountContext } from "../../../../Routes/CustomerRoutes";
import useUserLocation from "../../../../utilities/getLocation";

const SubscriptionRow = ({ title, route, query, filters }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const scrollRef = useRef(null);
  const location = useUserLocation();

  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [userInfo, setUserInfo] = useContext(AccountContext);

  useEffect(() => {
    if (!location?.geometry?.coordinates) return;

    const fetchProducts = async () => {
      setIsLoading(true);

      try {
        const data = await generateProductRow({
          route,
          query,
          filters,
          location: location?.geometry?.coordinates,
        });

        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [location, filters]);

  const scrollLeft = () => {
    scrollRef.current?.scrollBy({ left: -750, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current?.scrollBy({ left: 750, behavior: "smooth" });
  };

  return (
    <>
      {products.length > 0 ? (
        <div className="p-subscriptionFeed__row">
          <div
            className="p-subscriptionFeed__rowTitle"
            // Manually set position with margin based on sidebar (Not the best design but it does the trick)
            style={
              sidebar ? { margin: "0 200px 0 0" } : { margin: "0 5px 0 0" }
            }
          >
            <div className="p-subscriptionFeed__rowTitle__title">
              <p>{title}</p>
            </div>

            <div className="p-subscriptionFeed__scroll">
              <Link
                to={`/search?query=${query}`}
                className="p-subscriptionFeed__seeAll"
              >
                See More
              </Link>
              <div className="p-subscriptionFeed__arrow" onClick={scrollLeft}>
                <MdKeyboardArrowLeft size={40} />
              </div>
              <div className="p-subscriptionFeed__arrow" onClick={scrollRight}>
                <MdKeyboardArrowRight size={40} />
              </div>
            </div>
          </div>

          <div className="p-subscriptionFeed__rowItems" ref={scrollRef}>
            {products.map((product) => (
              <SubscriptionCard key={product._id} product={product} />
            ))}

            {/* Empty item at end of row for padding */}
            <div
              style={
                sidebar
                  ? {
                      minWidth: "200px",
                      height: "100px",
                      scrollSnapAlign: "center",
                    }
                  : {
                      minWidth: "30px",
                      height: "100px",
                      scrollSnapAlign: "center",
                    }
              }
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubscriptionRow;
