/*******************************************************************************************************
 * Function/File Purpose: Account Detail page is for customer to edit their profile or review their information (Written by Aoi)
 * Author: Spencer
 * Date: Don't know
 *
 * **********************************
 * Contributor: Aoi kuriki
 * Date: 2023/6/19
 * What was Done: refactored fetch data and get rid of unused codes.
 * *******************************
 * Constributor:
 * Date:
 * What was done:
 * ********************************
 */
import React, { useEffect, useState, useContext } from "react";
import UserCard from "./Components/UserCard";
import UserSettings from "./Components/UserSettings";
import ToSettings from "./Components/ToSettings";

import { AccountContext } from "../../Routes/CustomerRoutes";
import { scrollToTop } from "../../../GlobalComponents/Utils/scroll";
import zIndex from "@mui/material/styles/zIndex";

function UserDashboard() {
  const [userInfo, setUserInfo] = useContext(AccountContext);

  useEffect(() => {
    scrollToTop();
  }, [])

  return (
    <main>
      <div className="userDetailsContainer l-inner" style={{ minHeight: 0 }}>
        {/* User Details Card */}
        <div style={{zIndex: 0}}> {/* please keep the zIndex otherwise, it will go past the header because of the position: sticky*/}
          <UserCard />
        </div>
        {/* User Settings */}
        <UserSettings />
      </div>
      <hr></hr>
      <div className="toDetailsContainer">
        <ToSettings data={userInfo} />
      </div>
    </main>
  );
}

export default UserDashboard;
