import React, {
  useRef,
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import checkmark from "../../../../../Assets/Icons/checkmark_white.svg";
import { addOrUpdateWaitlist } from "../../../../CustomerStateManagement/FeedState";
import { formatBusinessTypeString } from "../../../../utilities/global_function";

import { FaShop, FaStar } from "react-icons/fa6";
import Rating from "@mui/material/Rating";
import toast, { Toaster } from "react-hot-toast";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { AccountContext } from "../../../../Routes/CustomerRoutes";
import { calculateDistance } from "../../../../../Functions/Formulas/calculateDistance";
import useUserLocation from "../../../../utilities/getLocation";
import PurplePin from "../../../../../Assets/Icons/location_pin_purple.svg";
import OrangePin from "../../../../../Assets/Icons/location_pin_orange.svg";
import OrangePinBorder from "../../../../../Assets/Icons/location_pin_orange_whiteBorder.svg";
import GrayPin from "../../../../../Assets/Icons/location_pin_gray.svg";
import GrayPinBorder from "../../../../../Assets/Icons/location_pin_gray_blackBorder.svg";
import WhitePin from "../../../../../Assets/Icons/location_pin_white.svg";

const Url = require("../../../../../backendURL");

// Takes in an array of sellers and an array of google places and a away to toggle the width of the map with the sidebar on the business feed page.
const BusinessMap = ({
  googlePlaces,
  range,
  searchQuery,
  widthToggle,
  setWidthToggle,
  sellerPins,
  filters,
  submitFilter,
}) => {
  const api_key = process.env.REACT_APP_EXPO_MAPBOX_PUBLIC_KEY;
  const google_api_key = process.env.REACT_APP_GOOGLE_PHOTOS_API_KEY;

  const [userInfo, setUserInfo] = useContext(AccountContext);
  const location = useUserLocation();

  const mapRef = useRef();
  const markersRef = useRef([]);
  const placeMarkersRef = useRef([]);
  const mapContainerRef = useRef();
  const [sellers, setSellers] = useState();

  const [places, setPlaces] = useState(googlePlaces);

  // Resize the map whenever the window height / width is changed
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Set the google places to be what's passed in through the businessfeed.jsx
  useEffect(() => {
    if (googlePlaces) {
      setPlaces(googlePlaces);
    }
  }, [googlePlaces]);

  useEffect(() => {
    if (!location) return; // Return if there's no location yet
    mountMap();
  }, [location]);

  // Center the map on the users location / default location
  useEffect(() => {
    if (mapRef.current && location?.geometry?.coordinates) {
      mapRef.current.setCenter(location?.geometry?.coordinates);
    }
  }, [location]);

  // MAP INFORMATION BELOW //////////
  // Renders the map on mount
  const mountMap = useCallback(() => {
    mapboxgl.accessToken = api_key;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: location?.geometry?.coordinates,
      zoom: 11, // How zoomed in the map is on load
      boxZoom: true,
      respectPrefersReducedMotion: true, // Respects browser reduced motion accessibility settings
      performanceMetricsCollection: true, // Mapbox will collect and send performance metrics
      style: "mapbox://styles/aoi727subitt/cm6jwredh00bf01rfh1eq0ww9", // Custom
      // style: "mapbox://styles/mapbox/dark-v11", // Dark Mode
      // style: "mapbox://styles/mapbox/light-v11", // Light mode
      trackResize: true,
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl());

    return () => {
      mapRef.current.remove();
    };
  }, [location]);

  // Put the Google places markers on the map
  useEffect(() => {
    if (!places || !mapRef.current) return; // Return if there are no places or map isn't ready

    // Clear any existing markers to avoid duplication
    placeMarkersRef.current?.forEach((marker) => marker.remove());
    placeMarkersRef.current = [];

    // Loop through places and add markers to the map
    places.forEach((feature) => {
      const { coordinates } = feature.geometry;
      const { businessId, name, mainType, photos } = feature.properties;
      const distanceToUser = calculateDistance(
        location?.geometry?.coordinates,
        coordinates,
      ).toFixed(1); // Distance away from place using coordinates

      const placeMarker = document.createElement("div");
      placeMarker.className = "c-customMarker c-customMarker__places";
      placeMarker.style.width = "35px";
      placeMarker.style.height = "35px";
      placeMarker.style.backgroundImage = `url(${GrayPinBorder})`; // Set places to have the purple location
      placeMarker.style.backgroundSize = "contain";
      placeMarker.style.backgroundRepeat = "no-repeat";

      // Check if the google ID is in session storage
      const isInSessionStorage = sessionStorage.getItem(businessId) !== null;
      const waitlistContent = !isInSessionStorage
        ? `<div class="popup-content__waitlist">
              <div class="popup-content__waitlist__request">   
                <p class="popup-content__waitlist__request__message">Want this business on Subitt?</p>
                <div id="waitlist-button-${businessId}" class="c-waitlistButton">Invite</div>
              </div>
            </div>`
        : `<div class="popup-content__waitlist">
              <div class="popup-content__waitlist__requested">
                <p>You've waitlisted this business:</p>
                <img src=${checkmark} id="waitlist-icon-${businessId}" class="c-waitlistedIcon" />
              </div>
            </div>`;

      // <img class="popup-content__avatar" src="https://places.googleapis.com/v1/${
      //   photos[0]?.name
      // }/media?maxHeightPx=400&maxWidthPx=400&key=${google_api_key}" />
      const marker = new mapboxgl.Marker(placeMarker)
        .setLngLat(coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<div class="popup-content">
                <div class="popup-content__businessName"><p>${name}</p> 
                ${
                  distanceToUser && userInfo.address
                    ? `<h6 class="popup-content__distance">${distanceToUser} miles</h6>`
                    : ``
                }</div>
                <div class="popup-content__businessInfo">
                  <h5>${formatBusinessTypeString(mainType)}</h5>
                </div>
                ${waitlistContent}
              </div>`,
          ),
        )
        .addTo(mapRef.current);

      marker.getPopup().on("open", () => {
        const button = document.getElementById(`waitlist-button-${businessId}`);
        const message = document.getElementsByClassName(
          `popup-content__waitlist__request__message`,
        );

        if (button) {
          button.onclick = async () => {
            let waitlistCount = await addOrUpdateWaitlist(
              feature,
              userInfo?._id,
            );

            toast.success(
              "Thanks for the invite, we'll let you know when they join!",
              {
                className: "c-toastPopup",
                duration: 5000,
                iconTheme: {
                  primary: "#e5704c",
                  secondary: "#fff",
                },
              },
            );

            sessionStorage.setItem(businessId, true);
            if (message.length >= 1) {
              message[0].remove();
            }

            // Change the text inside the element when they click waitlist
            button.outerHTML = `<p>${
              waitlistCount > 1
                ? `${waitlistCount} people want`
                : `${waitlistCount} person wants`
            }  to subscribe here! </p><img src=${checkmark} id="waitlist-icon-${businessId}" class="c-waitlistedIcon">`;
          };
        }
      });

      placeMarkersRef.current.push(marker); // Keep track of added markers
    });
  }, [places]);

  // Sellers is second so orange pins are placed above purple pins
  useEffect(() => {
    if (!sellerPins || !mapRef.current) return; // Return if there are no sellers or map isn't ready

    // Clear any existing markers to avoid duplication
    markersRef.current?.forEach((marker) => marker.remove());
    markersRef.current = [];

    // Loop through sellers and add markers to the map
    sellerPins.forEach((feature) => {
      const { coordinates } = feature.geometry;
      const { averageRating, banner, id, reviewCount, sellerPlus, title } =
        feature.properties;
      const distanceToUser = calculateDistance(
        location?.geometry?.coordinates,
        coordinates,
      ).toFixed(1); // Distance away from seller using coordinates

      // Styling for seller markers
      const sellerMarker = document.createElement("div");
      sellerMarker.className = "c-customMarker  c-customMarker__subitt";
      sellerMarker.style.width = "35px";
      sellerMarker.style.height = "35px";
      sellerMarker.style.backgroundImage = `url(${OrangePinBorder})`;
      sellerMarker.style.backgroundSize = "contain";
      sellerMarker.style.backgroundRepeat = "no-repeat";

      const marker = new mapboxgl.Marker(sellerMarker)
        .setLngLat(coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // Add popups to the markers
            .setHTML(
              `<div class="popup-content">
                <img src="${Url}images/${banner}" alt="${title} banner image" class="popup-content__avatar"/>
                <div class="popup-content__businessName"><p>${title}</p></div> 

                <div class="popup-content__lineTwo">
                  <svg stroke="currentColor" fill="#e5704c" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path></svg>
                  <h1>${averageRating.toFixed(
                    1,
                  )}</h1> <p>(${reviewCount} reviews) /</p>
                   ${
                     distanceToUser && userInfo.address
                       ? `<h6 class="popup-content__distance">${distanceToUser} miles</h6>`
                       : ``
                   }
                   <a href="/customerStoreFront/${id}" class="popup-content__seeMore">View</a>
                </div>
              </div>`,
            ),
        )
        .addTo(mapRef.current);

      markersRef.current.push(marker); // Keep track of added markers
    });
  }, [sellerPins]);

  // Handlers
  // Toggle the width change on the toggle button / resize map, placed in the top left of the map
  const handleToggle = () => {
    setWidthToggle((prevState) => !prevState);
    // Short delay before calling mapbox resize
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    }, 200);
  };

  return (
    <>
      <Toaster />
      <div
        className="p-businessFeed__toggles"
        style={widthToggle ? { left: "540px" } : { left: "15px" }}
      >
        <div className="p-businessFeed__widthToggle" onClick={handleToggle}>
          {widthToggle ? (
            <>
              <IoIosArrowBack size={15} />
              <p>Full Map</p>
            </>
          ) : (
            <>
              <IoIosArrowForward size={15} />
              <p>Show List</p>
            </>
          )}
        </div>
      </div>

      <div
        id="map-container"
        className="p-businessFeed__map"
        ref={mapContainerRef}
        // Mapbox struggles to dynamically resize the map, so to cope for that I've just set it to be larger than the size of the screen and then I hide it
        style={
          widthToggle ? { width: "calc(100vw - 525px)" } : { width: "100vw" }
        }
      ></div>
    </>
  );
};

export default BusinessMap;
