/**************************************************************************************
 * File: HeartButton.jsx
 * Purpose: Displays button with an svg of a filled or not filled heart based on the 
 * if an item is liked. This button may be pressed to add or delete it from
 * a user's wishlist. It is dependent on a liked : Boolean, className : String
 * data: Object, wishlist : List being passed
**************************************************************************************/
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { getWishlist, updateWishlist, deleteWishlist } from '../../Customer_Module/CustomerStateManagement/CustomerState';

const HeartButton = (props) => {
    const [liked, setLiked] = useState(props.liked);
    const [className, setClassName] = useState(props.className);
    const [data, setData] = useState(props.data);
    const [wishlist, setWishlist] = useState(props.wishlist)
    const [loading, setLoading] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(true)

    const saveItemToWishlist = async (e, data) => {
        e.stopPropagation();        
        let currentWishlist = await getWishlist();

        let itemIds = []
        
        for (let index in wishlist) {
          itemIds.push(wishlist[index].itemId)
        }

        if (liked == false) {
          const date = new Date().toLocaleDateString();
          if (data.itemType == undefined) {
            data.itemType = 'other'
          }
          const response = await updateWishlist(data._id, date, data.itemType);
          setWishlist(response)
          currentWishlist = await getWishlist();
        }
        else {
          setLoading(true)
          const index = itemIds.indexOf(data._id)
          const response = await deleteWishlist(currentWishlist[index]);
          setWishlist(response)
          currentWishlist = await getWishlist()
          setLoading(false)
        }
        setLiked(!liked);
    };

    if (loading) {
      console.log('Loading...')
    }

    useEffect(() => {
      setLoading(true)
      if (localStorage.getItem('userId') === null) {
        setIsLoggedIn(false)
      } else {
        setIsLoggedIn(true)
      }
      setLoading(false)
    }, [])

  return (
    <>
    {isLoggedIn === true ? (<div className={className} onClick={(e) => saveItemToWishlist(e, data)}>
        <svg className={liked ? `${className}--fill` : `${className}--noFill`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
        </svg>       
    </div>): (<></>)}
    </>
    
  )
}

export default HeartButton;