import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import subittLogo from "../../Assets/subittLogo-orange.png";
import businessSubittLogo from "../../Assets/businessSubittLogo.png";
import subittLogoWhite from "../../Assets/subittLogo-white.png";
import NavLinks from "./NavLinks";
import toast from "react-hot-toast";
import "../Styles/Global.css";
import Searchbar from "./Searchbar";
import { checkCustomerJWT } from "../../Customer_Module/utilities/checkCustomerJWT";
import NavLink from "./NavLink";
import { categories } from "../../Component/Options/Category";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isInBusiness, setIsInBusiness] = useState(false);

  useEffect(() => {
    let login = localStorage.getItem("isLoggedIn");
    if (login != null) {
      setIsLogin(true);
      // get Location
    } else {
      setIsLogin(false);
    }
  }, [isLogin, location.pathname]);

  useEffect(() => {
    // Check if user is in CustomerSide or Business Side by url and change nab button color
    const locationChecker = () => {
      setIsInBusiness(location.pathname.includes("businessportal"));
    };

    locationChecker();
  }, [location.pathname]);

  const [login, setLogin] = useState(false);

  const handleModalExit = () => {
    setLogin(false);
    // window.location.href = localStorage.getItem("lastLoc");
  };

  if (location.pathname.includes("/businessportal/storeSetUp")) {
    return;
  }
  if (location.pathname === "/businessportal/landingPage") {
    return null;
  }

  return (
    <nav className="l-nav__container">
      <div className="l-nav__wrapper">
        <div className="l-nav__top">
          <div className="l-nav__logo">
            <Link to={isInBusiness ? "/businessportal/dashboard" : "/feed"}>
              <img
                src={isInBusiness ? businessSubittLogo : subittLogo}
                alt="subittLogo"
              />
            </Link>
          </div>

          {/* search bar */}
          {/* <div className="l-nav__searchBar">
            {isInBusiness ? null : <Searchbar />}
          </div> */}
          {/* links */}
          {isInBusiness ? (
            <NavLink isInBusiness={false} isLogin={isLogin} />
          ) : (
            <NavLinks isLogin={isLogin} isInBusiness={isInBusiness} />
          )}
        </div>

        {/* <div className="l-nav__global">
          {!isInBusiness &&
            categories.map((category, index) => (
              <p key={index} onClick={() => navigate(`/sortBy/${category}`)}>
                {category}
              </p>
            ))}
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
