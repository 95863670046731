import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";
import ShippingInfo from "./ShippingInfo";
import Security from "./Security";

export default function UserSettingsCard({ icon, header, subheader, link }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (link != 'open' && link != 'close') {
      navigate(`/${link}`);
    }
  };
  return (
    <>
    <div className="userDetailsSettingsCard" onClick={handleClick}>
      <div className="userDetailsSettingsCardHeader">
        <div className="userDetailsSettingsCardHeaderLeft">
          <IconContext.Provider
            value={{ className: "userDetailsSettingsCardIcon" }}
          >
            {icon}
          </IconContext.Provider>
          <h3>{header}</h3>
        </div>
        <div className="userDetailsSettingsCardHeaderRight">
          <IconContext.Provider
            value={{ className: "userDetailsSettingsCardIcon" }}
          > 
          {link == 'open' ? 
          (
            <IoIosArrowDown />            
          ) : 
          (
            <IoIosArrowForward />
          )}
          </IconContext.Provider>
        </div>
      </div>
      <hr></hr>
      <p>{subheader}</p>
    </div>
    </>
  );
}
