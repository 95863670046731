import React from "react";

const SubscriptionSkeleton = () => {
  return (
    <>
      <div className="c-skeletonBox"></div>
    </>
  );
};

export default SubscriptionSkeleton;
