import React, { useState, createContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import SmallCalendar from "./SmallCalendar"
import "../Styles/AvailabilityPage.css"
import axios from 'axios';
import Subtitle from '../../../../GlobalComponents/Modules/Atom/Subtitle';
import { AddServiceTA, getAllServicesByBusinessId } from '../../../BusinessStateManagement/businessState';
export const DateContextAvailability = createContext();



function AvailabilityPage() {



  let date1 = new Date();
  const [d, setD] = useState(date1)
  const Url = require("../../../../backendURL");
  const [services, setServices] = useState([])
  const [dateSelected, setDateSelected] = useState(new Date())
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState()
  const [recurring, setRecurring] = useState(false);
  const [serviceId, setServiceId] = useState([])
  const [selectedHour, setSelectedHour] = useState(null)
  const [selectedMinute, setSelectedMinute] = useState(null)
  const [selectedNoon, setSelectedNoon] = useState(null)
  const [trigger, setTrigger] = useState(true)

  const hourOptions = ["1","2","3","4","5","6","7","8","9","10","11","12"];
  const minuteOptions = ["00","15","30","45"];
  const noonOptions = ["AM", "PM"];

  console.log(services)

  const navigate = useNavigate()
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

  useEffect(()=>{
    getData()

    // services.length == 0 ? <div>loading...</div>:services.data.docs.forEach((element)=>{displayServices(element)})
  },[trigger])

  const getData = async()=> {
    const businessID = localStorage.getItem("businessId")
    const res = await getAllServicesByBusinessId(businessID)
    setServices(res)
    }

  const handleRecurring = () => {
    if(recurring) {
      setRecurring(false)
    } else {
      setRecurring(true)
    }
  }

  const combineTime = () => {
    const time = `${selectedHour}:${selectedMinute} ${selectedNoon}`
    return time
  }

 
  const handleEndTime = (e) => {
    e.preventDefault();
    setEndTime(e.target.value)
  }


  const handleSubmission= async(e) => {
    e.preventDefault()

    try {

      const setTime = combineTime()

      for(let i=0; i < serviceId.length; i++) {
        let [serId, subTypeId] = serviceId[i].split(";");

      
        const data = {
          "dateTime": `${dateSelected.toLocaleDateString()}, ${setTime}`
          }
        console.log("data",data)
        await AddServiceTA(serId,subTypeId,data)
        toast.success("New Open Spot is created,\n Check in your service calendar")
        setTrigger(!trigger)
      }


      
    } catch(err){
      toast.error(err)
    }

  }
   function addService (e) {
      // e.preventDefault()
      if (serviceId.includes(e.target.value)) {
        const newArray = serviceId.filter((data)=>
          data !== e.target.value 
        )
        setServiceId(newArray)
      } else {
        
        setServiceId(pre => [...pre,e.target.value])
      }
  }



  const fixTimeFormat = (time) => {
    const newTime = time.replace(":00 ", " ");
    return newTime
  }

  
  return (
    <section className="businessMainContent">
      <div id="availabilityContainer">
        <div id='avLeft'>
          <Subtitle subtitle="Availability Setting" />
          <div className="avLeft">
           
            <form onSubmit={handleSubmission}>
              <div className='avTime'>
                <div>
                <p>On <span>{`${days[dateSelected.getDay()]}, ${months[dateSelected.getMonth()]} ${dateSelected.getDate()}`}</span>
                  <p></p>
                </p>
                </div>
                {/* <label id='recurringLabel'><input type='checkbox' onChange={handleRecurring}/>Recurring</label> */}

               <div className='avStartEnd'>

                <select value={selectedHour}   onChange={(e)=>setSelectedHour(e.target.value)}>
                <option value="">Select hour</option>
                {hourOptions.map((num)=>(
                  <option value={num}>{num}</option>
                ))}
                </select> 

                <select value={selectedMinute}   onChange={(e)=>setSelectedMinute(e.target.value)}>
                <option value="">Select minute</option>
                {minuteOptions.map((num)=>(
                  <option value={num}>{num}</option>
                ))}
                </select>   

                <select value={selectedNoon}   onChange={(e)=>setSelectedNoon(e.target.value)}>
                <option value="">Select AM/PM</option>
                {noonOptions.map((num)=>(
                  <option value={num}>{num}</option>
                ))}
                </select>             
            {/* {recurring?<label>End <input type="time" name="end" id="end" onChange={handleEndTime}/></label>:""} */}
                
               </div>
              </div>
              <div className='servicesTitle'>
                <p>Services Offered</p>
              </div>
              <div className='avServices'>
              {services.length == 0 ? <div>loading...</div>:services.map((element)=>(
                
                <label key={element._id}>
                  <input type="checkbox" value={`${element._id};${element.subTypes[0]._id}`} onChange={addService}/>
                  <div className='avServiceContent'>
                    <div>
                      <p>Service Name</p>
                      <span id='serviceName'>{element?.name !== undefined ? element?.name : element?.serviceName}</span>
                    </div>
                    <div>
                      <p>Time Duration</p>
                      <span id='serviceDuration'>1 hr</span>
                    </div>
                    <div>
                      <p>Price</p>
                      <span id='servicePrice'>${element?.subTypes[0].price/100.00}</span>
                    </div>
                  </div>
                </label>

              ))}
                
              </div>
              <div id='avRecurrent'>
                <input type="submit" value="Create Availability" disabled={selectedHour===null ||selectedMinute===null ||selectedNoon===null || serviceId.length===0}/>
              </div>
            </form>
          </div>
        </div>
        <div id="avRight">
          <DateContextAvailability.Provider value={{ d, setD }}>
            <SmallCalendar date={d} setDateSelected={setDateSelected}></SmallCalendar>
          
          </DateContextAvailability.Provider>
          <div className='spotView'>
            <h2>Current open spots</h2>
                <table>
                  <tbody>
                  {
              services?.map((data)=>(
                    <tr>
                      <th>{data?.name}</th>
                      {
                        data?.appointmentSlots && Object.keys(data.appointmentSlots).length > 0 &&
                          Object.entries(data?.appointmentSlots)
                            .filter(([key, value]) => value === "Open")
                            .map(([key]) => (
                              <td key={key}>{fixTimeFormat(key)}</td>
                            ))
                      }
                    </tr>
                    ))}
                  </tbody>
                </table>
              
            
          </div>
        </div>
      </div>
      <Toaster />
   
    </section>
  )
}


export default AvailabilityPage