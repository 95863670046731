import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'

const SocialMedias = ({data}) => {
  return (
    <div className="c-socialMedias">
    {
      data?.socialMedia?.instagram && 
      <span>
        <FaInstagram
          onClick={()=>(window.open(data?.socialMedia?.instagram))}
        />
      </span>
    }
    {
      data?.socialMedia?.facebook && 
      <span>
        <FaFacebook 
        onClick={()=>(window.open(data?.socialMedia?.facebook))}
        />
      </span>

    }
    {
      data?.socialMedia?.linkedin &&
      <span>
        <FaLinkedin 
        onClick={()=>(window.open(data?.socialMedia?.linkedin))}
         />
      </span>
    }
  </div>
  )
}

export default SocialMedias