import { GrCircleInformation } from 'react-icons/gr'
import { Tooltip } from 'react-tooltip'

const InformationIcon = ({ content, place="right", style, size=11 , id }) => {
 
  return (
    <div >
        <GrCircleInformation
         id={id}
         size={size}
         style={style}
         />

        <Tooltip
           anchorSelect={`#${id}`}
           content={content}
           place={place}
           openOnClick
        />

    </div>
  )
}

export default InformationIcon