/*
============================================================
Initial Information
==========
File Content: This file is a parent file for Business Detail. When customer chooses a product or service, get productId from "pages/Home/HomePageProducts/Product.jsx" and display 

Author: Aoi Kuriki
File Created Date: Before
===================================================
Revision History
==========
Author: Lakeram Narine
Revised Date: 6/1/2023
Revised Points: Refactor the page, Rename the component and make code efficient.
==========
Revision 2 here
===================================================
Author: Aoi Kuriki
Revised Date: 6/2/2023
Revised Points: Refactor the page, This file is the parent page to display other children components
============================================================
*/
import ReviewContainer from "../ItemDetail/Components/reviews/ReviewContainer";
import React, { Suspense, useEffect, useState, createContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import ItemTemplateSkelton from "../../utilities/ItemTemplateSkelton";
import BusinessBannerSkelton from "../../utilities/BusinessBannerSkelton";
import {
  getAllUserReviews,
  getReviewsByItemId,
  getReviewsByUserId,
} from "../../CustomerStateManagement/ReviewState";
const BusinessBannerInfo = React.lazy(() =>
  import("./Components/BusinessBannerInfo"),
);
const ItemTemplate = React.lazy(() => import("./Components/ItemTemplate"));
const RelatedItems = React.lazy(() =>
  import("../CustomerStoreFront/Components/RelatedItems"),
);

export const ReviewContext = createContext();

const ProductDetailPage = () => {
  const { type, itemId, businessId } = useParams();
  const location = useLocation();
  const { chosenIndex } = location.state || 0;

  // Set up reviews functions
  const [reviews, setReviews] = useState(); // Holds reviews of any specific item, set in ReviewContainer
  const [hasReviewed, setHasReviewed] = useState(); // On item detail page, shows if the user has reviewed a specific item
  const [filtered, setFiltered] = useState(0); // On item detail page, shows a specific message if there are no reviews with a certain filter

  // Fetch reviews
  useEffect(() => {
    const fetchReviewsByItemId = async () => {
      const result = await getReviewsByItemId(itemId);
      const userReviews = result.filter(
        (review) => review.accountId === localStorage.getItem("userId"),
      );
      const otherReviews = result.filter(
        (review) => review.accountId !== localStorage.getItem("userId"),
      );

      

      const sortedReviews = [...userReviews, ...otherReviews];

      //setReviews([...userReviews, ...otherReviews])
      setReviews(sortedReviews);
    };

    fetchReviewsByItemId();
  }, []);

  async function fetchOriginalReviews() {
    const userId = localStorage.getItem("userId");
    try {
      const response = await getReviewsByUserId(userId);

      // Check if any of the user's reviews has the current itemId
      const hasReviewedItem = response.some(
        (review) => review.itemId === itemId,
      );

      setHasReviewed(hasReviewedItem);
    } catch (error) {
      setHasReviewed(false);
    }
  }

  useEffect(() => {
    fetchOriginalReviews();
    //const userId = localStorage.getItem("userId");
    //setHasReviewed(reviews.some(review => review.accountId === userId));
  }, [reviews]);
  /***********************
   * this function will scroll page to item information
   * ********************/

  let scrollOption = {
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  };

  const scrollToElement = () => {
    const targetElement = document.getElementById("BDmain");

    if (targetElement) {
      const offsetTop =
        targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: offsetTop, ...scrollOption });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(scrollToElement, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [itemId]);

  /***************************************************************/

  return (
    <ReviewContext.Provider
      value={[
        reviews,
        setReviews,
        hasReviewed,
        setHasReviewed,
        filtered,
        setFiltered,
      ]}
    >
      <div className="l-inner">
        <Suspense fallback={<BusinessBannerSkelton />}>
          <BusinessBannerInfo businessId={businessId} />
        </Suspense>
        <Suspense fallback={<ItemTemplateSkelton />}>
          <ItemTemplate
            itemId={itemId}
            businessId={businessId}
            type={type}
            chosenIndex={chosenIndex}
          />
          <ReviewContainer itemId={itemId} businessId={businessId} />
        </Suspense>
        <Suspense fallback={<span>Loading</span>}>
          <RelatedItems businessId={businessId} />
        </Suspense>
      </div>
    </ReviewContext.Provider>
  );
};

export default ProductDetailPage;
