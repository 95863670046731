/*******************************************************************************************************
 * Function/File Purpose: This page is the algorithm that generates the content for the home feed
 * Author: Isaac Radford
 * Date: 1/30/2025
 */

import SubscriptionRow from "../SubscriptionComponents/SubscriptionRow";
import ContactHome from "../../../HomePage/Components/ContactHome";
import BuyerTestimonials from "../../../HomePage/Components/BuyerTestimonials";
import FavoriteSellers from "./FavoriteSellers";
import TopTenItems from "./TopTenItems";
import RecentReviews from "./RecentReviews";
import { Footer } from "../../../../../GlobalComponents";

/*******************************************************************************************************
 * HOW IT WORKS:
 * It's pretty straightforward, but the SubscriptionFeed.jsx loads this and keeps an index of which row has been loaded,
 * and then uses an intersection observer to load the next row and increment the index.
 * Every entry in this feedData object will be a row that gets loaded onto the feed.
 *
 * INPUTS:
 * title: Simple, this is just a string, it will display above the row. Make it something catchy and cute
 *
 * route: This is the backend route that will be targeted by the GenerateProductRow function in the FeedState.js.
 *        There's a lot of different routes to use, but the default is subscriptions, which will target the {URL}/feed/subscriptions route
 *        It's set up so you can target any /feed Route though, so work with your favorite backend Dev to make a new one if you want (Ex: subscriptions/top-ten)
 *
 * query: Think of this as the Search Bar on the top of the website. Pass in a query, the backend will search for content with that query
 *        in the Name, Description, BusinessName, or Category and return 6 products with that query
 *
 * component: The component that the data will be loaded into. Hypothetically can be any component, but it be one of the ones in this FeedContent folder
 * */

export const feedData = [
  // {
  //   component: BuyerTestimonials,
  // },
  {
    title: "test",
    route: "subscriptions",
    query: "test",
    component: SubscriptionRow,
  },
  {
    title: "Fizzy Fixes",
    route: "subscriptions",
    query: "drinks",
    component: SubscriptionRow,
  },
  {
    component: TopTenItems,
  },
  {
    title: "AM Assists",
    route: "subscriptions",
    query: "coffee",
    component: SubscriptionRow,
  },
  {
    title: "Automotive",
    route: "subscriptions",
    query: "auto",
    component: SubscriptionRow,
  },
  {
    component: FavoriteSellers,
  },
  {
    title: "Sweet Treats",
    route: "subscriptions",
    query: "sweet",
    component: SubscriptionRow,
  },
  {
    title: "Pet Care",
    route: "subscriptions",
    query: "pet",
    component: SubscriptionRow,
  },
  // {
  //   component: RecentReviews,
  // }
  // {
  //   component: Footer,
  // },
];

// Food:
// Coffee Shops, Soda Shops, Boba, Food
// Fitness:
// Yoga, Gyms, Home Cleaning, Rock Gyms, Crossfit
// Home:
// Home Cleaning, Snow Removal, Lawncare, Pet Waste, Garbage Can
// Pet:
// Pet Waste, Pet Grooming, Self Serve, Food
// Recreational:
// Trampoline Parks, Golf Simulators
// Car:
// Oil Change, Car Wash, Detailing (edited)
