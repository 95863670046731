import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import NoResultsPage from "../../Components/NoResultsPage";
import RecommendationForm from "../../Components/RecommendationForm";

import {
  searchNearbySellers,
  searchNearbyMemberships,
} from "../../CustomerStateManagement/FeedState";
import "./Styles/SearchResult.css";
import ItemContainer from "./Components/ItemContainer";
import SubscriptionSearchResult from "./Components/SubscriptionSearchResult";
import BusinessSearchResult from "./Components/BusinessSearchResult";
import SEO from "../../../Component/Atom/SEO";
import { AccountContext } from "../../Routes/CustomerRoutes";
import { FeedContext } from "../../Routes/CustomerRoutes";
import Filters from "../../Components/Filters";
import Sidebar from "../../Components/Sidebar";
import useUserLocation from "../../utilities/getLocation";

const SearchResult = () => {
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query"));
  const [totalBusResults, setTotalBusResults] = useState(0);
  const [totalSubResults, setTotalSubResults] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const location = useUserLocation();

  // Pagination for businesses + Subscriptions
  const [businessPage, setBusinessPage] = useState(1);
  const [subscriptionPage, setSubscriptionPage] = useState(1);

  // Search Filters, being modified by SearchFilters.jsx
  const [searchFilters, setSearchFilters] = useState({
    distance: 250,
    // + Businesses / Subscriptions Only
  });

  // Scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Update search if they're already on page
  useEffect(() => {
    const params = searchParams.get("query");
    setQuery(params);
  }, [searchParams]);

  // Functions to get businesses and subscriptions
  const fetchBusinessesByQuery = useCallback(async () => {
    const res = await searchNearbySellers({
      query: query,
      location: location?.geometry?.coordinates,
      page: businessPage,
      filters: searchFilters,
    });

    return res;
  }, [location?.geometry?.coordinates, query, searchFilters, businessPage]);

  // Functions to get businesses and subscriptions
  const fetchSubscriptionsByQuery = useCallback(async () => {
    const res = await searchNearbyMemberships({
      query: query,
      location: location?.geometry?.coordinates,
      page: subscriptionPage,
      filters: searchFilters,
    });

    return res;
  }, [location, query, searchFilters, subscriptionPage]);

  // Fetch the Businesses on load + when relevant info/pagination changes
  useEffect(() => {
    if (!location?.geometry?.coordinates) return; // Return if the users location isn't there

    const fetchBusinesses = async () => {
      setIsLoading(true);
      try {
        const busRes = await fetchBusinessesByQuery();
        setBusinesses((prevBus) => [...prevBus, ...busRes?.docs]);
        setTotalBusResults(busRes?.total);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    };

    fetchBusinesses();
  }, [location?.geometry?.coordinates, query, searchFilters, businessPage]);

  // Fetch the Subscriptions on load + when relevant info/pagination changes
  useEffect(() => {
    if (!location?.geometry?.coordinates) return; // Return if the users location isn't there

    const fetchSubscriptions = async () => {
      setIsLoading(true);
      try {
        const subRes = await fetchSubscriptionsByQuery();
        setSubscriptions((prevSubs) => [...prevSubs, ...subRes?.docs]);
        setTotalSubResults(subRes?.total);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    fetchSubscriptions();
  }, [location?.geometry?.coordinates, query, searchFilters, subscriptionPage]);

  // Reset pagination + results when search filter or query changes
  useEffect(() => {
    setBusinessPage(1);
    setBusinesses([]);

    setSubscriptionPage(1);
    setSubscriptions([]);
  }, [location, searchFilters, query]);

  ////////////////
  //  HANDLERS  //
  ////////////////

  // Handles click to load more button
  const handleBusinessLoadMore = async (e) => {
    setBusinessPage((prevPage) => prevPage + 1);
  };

  // Handles click to load more button
  const handleSubscriptionLoadMore = async (e) => {
    setSubscriptionPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <SEO title={`Results for "${query}"`} />
      <main
        className="p-searchResult"
        style={
          !sidebar
            ? {
                display: "grid",
                gridTemplateColumns: "auto 100vw",
                gridTemplateRows: "70px 70px auto auto",
              }
            : {
                display: "grid",
                gridTemplateColumns: "auto calc(100% - 200px)",
                gridTemplateRows: "70px 70px auto auto",
              }
        }
      >
        <div
          className="l-cSidebar"
          style={!sidebar ? { width: "0px" } : { width: "200px" }}
        >
          <Sidebar />
        </div>
        <Filters setParentFilters={setSearchFilters} />
        <>
          {totalBusResults === 0 && totalSubResults === 0 ? (
            <></>
          ) : (
            <div className="p-searchResult__count">
              {totalBusResults} business{totalBusResults === 1 ? "" : "es"},{" "}
              {totalSubResults} subscription
              {totalSubResults === 1 ? "" : "s"}
            </div>
          )}
          <div
            className="p-searchResult__content"
            // style={sidebar ? { width: "100%" } : { width: "calc(100vw - 200px)" }}
          >
            <BusinessSearchResult businesses={businesses} />

            {/* // If the number currently shown does not equal the total count of docs received from the db, there's more to load */}
            {businesses.length != totalBusResults && (
              <div onClick={handleBusinessLoadMore} className="c-loadMore">
                More Businesses
              </div>
            )}

            <SubscriptionSearchResult subscriptions={subscriptions} />
            {subscriptions.length != totalSubResults && (
              <div onClick={handleSubscriptionLoadMore} className="c-loadMore">
                More Subscriptions
              </div>
            )}

            {totalSubResults < 1 && totalBusResults < 1 && (
              <NoResultsPage title={query} />
            )}
            {(subscriptions?.length < 6 || businesses?.length < 6) && (
              <div
                className="p-category__recommendation"
                style={
                  sidebar
                    ? { width: "calc(100vw - 200px)" }
                    : { width: "100vw" }
                }
              >
                <div className="p-category__recommendation__title">
                  <h1>Have a business you want to see on Subitt?</h1>
                  <p>
                    Recommend a business for a chance to earn a free first month
                    when they join.
                  </p>
                </div>
                <RecommendationForm showHeader={false} />
              </div>
            )}
          </div>
        </>

        {/* <ItemContainer /> */}
      </main>
    </>
  );
};

export default SearchResult;
