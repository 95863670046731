/*******************************************************************************************************
 * Function/File Purpose: This component is for businessBanner displays information of business and link for call, email, and check location
 * Author: Aoi Kuriki
 * Date: 6/2/2023
 *
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * *******************************
 * Constributor:
 * Date:
 * What was done:
 * ********************************
 */

import React, { useEffect, useState } from "react";
import { BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../../utilities/global_function";
import { getBusinessById } from "../../../CustomerStateManagement/CustomerState";
import BusinessBannerSkelton from "../../../utilities/BusinessBannerSkelton";
import SocialMedias from "../../../../Component/Atom/SocialMedias";
import SEO from "../../../../Component/Atom/SEO";
import EmptyAvatar from "../../../../Assets/emptyAvatar.webp";
import EmptyBanner from "../../../../Assets/subittBanner.jpg";
import onShare from "../../../../Functions/Global/share_button.js";
import { Toaster } from "react-hot-toast";

const BusinessBannerInfo = ({ businessId }) => {
  const navigate = useNavigate();
  const Url = require("../../../../backendURL");
  const [data, setData] = useState(null);

  /***********************
   * Controlling functions to make actions for
   * {call, email, and open map}
   * ********************/
  useEffect(() => {
    const fetchBusinessData = async () => {
      const result = await getBusinessById(businessId);
      setData(result);
    };
    fetchBusinessData();
  }, []);

  const handleMailClick = () => {
    window.location.href = `mailto:${data?.email}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${data?.phone}`;
  };

  const handleLocationClick = () => {
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${data?.location.line1}, ${data?.location.city}, ${data?.location.state}, ${data?.location.zipCode}`;
    window.open(mapUrl, "_blank");
  };

  /***************************************************************/

  const callToAction = [
    {
      action: "Call",
      icon: <BsFillTelephoneFill onClick={handlePhoneClick} />,
    },
    { action: "Email", icon: <MdEmail onClick={handleMailClick} /> },
    { action: "Staff", icon: <BsFillPersonFill /> },
  ];


  // Structured data for LocalBusiness schema for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": data?.businessName,
    "description": data?.biography,
    "image": `${Url}image/${data?.avatarImg}`,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": data?.location.line1,
      "addressLocality": data?.location.city,
      "addressRegion": data?.location.state,
      "postalCode": data?.location.zipCode
    },
    "telephone": data?.phone,
    "email": data?.email,
    "url": `https://subitt.io/CustomerStoreFront/${businessId}`
  };

  if(data === null){return <BusinessBannerSkelton />}
  else{
  return (
    <>
      <div className="p-businessBanner__container">
        <SEO
          title={`${data?.businessName} | ${data?.location.city}, ${data?.location.state}`}
          description={data?.biography}
          image={`${Url}images/${data?.bannerImg}`}
          url={`https://subitt.io/CustomerStoreFront/${businessId}`}
          structuredData={structuredData}
        />
        <Toaster />
        <img
          className="p-businessBanner__bannerImg"
          src={data?.bannerImg === "sample" ? EmptyBanner :`${Url}images/${data?.bannerImg}`}
          alt={`${data.businessName} banner image`}
        />
        <img
          className="p-businessBanner__avatarImg"
          src={data?.avatarImg === "sample" ? EmptyAvatar :`${Url}images/${data?.avatarImg}`}
          alt={`${data.businessName} avatar image`}
        />
      </div>
      <section className="p-businessInfo__container">
        <div className="p-businessInfo__inner">
          <h4 
            className="p-businessInfo__name" 
            onClick={()=>navigate(`/customer/customerStoreFront/${data?._id}`)}>
              {data?.businessName}
          </h4>
            <SocialMedias data={data} />
            <div className="p-businessInfo__contact">
              <div
                className="p-businessInfo__contact--options"
                onClick={handleLocationClick}
              >
                <MdLocationOn />
                <h6>
                  {data?.location?.line2 != undefined
                    ? `${data?.location?.line1} ${data?.location?.line2}, \n ${data?.location?.city}, ${data?.location?.state}, ${data?.location?.zipCode}`
                    : `${data?.location?.line1}, \n ${data?.location?.city}, ${data?.location?.state}, ${data?.location?.zipCode}`}
                </h6>
              </div>
              <div
                className="p-businessInfo__contact--options"
                onClick={handlePhoneClick}
              >
                <BsFillTelephoneFill />
                <h6>{formatPhoneNumber(data?.phone)}</h6>
              </div>
              <div
                className="p-businessInfo__contact--options"
                onClick={handleMailClick}
              >
                <MdEmail />
                <h6>{data?.email}</h6>
              </div>
              {/* <button onClick={onShare} className="share-button">
                {" "}
                {/*Connects with onShare function to creat message with page's url*/}
                {/* <FaShareAlt />
              </button>  */}
          </div>
            <p className="p-businessInfo__bio">{data?.biography}</p>
          </div>
        </section>
      </>
    );
  }
};

export default BusinessBannerInfo;
