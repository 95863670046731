/*******************************************************************************************************
 * Function/File Purpose: This file has all of routes for customer side * and it is imported in App.js.
 * Author: Aoi Kuriki
 * Date: 6/19/2023
 *
 * **********************************
 * Contributor:
 * Date:
 * What was done:
 * *******************************
 * Constributor:
 * Date:
 * What was done:
 * ********************************
 */

import React, { createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import {
  AccountDetail,
  CustomerStoreFront,
  HomePage,
  Login,
  MySubscription,
  ProductDetail,
  SearchResult,
  TimeAvailability,
  Feed,
} from "../Pages";
import {
  CurrentSubscriptions,
  Notifications,
  Profile,
  Security,
  ShippingInfo,
  StripeLink,
} from "../Pages/AccountDetail/Components";
import { getAccountById } from "../CustomerStateManagement/CustomerState";
import { Error } from "../../pages";
import ProtectedCustomerRoute from "./ProtectedCustomerRoute";
import CheckoutReview from "../Pages/CheckoutPreview/CheckoutPreview";
import CheckoutPreview from "../Pages/CheckoutPreview/CheckoutPreview";
import BusinessList from "../Pages/BusinessList/BusinessList";
import Chatbox from "../../GlobalComponents/ChatboxPage/Chatbox";
import History from "../Pages/MySubscription/Components/History";
import ResetPasswordFirst from "../Pages/LoginPage/Components/ResetPasswordFirst";
import NewPassword from "../Pages/LoginPage/Components/NewPassword";
import Wishlist from "../Pages/AccountDetail/Components/Wishlist";
import CustomerHeader from "../Components/CustomerHeader";
import SubscriptionFeed from "../Pages/Feed/Components/SubscriptionFeed";
import BusinessFeed from "../Pages/Feed/Components/BusinessFeed";
import UserReviewPage from "../Pages/AccountDetail/Components/UserReviewPage";
import { Footer, Header } from "../../GlobalComponents";
import AllCategories from "../Pages/Browse/AllCategories";
import Subcategories from "../Pages/Browse/Subcategories/Subcategories";
import CategoriesPage from "../Pages/Browse/Subcategories/CategoryPage";
import { isLoggedIn } from "../CustomerStateManagement/CustomerState";
import ItemDetail from "../Pages/ItemDetail/ItemDetail";

export const AccountContext = createContext();
export const FeedContext = createContext();
export const SearchContext = createContext();
export const ReviewContext = createContext();

const CustomerRoutes = () => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const [loggedIn, setLoggedIn] = useState();

  // Set if sidebar is extended or hidden
  const [sidebar, setSidebar] = useState(true); // Sidebar open = True, Sidebar closed = False

  // Check where user is and set the toggle based on that
  // True = subscriptions, false = businesses
  const [feedType, setFeedType] = useState(() => {
    if (location.search.includes("subscriptions")) {
      return true;
    } else if (location.search.includes("businesses")) {
      return false;
    } else if (location.pathname === "/feed") {
      return true;
    } else {
      return true;
    }
  });

  useEffect(() => {
    if (location.search.includes("?type=subscriptions")) {
      setFeedType(true);
    } else if (location.search.includes("?type=businesses")) {
      setFeedType(false);
    } else if (
      location.pathname === "/feed" ||
      location.pathname === "/browse" ||
      location.pathname === "/search"
    ) {
      setFeedType(true);
    }
  }, [location]);

  // Check if the user is loggedin
  useEffect(() => {
    const login = isLoggedIn();
    setLoggedIn(login);
  }, []);

  useEffect(() => {
    if (loggedIn === undefined) return; // Wait for the check above to see if user is logged in

    const fetchData = async () => {
      const res = await getAccountById();
      setUserInfo(res);
    };

    // If we're logged in, use their account data, if not, set default location to SLC
    if (loggedIn) {
      fetchData();
    } else {
      setUserInfo({
        addresses: [
          {
            line1: "Salt Lake City",
            geometry: {
              type: "Point",
              coordinates: [-111.891, 40.7608],
            },
          },
        ],
      });
    }
  }, [loggedIn]);

  return (
    <AccountContext.Provider value={[userInfo, setUserInfo]}>
      <FeedContext.Provider
        value={[feedType, setFeedType, sidebar, setSidebar]}
      >
        <CustomerHeader />
        <Routes>
          {/* <Route path="/home" element={<HomePage />} /> */}
          <Route path="/feed" element={<Feed />} />
          <Route path="/browse" element={<CategoriesPage />} />
          <Route path="/browseAll" element={<AllCategories />} />

          <Route
            path="/feed?type=subscriptions"
            element={<SubscriptionFeed />}
          />
          <Route path="/feed?type=businesses" element={<BusinessFeed />} />

          <Route
            exact
            path="/wishlist"
            element={
              <ProtectedCustomerRoute>
                <Wishlist />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/chatbox"
            element={
              <ProtectedCustomerRoute>
                <Chatbox />
              </ProtectedCustomerRoute>
            }
          />

          {/* <Route path="/sortBy/:category" element={<SearchResult />} /> */}
          <Route path="/search" element={<SearchResult />} />

          <Route exact path="/login" element={<Login />} />

          <Route
            exact
            path="/login/resetPassword"
            element={<ResetPasswordFirst />}
          />

          <Route
            exact
            path="/login/resetPassword/:accountId/:token"
            element={<NewPassword />}
          />

          <Route exact path="/business" element={<BusinessList />} />

          <Route
            exact
            path="/ProductDetail/:type/:businessId/:itemId"
            element={<ProductDetail />}
          />
          <Route
            exact
            path="/itemDetail/:type/:businessId/:itemId"
            element={<ItemDetail />}
          />

          <Route
            exact
            path="/CustomerStoreFront/:businessId"
            element={<CustomerStoreFront />}
          />
          <Route
            exact
            path="/CustomerStoreFront/:businessId"
            element={<CustomerStoreFront />}
          />

          <Route
            exact
            path="/user"
            element={
              <ProtectedCustomerRoute>
                <AccountDetail />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/reviews"
            element={
              <ProtectedCustomerRoute>
                <UserReviewPage />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/user/profile"
            element={
              <ProtectedCustomerRoute>
                <Profile />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/user/security"
            element={
              <ProtectedCustomerRoute>
                <Security />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/user/notifications"
            element={
              <ProtectedCustomerRoute>
                <Notifications />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            path="/user/currentsubscriptions"
            element={
              <ProtectedCustomerRoute>
                <CurrentSubscriptions />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            path="/subscriptionsHistory"
            element={
              <ProtectedCustomerRoute>
                <History />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            path="/user/customerStripeRedirect"
            element={
              <ProtectedCustomerRoute>
                <StripeLink />
              </ProtectedCustomerRoute>
            }
          />
          <Route
            path="/user/shippingInfo"
            element={
              <ProtectedCustomerRoute>
                <ShippingInfo />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/mySubscriptions"
            element={
              <ProtectedCustomerRoute path="/mySubscriptions">
                <MySubscription />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/TimeAvailability/:businessId/:itemId"
            element={
              <ProtectedCustomerRoute path="/feed">
                <TimeAvailability />
              </ProtectedCustomerRoute>
            }
          />

          <Route
            exact
            path="/checkoutPreview" //add id here later
            element={
              <ProtectedCustomerRoute>
                <CheckoutPreview />
              </ProtectedCustomerRoute>
            }
          />

          {/* Default Error case */}
          <Route path="*" element={<Error />} />
        </Routes>
      </FeedContext.Provider>
    </AccountContext.Provider>
  );
};

export default CustomerRoutes;
