import axios from "axios";
const Url = require("../../backendURL");

export const sendSupportTicket = async(body) => {
  try{
    const res = await axios.post(`${Url}admin/support`,body)
    if(res.status === 200){
      return true
    }

  }catch(err){
    console.log(err)
    return false
  }
}