import React from "react"
import ContentLoader from "react-content-loader"

const ItemTemplateSkelton = (props) => (
  <ContentLoader 
    speed={2}
    width={1500}
    height={470}
    viewBox="0 0 1500 470"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="207" height="150" /> 
    <rect x="0" y="320" rx="0" ry="0" width="207" height="150" /> 
    <rect x="0" y="160" rx="0" ry="0" width="207" height="150" /> 
    <rect x="240" y="0" rx="0" ry="0" width="600" height="470" /> 
    <rect x="870" y="0" rx="16" ry="16" width="380" height="385" />
  </ContentLoader>
)

export default ItemTemplateSkelton
