import React from "react";

export default function CurrentSubscriptions() {
  return (
    <div className="userCurrentSubscriptionsContainer">
      <div className="userCurrentSubscriptionFilter divider">
        <input
          type="text"
          name="subscriptionSearch"
          placeholder="Search Current Subscriptions"
        ></input>
        <input
          id="subscriptionSort-subType"
          type="checkbox"
          value="subscriptionType"
        ></input>
        <label for="subscriptionSort-subType">Subscription Type</label>
        <input
          id="subscriptionSort-lowToHigh"
          type="checkbox"
          value="lowToHigh"
        ></input>
        <label for="subscriptionSort-lowToHigh">Price - Low to High</label>
        <input
          id="subscriptionSort-highToLow"
          type="checkbox"
          value="highToLow"
        ></input>
        <label for="subscriptionSort-highToLow">Price - High to Low</label>
      </div>
      {/* SUBSCRIPTION INFO SECTION */}
      <div className="userCurrentSubscriptionCard divider">
        <p>Item Price: $50.00</p>
        <p>Subscription: Monthly</p>
        <p>Shipping: $5.00</p>
        <p>Taxes and Fee: $6.00</p>
        <p>Payment Method: Stripe</p>
        <p>Address of Fulfillment: 123 Mama Mo Rexburg Patatas</p>
        <button type="button">Cancel</button>
        <button type="button">Edit</button>
      </div>
    </div>
  );
}
