import React from "react";


const ContactHome = () => {


  return (

    <section className='p-contact__container' style={{position:"relative"}}>
      <div className='p-contact__inner'>
        <h4 className='p-contact__title'>Want to Know More?</h4>
        <p className='p-contact__text'>Reach out to our team now!</p>
        <h6><b>email:</b> nick@subitt.io</h6>
        <div style={{display:"flex",flexDirection:"row",position:"absolute",right:0,bottom:0,gap:24,padding:16}}>
          <a href="https://www.subitt.io/footer/privacy-policy" style={{cursor:"pointer"} }>Privacy Policy</a>
          <a href="https://www.subitt.io/footer/terms-of-use" style={{cursor:"pointer"} }>Terms of Use</a>
        </div>

      </div>
    </section>
  );
};

export default React.memo(ContactHome);
