// Stephen: Created chatbox under global components

import Conversations from "./Conversations";
import Messages from "./Messages";
import SendMessage from "./SendMessage";
import { ChatboxContextProvider } from "./ChatboxContext";

export default function Chatbox() {

    return (
        <>
            <main className="p-chatbox">
                <ChatboxContextProvider>
                    <Conversations/>
                    <Messages/>
                    <SendMessage/>      
                </ChatboxContextProvider>      
            </main>
        </>
    )
}