import React, { useState, useEffect, useContext } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { DateContext } from "./Calendar/Calendar";
import { DateContextAvailability } from "../../../Business_Module/Pages/AvailabilityPage/Component/AvailabilityPage";
import { convertNumberToTwoDigitFormat } from "../../../Customer_Module/utilities/global_function";

function SmallCalendar(props) {
  let currentDate = new Date();

  const { d, setD } = useContext((DateContext._currentValue != undefined ? DateContext : DateContextAvailability));

  // let date = d
  let currentMonth = d.getMonth();
  let currentYear = d.getFullYear();
  let daysInMonth;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  
  let dateForAppointment = new Date()

  function handleDateSelected(e) {
    e.preventDefault();
    const day = +e.target.textContent
    const dateObject = new Date(Date.UTC(currentYear, d.getMonth(), day+1)); 
    props.setDateSelected(dateObject)
  }

  // const [month, setMonth] = useState(currentMonth)

  const [year, setYear] = useState(currentYear);
  const [spotData, setSpotData] = useState(props.spotData)

  // This function gets the number of days in the month so we can know how many times we need to loop to displays
  // the days in the days class
  function getDaysInMonth(month, year) {
    let d = new Date(year, month + 1, 0);
    daysInMonth = d.getDate();
  }

  getDaysInMonth(d.getMonth(), d.getFullYear());

  // Handle clicking on a day of the calendar


  // This function will loop through the number of days and add a <div></div> with the day number inside
  // the days class

  useEffect(() => {
    setSpotData(props.spotData)
  },[props.spotData])
  
  useEffect(() => {
    let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
    document.querySelector(".days").innerHTML = "";
    // This will first loop through the days of the week that are empty
    // for example if the first day of the month is Thursday, this will run
    // 3 times and add an empty <div>
    for (let j = 0; j < firstDay.getDay(); j++) {
      document.querySelector(".days").innerHTML += `<div></div>`;
    }

    // After we're done filling the empty days, this will run and fill the numbers
    // in the days

    let dayNumber = document.querySelector(".days")
    for (let i = 0; i < daysInMonth; i++) {

    
      if (i + 1 == currentDate.getDate() && d.getMonth() == currentDate.getMonth()) {
        let div = document.createElement("div") 

        try {
          if((Object.keys(spotData)).includes(`${d.getMonth()}/${i+1}/${d.getFullYear()}`) ) {
            div.style.backgroundColor = '##bcffb5';
            
          }
        } catch {
          console.log("error")
        }
        div.innerHTML = i+1
        div.addEventListener("click", handleDateSelected)
        dayNumber.appendChild(div)
      } else {
       
        let div2 = document.createElement("div")        
        div2.innerHTML = i+1

        try {
          if((Object.keys(spotData)).includes(`${d.getMonth()+1}/${i+1}/${d.getFullYear()}`)) {
            div2.style.backgroundColor = '#bcffb5';
            div2.style.borderRadius = '5px'
            
          }
        } catch {
          console.log("error")
        }

        div2.addEventListener("click", handleDateSelected)
        dayNumber.appendChild(div2)
        
      }
  
      
    }


  }, [d.getMonth()]);

  function addtoMonth() {
    d.setMonth(d.getMonth() + 1);
    setD(new Date(d));
    document.querySelector(".days").innerHTML = "";
  }
  // Every time we're changing the month to display, this function will also
  // clean the days div

  function substractMonth() {
    d.setMonth(d.getMonth() - 1);
    setD(new Date(d));
    document.querySelector(".days").innerHTML = "";
  }
 
  return (
    <div className="calendarSmall">
      <div className="month">
        <div className="calendarArrow" onClick={substractMonth}>
          <MdArrowBackIos />
        </div>
        <div className="monthName">
          {months[d.getMonth()] + " " + d.getFullYear()}
        </div>
        <div className="calendarArrow" onClick={addtoMonth}>
          <MdArrowForwardIos />
        </div>
      </div>
      <div className="daysWeek">
        <span>Sun</span>
        <span>Mon</span>
        <span>Tue</span>
        <span>Wed</span>
        <span>Thur</span>
        <span>Fri</span>
        <span>Sat</span>
      </div>
      <div className="days"></div>
    </div>
  );
}

export default SmallCalendar;
