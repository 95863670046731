import React from 'react'
import { useNavigate } from 'react-router-dom'
import EmptyIMG from "../../../../Assets/subittBanner.jpg"

const BusinessCard = ({business}) => {
  const navigate = useNavigate()
  const Url = require("../../../../backendURL")
  return (
        <div className='p-newSellers__card' onClick={()=>navigate(`customerStoreFront/${business?._id}`)}>
          <div className='p-newSellers__card__img'>
            <img className='p-newSellers__card__img--banner' src={business?.bannerImg === "sample" ? EmptyIMG :`${Url}images/${business?.bannerImg}`} alt='example' />
            <div className='p-newSellers__card__img--avatarHolder'>
              <img className='p-newSellers__card__img--avatar' src={business?.avatarImg === "sample" ? EmptyIMG :`${Url}images/${business?.avatarImg}`} alt='example' />
            </div>
          </div>
          <h4 className='p-newSellers__card__businessName'>{business?.businessName}</h4>
          <p className='p-newSellers__card__paragraph'>{business?.biography}</p>
        </div>
  )
}

export default BusinessCard