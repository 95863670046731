import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  AddressAutofill,
  AddressMinimap,
  useConfirmAddress,
} from "@mapbox/search-js-react";
import {
  addLocation,
  setPrimaryAddress,
} from "../CustomerStateManagement/CustomerState";
import { AccountContext } from "../Routes/CustomerRoutes";
import { ShippingInfo } from "../Pages/AccountDetail/Components";
import { isLoggedIn } from "../CustomerStateManagement/CustomerState";
import useUserLocation from "../utilities/getLocation";

const AddressChangePopup = ({ closePopup }) => {
  const [userInfo, setUserInfo] = useContext(AccountContext); // Get current data
  const [manualAddress, setManualAddress] = useState(false); // When the "input manually button is pressed"
  const [showUpdateButton, setShowUpdateButton] = useState(false); // Only shows submit/update button when autofill is completed
  const [showMap, setShowMap] = useState(false); // Show the minimap or not, currently shows when autofill is selected
  const [loggedIn, setLoggedIn] = useState(false);
  const [primaryToggle, setPrimaryToggle] = useState(true);
  const currentLocation = useUserLocation();

  const [address, setAddress] = useState({
    line1: userInfo?.address?.line1 || "",
    line2: userInfo?.address?.line2 || "",
    city: userInfo?.address?.city || "",
    state: userInfo?.address?.state || "",
    postal_code: userInfo?.address?.postal_code || "",
    country: "US",
    coordinates: userInfo?.address?.coordinates || [],
    geometry: {
      type: "Point",
      coordinates: [
        userInfo?.address?.geometry?.coordinates[0] || 0,
        userInfo?.address?.geometry?.coordinates[1] || 0,
      ],
    },
  });

  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_EXPO_MAPBOX_PUBLIC_KEY;

  const [minimapFeature, setMinimapFeature] = useState();
  const { formRef } = useConfirmAddress({
    accessToken: MAPBOX_ACCESS_TOKEN,
  });

  useEffect(() => {
    let login = isLoggedIn();
    if (login != null) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const toggleManualAddress = () => {
    setManualAddress((prevState) => !prevState);
  };

  // Get the autofill and set the minimap feature/address
  const handleAutofillRetrieve = (response) => {
    const { geometry, properties } = response.features[0];
    console.log(properties);

    // Update address with the retrieved coordinates and address details
    setAddress((prevAddress) => ({
      ...prevAddress,
      line1: properties.address_line1,
      line2: properties.address_line2,
      city: properties.place,
      state: properties.region,
      postal_code: properties.postcode,
      coordinates: {
        lon: geometry.coordinates[0],
        lat: geometry.coordinates[1],
      },
      geometry: {
        type: "Point",
        coordinates: [geometry.coordinates[0], geometry.coordinates[1]],
      },
    }));

    // Set address for minimap using the response of the autofill
    setMinimapFeature(response.features[0]);
    setShowMap(true);
    setShowUpdateButton(true);
  };

  // Submit the form
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update user info in context and check if it's already in teh users addresses
      const existingIndex = userInfo.addresses.findIndex(
        (addr) =>
          addr.line1 === address?.line1 &&
          addr.city === address?.city &&
          addr.state === address?.state &&
          addr.postal_code === address?.postal_code &&
          addr.country === address?.country,
      );

      if (existingIndex !== -1) {
        // Address already exists, set it as primary
        await setPrimaryAddress(existingIndex);
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          addresses: [
            {
              line1: address?.line1,
              line2: address?.line2,
              city: address?.city,
              state: address?.state,
              postal_code: address?.postal_code,
              country: address?.country,
              coordinates: {
                lon: address?.coordinates?.lon,
                lat: address?.coordinates?.lat,
              },
              geometry: {
                type: "Point",
                coordinates: [
                  address.geometry.coordinates[0],
                  address.geometry.coordinates[1],
                ],
              },
            },
            ...prevUserInfo.addresses,
          ],
        }));
      } else {
        // Address does not exist, add it to the beginning
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          addresses: [
            {
              line1: address.line1,
              line2: address.line2,
              city: address.city,
              state: address.state,
              postal_code: address.postal_code,
              country: address.country,
              coordinates: {
                lon: address.coordinates.lon,
                lat: address.coordinates.lat,
              },
              geometry: {
                type: "Point",
                coordinates: [
                  address.geometry.coordinates[0],
                  address.geometry.coordinates[1],
                ],
              },
            },
            ...prevUserInfo.addresses,
          ],
        }));

        // Add the new address to the database
        if (loggedIn) {
          await addLocation(address);
          {
            primaryToggle &&
              (await setPrimaryAddress(userInfo?.addresses?.length)); // Set it to the length of the addresses because that's where it'll be on the backend
          }
        }
      }

      // Optionally close the popup after updating
      closePopup(false);

      // window.location.reload(); // Currently won't update products unless it reloads
    } catch (err) {
      console.error(err);
    }
  };

  const handlePrimaryToggle = () => {
    setPrimaryToggle((prevState) => !prevState);
  };

  return (
    <div 
      onClick={() => closePopup(false)}
      className="overlay"
    >
      <form 
        className="c-addressForm" 
        ref={formRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="c-addressForm__inputs">
          {/* Change whether or not they are inputting manually or with autofill*/}
          {!manualAddress ? (
            <>
              {/* address-line1 with search icon */}
              <AddressAutofill
                accessToken={MAPBOX_ACCESS_TOKEN}
                onRetrieve={handleAutofillRetrieve}
              >
                <h6>Enter Your Address</h6>
                <div>
                  <input
                    autoComplete="address-line1"
                    name="address-line1"
                    required
                  />
                  <div style={{ display: "none" }}>
                    <label className="c-input__label">
                      Address Line 2 (Optional)
                    </label>
                    <input autoComplete="address-line2" name="address-line2" />
                  </div>
                  <div style={{ display: "none" }}>
                    <label className="c-input__label">City</label>
                    <input
                      autoComplete="address-level2"
                      name="address-level2"
                      required
                    />
                  </div>
                  <div style={{ display: "none" }}>
                    <label className="c-input__label">State / Region</label>
                    <input
                      autoComplete="address-level1"
                      name="address-level1"
                      required
                    />
                  </div>
                  <div style={{ display: "none" }}>
                    <label className="c-input__label">ZIP / Postcode</label>
                    <input
                      className="c-input"
                      autoComplete="postal-code"
                      name="postal-code"
                      required
                    />
                  </div>
                </div>
              </AddressAutofill>

              {/* Add apt or suite # for people */}
              {showMap && (
                <div className="c-addressForm__inputs">
                  <label>Apartment or Suite #</label>
                  <input
                    autoComplete="address-line2"
                    name="address-line2"
                    value={address.line2}
                    onChange={(e) =>
                      setAddress({ ...address, line2: e.target.value })
                    }
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <ShippingInfo />
            </>
          )}

          {/* Minimap shows by changing height when showMap is true */}
          <div
            id="minimap-container"
            className="c-addressForm__minimap"
            style={{ height: showMap ? "200px" : "0px" }}
          >
            <AddressMinimap
              accessToken={MAPBOX_ACCESS_TOKEN}
              feature={minimapFeature}
              show={minimapFeature}
              canAdjustMarker
              footer=""
            />
          </div>

          {!manualAddress && (
            <div className="c-addressForm__buttons">
              {/* Update button */}
              {showUpdateButton && (
                <>
                  <div
                    className="c-addressForm__button"
                    style={{ marginTop: showMap ? "10px" : "0px" }}
                    onClick={(e) => handleFormSubmit(e)}
                  >
                    Update Address
                  </div>

                  {loggedIn && (
                    <>
                      <div className="c-form c-recommendationForm">
                        <span className="c-recommendationForm__contact">
                          <input
                            name="contact"
                            type="checkbox"
                            checked={primaryToggle}
                            onChange={handlePrimaryToggle}
                            style={{ outline: "none" }} // Remove default styling not hit by form stuff
                          />
                          <label>Set as primary address?</label>
                        </span>
                      </div>
                    </>
                  )}
                </>
              )}

              {/* Enter manually */}
              {!showMap && (
                <div
                  className="c-addressForm__button"
                  onClick={toggleManualAddress}
                >
                  Enter manually
                </div>
              )}
            </div>
          )}
        </div>

        {/* {!showMap && !manualAddress && userInfo?.address?.line1 !== "" ? (
          <div className="c-addressForm__current">
            <p className="c-addressForm__current__title">Current Address:</p>
            <div className="c-addressForm__current__line1">
              <p>
                {userInfo?.address?.line1}{" "}
                {userInfo?.address?.line2 && `#${userInfo?.address?.line2}`}
              </p>
              <p></p>
            </div>
            <div className="c-addressForm__current__line2">
              <p>
                {userInfo?.address?.city}, {userInfo?.address?.state}{" "}
                {userInfo?.address?.postal_code}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )} */}
      </form>
    </div>
  );
};

export default AddressChangePopup;

{
  /* address-line2 */
}
{
  /* <label>
          Apartment, suite, etc. (optional)
          <input
            autoComplete="address-line2"
            name="address-line2"
            value={address.line2}
            onChange={(e) => setAddress({ ...address, line2: e.target.value })}
          />
        </label> */
}

{
  /* address-level2, address-level1, postal-code */
}
{
  /* <div>
          <div>
            <label>
              City
              <input
                autoComplete="address-level2"
                name="address-level2"
                value={address.city}
                onChange={(e) =>
                  setAddress({ ...address, city: e.target.value })
                }
                required
              />
            </label>
          </div>
          <div>
            <label>
              State / Region
              <input
                autoComplete="address-level1"
                name="address-level1"
                value={address.state}
                onChange={(e) =>
                  setAddress({ ...address, state: e.target.value })
                }
                required
              />
            </label>
          </div>
          <div>
            <label>
              ZIP / Postcode
              <input
                className="input"
                autoComplete="postal-code"
                name="postal-code"
                value={address.postal_code}
                onChange={(e) =>
                  setAddress({ ...address, postal_code: e.target.value })
                }
                required
              />
            </label>
          </div>
        </div> */
}
