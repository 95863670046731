/*******************************************************************************************************
 * Function/File Purpose: This file contains a function call for the Haversine Formula
 * Author: Isaac Radford
 * Date: Jan. 16th, 2025
 *
 * Details: Using the coordinates / longitude and latitude of two separate locations,
 * this function finds the distance between the two of those two points.
 */

export function calculateDistance(coord1, coord2) {
  const toRadians = (degree) => (degree * Math.PI) / 180;

  const [lon1, lat1] = coord1;
  const [lon2, lat2] = coord2;

  const R = 3963.1; // Earth's radius in Miles
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in miles
}
