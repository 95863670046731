import React from "react"
import ContentLoader from "react-content-loader"

const BusinessBannerSkelton = (props) => (
  <ContentLoader 
    speed={2}
    width={1500}
    height={460}
    viewBox="0 0 1500 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="750" y="272" rx="5" ry="5" width="150" height="14" /> 
    <rect x="740" y="485" rx="13" ry="13" width="140" height="30" /> 
    <rect x="650" y="485" rx="13" ry="13" width="60" height="30" /> 
    <rect x="0" y="0" rx="0" ry="0" width="1500" height="375" /> 
    <circle cx="263" cy="363" r="113" /> 
    <rect x="440" y="400" rx="5" ry="5" width="150" height="36" /> 
    <rect x="760" y="446" rx="5" ry="5" width="150" height="22" /> 
    <rect x="600" y="446" rx="5" ry="5" width="150" height="22" /> 
    <rect x="440" y="446" rx="5" ry="5" width="150" height="22" /> 
    <rect x="400" y="475" rx="5" ry="5" width="410" height="40" />
  </ContentLoader>
)

export default BusinessBannerSkelton
