import React, {useState, useRef} from 'react'
import toast from 'react-hot-toast';
import ReactCrop from 'react-image-crop';

import {FaRegCircle, FaCircle, FaRegSquare, FaSquare} from 'react-icons/fa';

import useImageUpload from './ImageUploads'

const Url = require('../../../backendURL');

const ImageUploader = ({ image, setImage, uploadImage }) => {
  const {handleImgChange, uploadImages} = useImageUpload();
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 50, height: 50, x: 25, y: 25, aspect: 1});
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [uploading, setUploading] = useState(false);

  const [circle, setCircle] = useState(false);
  const imageRef = useRef();

  const resizeImageToFitCropBox = (file, cropWidth, cropHeight, callback) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');

        const aspectRatio = img.width / img.height;
        const cropAspectRatio = cropWidth / cropHeight;

        let targetWidth, targetHeight;

        if (aspectRatio > cropAspectRatio) {
            targetHeight = cropHeight;
            targetWidth = targetHeight * aspectRatio;
        } else {
            targetWidth = cropWidth;
            targetHeight = targetWidth / aspectRatio;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, { type : file.type });
            callback(resizedFile);
        }, "image/png");

        URL.revokeObjectURL(objectUrl)
    };

    img.src = objectUrl;
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('File received', e.target.files[0]);

    if (!file) return;

    resizeImageToFitCropBox(file, 300, 300, (resizedFile) => {
        setSelectedFile(resizedFile);
        const objectUrl = URL.createObjectURL(resizedFile);
        setImageUrl(objectUrl);
        setIsModalOpen(true);
    });
  };

  const handleExit = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setImageUrl(null);
    setCompletedCrop(null);
    setCrop({ aspect: 1 });
    setCircle(false);
  };

  const cropImage = async () => {
    if (!completedCrop || !imageRef.current) {
        toast.error("Please complete cropping before uploading.");
        return null;
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

    let diameter = null;
    if (circle === true) {
      diameter = Math.min(completedCrop.width, completedCrop.height);
      canvas.width = diameter;
      canvas.height = diameter;
    } else {
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
    }

    ctx.drawImage(
      imageRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      circle ? diameter : completedCrop.width,
      circle ? diameter : completedCrop.height
    );

    if (circle === true) {
        const radius = diameter / 2;
    
        ctx.globalCompositeOperation = "destination-in"; 
        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
  
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "transparent";
        ctx.fillRect(0,0, canvas.width, canvas.height)
    }

    return new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const croppedFile = new File([blob], selectedFile.name, {
                type: selectedFile.type,
              });
              resolve(croppedFile);
            } else {
              resolve(null);
            }
          },
          "image/png",
          0.9
        );
      });  
    }

    const handleUpload = async () => {
        if (isProcessing) return; 
        setIsProcessing(true);
        try {
          const croppedFile = await cropImage();
    
          if (!croppedFile) {
            throw new Error("Failed to crop the image.");
          }

          console.log('handling the image change')
          await handleImgChange([croppedFile], 0);

          console.log('Uploading images')

          /* try {
            const uploadedImages = await uploadImages(Url + "images/upload"); //url to image
            
            console.log('just uploaded the images', uploadedImages);
            if (uploadedImages.length > 0) {
                //const imageUrl = uploadedImages[0];
                setImage(uploadedImages);
    
                console.log('Right before trycatch')

                try {
                    await uploadImage()

                    toast.success("Image cropped succesfully")
                } catch (error) {
                    console.error('Error in uploadImage', error)
                    toast.error('Error in uploading')
                }
          }} 
          catch (error) {
                console.error('Error with uploadedImages', error)
                toast.error("Error in uploading")
          }}
           catch (error) {
            console.error('Error with cropping')
            toast.error("Error in uploading")
          } finally {
            setIsProcessing(false)
          }
        } */

          try {
            const uploadedImages = await uploadImages(Url + "images/upload"); //url to image
            console.log('just uploaded the images', uploadedImages.length)
            if (uploadedImages.length > 0) {
                //const imageUrl = uploadedImages[0];
                setImage(uploadedImages);

                console.log('Right before trycatch')
                try {
                  await uploadImage();
                  console.log('Upload image called')
                } catch (err) {
                 console.log("BROKEN RIP")
                }

            toast.success("Image cropped successfully!");
            handleExit();
          } 
          } catch (error) {
            console.error("Error during upload:", error)
            toast.error("ERROR IN SECOND")
          }
         
          
        } catch (err) {
          console.error("Error during upload:", err);
          toast.error("An error occurred during the upload process.");
        } finally {
          setIsProcessing(false);
        }  
  }; 
  
  const uploadCroppedImage = async () => {
    console.log('in upload cropped image')
    try {
        const uploadedImages = await uploadImage(Url + "images/upload");
        console.log('UploadedImages url', uploadedImages)

        if (uploadedImages.length > 0) {
            console.log('Setting image')
            setImage(uploadedImages)
        }
     } catch (err) {
        console.error("error during upload", err)
        toast.error("An error occurred during the upload process.")
      } finally {
      await uploadImage();
      setIsProcessing(false);
    }
  }

    const handleClick = () => {
    setUploading(true);
  }
    
      return (
        <div>
          {uploading ? (
            <>
               <label htmlFor="file-upload">
               <div onClick={handleClick}>Choose Image</div>
              </label>
            </>
          ) : (
            <>
              {!selectedFile && (
                <label htmlFor="file-upload">
                  Choose Image
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                </label>
              )}
        
              {isModalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <div className="crop-container">
                      <button type="button" onClick={handleExit} className='crop-button'>
                        Cancel
                      </button>
                      <ReactCrop
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={1}
                        circularCrop={circle} 
                      >
                        <img ref={imageRef} src={imageUrl} alt="Crop preview" />
                        </ReactCrop>
                        
                      <div className="crop-shapeButtons">
                        <button onClick={() => setCircle(true)}>{circle ? <FaCircle /> : <FaRegCircle />}</button>
                        <button onClick={() => setCircle(false)}>{circle ? <FaRegSquare /> : <FaSquare />}</button>
                      </div>
        
                      <button type="button" onClick={handleUpload} disabled={isProcessing} className="crop-button">
                        {isProcessing ? "Processing..." : "Crop Image"}
                      </button>
                    </div>
                  </div>
                </div>
            )}
          </>
          )}
         
        </div>
      );
    };

export default ImageUploader