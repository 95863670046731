import React, { useEffect, useState, useContext } from "react";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import { AccountContext } from "../../../Routes/CustomerRoutes";
import Rating from "@mui/material/Rating";
import { getReviewsByUserId } from "../../../CustomerStateManagement/ReviewState";
import { getMembershipById, getProductsById, getServiceById } from "../../../CustomerStateManagement/Item";
import UserCard from "./UserCard";

const UserReviewsPage = () => {
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [visibleReviews, setVisibleReviews] = useState(3); // Number of reviews to show initially
  const [isLoading, setIsLoading] = useState(true);

  const userInfo = useContext(AccountContext);

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      if (userInfo && userInfo[0]) {
        const data = await getReviewsByUserId(userInfo[0]._id);
        setReviews(data);

        const productPromises = data.map(async (review) => {
          const productData = await getProductsById(review.itemId);
          if (productData.length === 0) {
            const membershipData = await getMembershipById(review.itemId);
            if (membershipData.length === 0) {
              const serviceData = await getServiceById(review.itemId);
              return { itemId: review.itemId, ...serviceData };
            } else {
              return { itemId: review.itemId, ...membershipData };
            }
          } else {
            return { itemId: review.itemId, ...productData };
          }
        });

        const productsData = await Promise.all(productPromises);
        setIsLoading(false);
        setProducts(productsData);
      }
    };

    fetchReviews();
  }, [userInfo]);

  // Function to load more reviews
  const loadMoreReviews = () => {
    setVisibleReviews((prev) => prev + visibleReviews); // Increase visible reviews by 5
  };

  return (
    <div className="userDetailsContainer l-inner">
      <UserCard data={userInfo}/>
      <div className="p-userReviewPage">
        <div className="reviewSummary">
          {reviews.length ? (
            reviews.slice(0, visibleReviews).map((review) => {
              const product = products.find((prod) => prod.itemId === review.itemId);

              return (
                <div key={review._id} className="reviewMargining">
                  {product ? (
                    <Link className="reviewLinking" to={`/productDetail/${product.itemType}/${product.business}/${review.itemId}`}>
                      <Card  className="m-padding--24">
                        <h3 className="reviewProductName">{product.name}</h3>
                          <div className="reviewRateFloating">
                            <div className="c-starRating" >
                              <Rating
                                value={review.rating}
                                readOnly
                                precision={0.5}
                                sx={{
                                  "& .MuiRating-iconFilled": {
                                    fontSize: "16px",
                                  },
                                  "& .MuiRating-iconEmpty": {
                                    fontSize: "16px",
                                  },
                                  "& .MuiRating-iconHover": {
                                    fontSize: "16px",
                                  },
                                }}
                                className="rating"
                              />
                            </div>
                          </div>
                          <h5 className="reviewTitlePadding" >{review.title}</h5>
                        <p className="reviewBodyBreaking" >{review.body}</p>
                        <p>{new Date(review.date).toLocaleDateString()}</p>
                      </Card>
                    </Link>
                  ) : (
                    <div className= "c-skeletonBox" key={visibleReviews}/>
                  )}
                </div>
              );
            })
          ) : (
            <p>No reviews found.</p>
          )}
        </div>
        {/* Load More Button */}
        {visibleReviews < reviews.length && (
          <div className="buttonAligning">
            <button onClick={loadMoreReviews} className="u-Btn--orange buttonMargining">
              {isLoading ? "Loading Reviews..." : "Load more"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserReviewsPage;
