/**
 * Author: Aoi Kuriki
 * Date: 3/12/2024
 * Add this component in pages where you want to change meta data
 * 
 */

import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description, image, url, structuredData }) => {
  return (
    <Helmet>
    { /* Standard metadata tags */ }
    <title>{title} | Subitt</title>
    <meta name='description' content={description} />
    <link rel="canonical" href={url} />
    { /* End standard metadata tags */ }

    { /* Facebook tags */ }
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    { /* End Facebook tags */ }

    { /* Twitter tags */ }
    <meta name="twitter:creator" content="Subitt" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    { /* End Twitter tags */ }

    {/* Structured Data */}
    {structuredData && (
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    )}
    </Helmet>
  )
}

export default SEO