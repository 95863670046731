import React from "react";
import Error from "../../Assets/404Error.png";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <section className="l-inner">
      <div className="errorContainer">
        <h1 className="c-borderText">404 Error</h1>
        <div className="errorRight">
          <h3>
            Thanks for exploring our website! We're sorry but this page is not
            available or is still under development.<br></br>Check back in again
            later!
          </h3>
          <button className="c-smallButton" onClick={() => navigate(-1)}>
            Go Back
          </button>
        </div>
      </div>
    </section>
  );
};

export default Error404;
