/*******************************************************************************************************
 * Function/File Purpose: Display category icons which navigates user to SearchList Page or just change information
 * Author: Aoi Kuriki
 * Date: 6/26/2023
 *
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * *******************************
 */

import React, { useContext } from "react";
import { navigateLink } from "../utilities/global_function";
import { useNavigate } from "react-router-dom";
import { IsMobile } from "../utilities/IsMobile";
import {
  IoBalloonOutline,
  IoBarbellOutline,
  IoBicycleOutline,
  IoBodyOutline,
  IoCarOutline,
  IoCashOutline,
  IoCodeWorkingOutline,
  IoConstructOutline,
  IoCutOutline,
  IoFitnessOutline,
  IoHomeOutline,
  IoLeafOutline,
  IoPawOutline,
  IoSchoolOutline,
  IoTrendingUpSharp,
  IoGolfOutline,
  IoHammerOutline,
  IoCarSportOutline,
} from "react-icons/io5";
import { TbStarsFilled } from "react-icons/tb";
import { MdOutlineCoffee, MdOutlineCookie } from "react-icons/md";
import { LuCroissant } from "react-icons/lu";
import { BiCoffee, BiCategory } from "react-icons/bi";
import { FeedContext } from "../Routes/CustomerRoutes";

const CategoryList = () => {
  const navigate = useNavigate();

  const isMobile = IsMobile();

  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

  // Category setUp
  const categories = [
    // {
    //   icon: BiCategory,
    //   displayName: "Browse",
    //   navigation: "browseAll",
    // },
    // {
    //   icon: IoTrendingUpSharp,
    //   displayName: "Trending",
    //   navigation: "browse?category=trending",
    // },
    // {
    //   icon: TbStarsFilled,
    //   displayName: "Popular",
    //   navigation: "browse?category=popular",
    // },
    {
      icon: BiCoffee,
      displayName: "Coffee",
      navigation: "browse?category=coffee",
    },
    {
      icon: MdOutlineCookie,
      displayName: "Sweets",
      navigation: "browse?category=sweets",
    },
    {
      icon: LuCroissant,
      displayName: "Bakeries",
      navigation: "browse?category=bakeries",
    },
    {
      icon: IoCarSportOutline,
      displayName: "Auto",
      navigation: "browse?category=auto",
    },
    {
      icon: IoGolfOutline,
      displayName: "Sports",
      navigation: "browse?category=sports",
    },
    {
      icon: IoBalloonOutline,
      displayName: "Baby",
      navigation: "browse?category=baby",
    },
    {
      icon: IoCutOutline,
      displayName: "Beauty",
      navigation: "browse?category=beauty",
    },
    {
      icon: IoConstructOutline,
      displayName: "Cleaning",
      navigation: "browse?category=cleaning",
    },
    {
      icon: IoBodyOutline,
      displayName: "Childcare",
      navigation: "browse?category=childcare",
    },
    {
      icon: IoHammerOutline,
      displayName: "Maintenance",
      navigation: "browse?category=maintenance",
    },
    {
      icon: IoSchoolOutline,
      displayName: "Education",
      navigation: "browse?category=education",
    },
    {
      icon: IoBarbellOutline,
      displayName: "Fitness",
      navigation: "browse?category=fitness",
    },
    {
      icon: IoFitnessOutline,
      displayName: "Healthcare",
      navigation: "browse?category=healthcare",
    },
    {
      icon: IoHomeOutline,
      displayName: "Home",
      navigation: "browse?category=home",
    },
    {
      icon: IoLeafOutline,
      displayName: "Landscaping",
      navigation: "browse?category=landscaping",
    },
    {
      icon: IoCashOutline,
      displayName: "Money",
      navigation: "browse?category=money",
    },
    {
      icon: IoBicycleOutline,
      displayName: "Outdoor",
      navigation: "browse?category=outdoor",
    },
    {
      icon: IoPawOutline,
      displayName: "Pet",
      navigation: "browse?category=pet",
    },
    {
      icon: IoCodeWorkingOutline,
      displayName: "Tech",
      navigation: "browse?category=tech",
    },
  ];

  return (
    <>
      {categories.map((option, index) => (
        <div className="category__card" key={index}>
          <div className="category__card__background"></div>
          <div
            className="category__card__icon"
            onClick={() => navigateLink(`${option.navigation}`, navigate)}
          >
            <option.icon
              style={{
                fontSize: isMobile ? "20px" : "26px",
                // color: "#000000",
              }}
            />
            <p className="category__card__name">{option.displayName}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default CategoryList;
