import React, { useContext } from "react";
import SearchBusinessCard from "./SearchBusinessCard";
import { FeedContext } from "../../../Routes/CustomerRoutes";

const Url = require("../../../../backendURL");

const BusinessSearchResult = ({ businesses }) => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

  return (
    <>
      {businesses?.length > 0 ? (
        <div
          className="p-searchResult__businesses"
          style={
            businesses?.length < 3
              ? { justifyContent: "flex-start", marginLeft: "20px" }
              : { justifyContent: "space-evenly" }
          }
        >
          {businesses.map((business, index) => (
            <SearchBusinessCard business={business} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BusinessSearchResult;
