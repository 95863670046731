import React from "react";
import UserSettingsCard from "./UserSettingsCard";

export default function ToSettings() {
  return (
    <div className="tosDetailsSettings l-inner" style={{minHeight:0}}>
      <h5>Terms of Use and Privacy</h5>
      <div className="tosDetailsSettingsCards">
        <UserSettingsCard
          header="Terms of Use"
          subheader="The rule book for Subitt"
          link="footer/terms-of-use"
        />
        <UserSettingsCard
          header="Privacy Policy"
          subheader="How we use information, please read this"
          link="footer/privacy-policy"
        />
        <UserSettingsCard
          header="FAQ"
          subheader="Any questions or concerns can be answered here"
          link="#"
        />
      </div>
      <div className="tosDetailsSettingsCards">
        <UserSettingsCard
          header="Contact Us"
          subheader="For more specific questions and technical issues"
          link="footer/contact-us"
        />
      </div>
    </div>
  );
}
