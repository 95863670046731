import React, {
  useRef,
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";
import { AccountContext } from "../../../Routes/CustomerRoutes";
import { TbMapPinFilled } from "react-icons/tb";
import { downArrow } from "../../../../icons/business/icons";
import Filters from "../../../Components/Filters";
import {
  getNearbyPlaces,
  getNearbySellers,
  getNearbySellerPins,
} from "../../../CustomerStateManagement/FeedState";
import BusinessNotfound from "../../../../Assets/Icons/business_with_question.svg";
import toast, { Toaster } from "react-hot-toast";
import { isLoggedIn } from "../../../CustomerStateManagement/CustomerState";
import BusinessCard from "./BusinessComponents/BusinessCard";
import BusinessMap from "./BusinessComponents/BusinessMap";
import RecommendationForm from "../../../Components/RecommendationForm";
import useUserLocation from "../../../utilities/getLocation";

const BusinessFeed = () => {
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useUserLocation();
  const [filters, setFilters] = useState({
    distance: 250,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Sets up pagination
  const pageRef = useRef(0);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [submitFilter, setSubmitFilter] = useState(false); // The bool state isn't important, just needs a trigger to resubmit
  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open

  // Feed is the content on the left side
  const [feed, setFeed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Store the variables being shown on the map
  const [sellers, setSellers] = useState([]);
  const [places, setPlaces] = useState([]);
  const [sellerPins, setSellerPins] = useState([]);

  // Toggles the width of the sidebar and if we're showing Google places too
  const [toggle, setToggle] = useState(true);

  // Saves search query inputs
  const [submittedQuery, setSubmittedQuery] = useState(); // Searched user input

  // Recommendation form information
  const [showForm, setShowForm] = useState(false);

  // Check if user is signed in
  useEffect(() => {
    let login = isLoggedIn();
    if (login != null) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [isLoggedIn]);

  // Load a query if it's been passed into the URL
  useEffect(() => {
    const queryFromParams = searchParams.get("query");
    if (queryFromParams?.length > 0) {
      setSubmittedQuery(queryFromParams);
    } else {
      setSubmittedQuery("");
      setSearchParams({
        type: "businesses",
      });
    }
  }, [searchParams]);

  // Load subitt sellers and google places on map on load and when a search query is submitted
  useEffect(() => {
    if (!filters?.distance && !location?.geometry?.coordinates) return; // Don't let it fire before it's ready

    setSellers([]);
    setPlaces([]);
    setFeed([]);
    pageRef.current = 1;

    getSubittSellers();
    getGooglePlaces();
  }, [location, filters, submittedQuery]);

  // Loading Businesses on left
  const getSubittSellers = useCallback(async () => {
    if (!filters?.distance && !location?.geometry?.coordinates) return;
    try {
      // Gets nearby sellers that are on Subitt
      const res = await getNearbySellers({
        query: submittedQuery,
        location: location?.geometry?.coordinates,
        page: pageRef.current,
        filters,
      });

      // If there are businesses returned and there are more to be found, set up the correct pagination
      if (res.docs?.length > 0) {
        setSellers((prevSellers) => [...prevSellers, ...res.docs]);
        setFeed((prevFeed) => [...prevFeed, ...res.docs]);

        if (res.hasMore) {
          setCanLoadMore(true);
        } else {
          setCanLoadMore(false);
        }
      } else {
        // No businesses in response
        setCanLoadMore(false);
      }

      return res;
    } catch (err) {
      console.error(err);
    }
  }, [location, filters, submittedQuery]);

  // Loads google places for map to use (I've put it here instead of the business map because we might add cards on the feed for google places too)
  const getGooglePlaces = useCallback(async () => {
    if (!filters?.distance && !location?.geometry?.coordinates) return; // Don't let it fire before it's ready

    const res = await getNearbyPlaces({
      query: submittedQuery,
      location: location?.geometry?.coordinates,
      filters,
    }); // Gets nearby places from Google Places API

    console.log(res);
    setPlaces(res);
    return res;
  }, [location, filters, submittedQuery]);

  // Function + useEffect + useMemo to get and stash all the map pins near the user
  const stashSellerPins = useMemo(() => {
    let cachedResult = null;

    // Function to fetch pins only if dependencies change
    const fetchSellerPins = async () => {
      if (!cachedResult) {
        const res = await getNearbySellerPins({
          query: submittedQuery,
          location: location.geometry.coordinates,
          range: filters.distance,
        });
        cachedResult = res; // Cache the result
      }
      return cachedResult;
    };

    return fetchSellerPins;
  }, [submittedQuery, filters, location]); // Re-computing only happens on changes to miminize seller pins calls

  const getSellerPins = useCallback(async () => {
    if (!location?.geometry?.coordinates) return;
    if (!stashSellerPins) return;

    const res = await stashSellerPins();
    setSellerPins(res);
  }, [filters, stashSellerPins]);

  // Take the nearby sellers and businesses received and put them on the map
  useEffect(() => {
    if (!location?.geometry?.coordinates) return;

    getSellerPins();
  }, [location, filters, submittedQuery]);

  // Intersection Observer that tracks the load more trigger, which will load more content when it's on the screen
  const loadMoreTrigger = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          pageRef.current += 1;
          getSubittSellers();
        }
      },
      { threshold: 0.6 },
    );

    if (loadMoreTrigger.current) {
      observer.observe(loadMoreTrigger.current);
    }

    return () => {
      if (loadMoreTrigger.current) {
        observer.unobserve(loadMoreTrigger.current);
      }
    };
  }, [getSubittSellers]);

  ///////////////////////
  ////// Handlers ///////
  ///////////////////////
  // Button to show the form
  const handleShowForm = (e) => {
    setShowForm((prevFormState) => !prevFormState);
  };

  return (
    <div className="p-businessFeed">
      <div
        className="p-businessFeed__content"
        style={toggle ? { width: "525px" } : { width: "0px" }}
      >
        <div className="p-businessFeed__header">
          <p className="p-businessFeed__header__title">
            {submittedQuery
              ? `Showing results for ${submittedQuery}`
              : "Showing places on Subitt"}
          </p>
        </div>

        <div className="p-businessFeed__filters">
          <Filters setParentFilters={setFilters} />
        </div>

        <div
          className="p-businessFeed__cards"
          // style={toggle ? { width: "500px" } : { width: "0px" }}
        >
          {feed?.map((business, index) => (
            <BusinessCard key={index} business={business.properties} />
          ))}
        </div>

        {/* SUPER IMPORTANT: */}
        {/* If there are no sellers, without this check, it would mean the loadmore trigger is always in view, meaning it's CONSTANTLY, INFINITELY making a new call trying to grab it */}
        {/* That's obv BAD so this checks that something can actually be loaded before making the call */}
        {canLoadMore && (
          <div
            ref={loadMoreTrigger}
            style={{ height: "50px", width: "10px" }}
          />
        )}

        {/* If there's no more, show the recommendation form */}
        {!canLoadMore && (
          <>
            <div className="p-businessFeed__noMore c-businessCard">
              <div className="p-businessFeed__noMore__content">
                <h1>Not seeing the business you want?</h1>
                <img
                  src={BusinessNotfound}
                  alt="Business icon with question mark"
                />
                <p>
                  Try expanding your search radius or tell us who you're looking
                  for!
                </p>

                {!showForm && (
                  <div
                    className="p-businessFeed__noMore__content__button"
                    onClick={handleShowForm}
                  >
                    Suggest a business:
                  </div>
                )}
              </div>
            </div>

            {showForm && <RecommendationForm showHeader={false} />}
          </>
        )}
      </div>

      <BusinessMap
        googlePlaces={places}
        sellerPins={sellerPins}
        widthToggle={toggle}
        setWidthToggle={setToggle}
        searchQuery={submittedQuery}
        querySubmit={submittedQuery}
        filters={filters}
        range={filters.distance}
      />
    </div>
  );
};

export default BusinessFeed;
