import React, { useState } from 'react'
import { emailValidation } from '../../../../Functions/Validation/emailValidation'
import toast, { Toaster } from 'react-hot-toast'
import { forgetPasswordPostEmail } from '../../../CustomerStateManagement/CustomerState'
import ContactHome from '../../HomePage/Components/ContactHome'
import NeedMoreHelp from '../../../../Component/Atom/NeedMoreHelp'

const ResetPasswordFirst = () => {

  const [email, setEmail] = useState("")
  const [processing, setProcessing] = useState(false)

  const steps = [
    "Please enter your email in the box below and click the 'Submit' button. You should recieve an email if you have an account, follow the instructions in the email. If you didn't recieve an email, please check your email boxes.",
    "Reminder: Subitt Employees will never ask you for your password"
  ]

  const submitFirstForm = async(e) => {
    e.preventDefault()
    setProcessing(true)
    if(emailValidation(email)){
      const res = await forgetPasswordPostEmail(email)
      if(res){
        toast.success("email is correctly sent, you will receive the link soon\nIt may take a few minutes to verify the account.")
        setProcessing(false)
      }else{
        toast.error("some error occurred, please try again or contact us")
        setProcessing(false)
      }
    }else{
      setProcessing(false)
    } 
  }

  return (
    <div className='l-inner'>
      <Toaster />
      <div className='p-resetPassword'>
        <div className='p-resetPassword__wrapper'>
          <h4>Forgot Password?</h4>
          <div className='p-resetPassword__instructions'>
            {
              steps.map((step, index) => (
                <h6>{index+1}. {step}</h6>
              ))
            }
          </div>
          <form className='p-resetPassword__form' onSubmit={(e)=>submitFirstForm(e)}>
            <div 
              className='c-inputWrapper__vertical' style=    {{width:"80%"}}
            >
            
              <label className='c-input__label'>Email</label>
              <input 
                className="c-input" 
                name='email'
                placeholder="email"
                value={email} 
                onChange={(e)=>setEmail(e.target.value)}
                required
                />
            </div>
            <button 
              className='c-smallButton' 
              style={{marginLeft:"auto"}}
              type='submit'
              disabled={processing}
              >
                {processing?"Submitting":"Submit"}
              </button>
          </form>
          <NeedMoreHelp />
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordFirst