import { toast } from "react-hot-toast";

const onShare = async () => {
    try {
        const url = window.location.href;

        await navigator.clipboard.writeText(url); // Copy the URL to clipboard

        toast.success("Message and URL copied to clipboard!");
    } catch (err) {
        console.log(err);
        toast.error("Failed to copy message. Please try again.");
    }
};

export default onShare