import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { customerSignOut } from "../../Functions/Auth/signout";
import { MdManageHistory } from "react-icons/md";

import AccountDropdown from "./AccountDropdown";

const CustomerNavLinks = ({ isLoggedIn }) => {
  return (
    <>
      {/* If user is logged in, show user icon */}
      <AccountDropdown isLoggedIn={isLoggedIn} />

      {isLoggedIn && (
        <Link to="/mySubscriptions" type="button">
          <MdManageHistory
            size={25}
            className="customerNavIcon"
            id="nav-link-mySubscriptions"
          />
        </Link>
      )}
    </>
  );
};

export default CustomerNavLinks;
