import React, { useState } from "react";
import useWindowSize from "../../../../Functions/Hooks/useWindowSize";
import { sendSupportTicket } from "../../../../Functions/API/supportTicket";
import toast, { Toaster } from "react-hot-toast";

const SupportTicketForm = ({ side = "Business", data = {} }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  const isMobile = useWindowSize();
  const isBusiness = side === "Business";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      contactInfo: {
        email: isBusiness ? data.email : email,
      },
      customer: isBusiness
        ? data._id
        : side === "Customer"
        ? localStorage.getItem("userId")
        : "Guest User",
      role: side,
      name: isBusiness ? `${data.firstName} ${data.lastName}` : name,
      contents: content,
    };

    const res = await sendSupportTicket(body);
    if (res) {
      toast.success(
        "Thank your for your feedback, our support team will reply as soon as possible!",
      );
    } else {
      toast.error("Failed to send feedback, please try again");
    }
  };

  return (
    <div className="c-supportTicket">
      <h5 className="c-supportTicket__title">Support Form</h5>
      <Toaster />
      <form className="c-supportTicket__form" onSubmit={handleSubmit}>
        {!isBusiness && (
          <div className="c-supportTicket__customerQuestion">
            <div
              className="c-inputWrapper__vertical"
              style={isMobile ? { width: "100%" } : { width: "45%" }}
            >
              <label className="c-input__label">Name</label>
              <input
                className="c-input"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div
              className="c-inputWrapper__vertical"
              style={isMobile ? { width: "100%" } : { width: "45%" }}
            >
              <label className="c-input__label">Email</label>
              <input
                className="c-input"
                type="mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
        )}
        <div className="c-inputWrapper__vertical">
          <label className="c-input__label">Content</label>
          <textarea
            className="c-input__textBox"
            maxLength={3000}
            name="content"
            style={{ resize: "none" }}
            placeholder="Please describe your issue or suggestion in detail. Thank you for helping us improve!"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className={`c-smallButton${isBusiness ? "--business" : ""}`}
          style={{ marginLeft: "auto" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SupportTicketForm;
