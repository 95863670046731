import React from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

const More = ({trigger, setTrigger,placeholder="More"}, cStyle={}) => {
  return (
    <p className='c-more' style={cStyle} onClick={()=>setTrigger(!trigger)}>
      {
        trigger ? (
          <>
            Close <IoIosArrowUp />
          </>
          ) : (
          <>
            {placeholder} <IoIosArrowDown />
          </>
          )
      }
      </p>
  )
}

export default More