import React, { useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigateLink } from "../../Customer_Module/utilities/global_function";
import { RxMagnifyingGlass } from "react-icons/rx";
import { FeedContext } from "../../Customer_Module/Routes/CustomerRoutes";
import { useSearchParams } from "react-router-dom";

const Searchbar = () => {
  const navigate = useNavigate();
  const ref = useRef();

  const [searchParams, setSearchParams] = useSearchParams();
  const [feedType, setFeedType] = useContext(FeedContext);

  // to make the searchbar just static for now
  const handleSubmit = (e) => {
    e.preventDefault();
    const searchQuery = ref.current.value.toLowerCase();

    // If they're on subscriptions, search subscriptions, if they're on businesses, search businesses
    if (feedType) {
      navigateLink(`search?query=${searchQuery}`, navigate);
    } else {
      setSearchParams({
        type: "businesses",
        query: ref.current.value.toLowerCase(),
      });
    }
  };

  useEffect(() => {
    const query = searchParams.get("query");
    ref.current.value = query || "";
  }, []);

  return (
    <form className="c-search__container" onSubmit={handleSubmit}>
      <div className="c-search__icon" onClick={handleSubmit}>
        <RxMagnifyingGlass size={20} />
      </div>
      <label htmlFor="search"></label>
      <input
        className="c-search__input"
        type="text"
        ref={ref}
        placeholder={feedType ? "Search Subscriptions" : "Search Businesses"}
      />
    </form>
  );
};

export default Searchbar;
