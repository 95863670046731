import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DropdownMenu from "../Components/AccountDropdown";
import { customerSignOut } from "../CustomerStateManagement/CustomerState";
import { MdSubscriptions } from "react-icons/md";
import { RiHeartAddLine } from "react-icons/ri";
import { TbCategoryPlus } from "react-icons/tb";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { TbHelpSquare } from "react-icons/tb";
import {
  MdOutlineMessage,
  MdOutlineDiscount,
  MdOutlineCookie,
  MdManageHistory,
} from "react-icons/md";
import { FiCoffee } from "react-icons/fi";
import { LuApple } from "react-icons/lu";
import { RiHomeSmile2Line } from "react-icons/ri";
import { TbPerfume } from "react-icons/tb";
import { FaSignOutAlt } from "react-icons/fa";

import { FeedContext } from "../Routes/CustomerRoutes";
import { BiHome } from "react-icons/bi";
import useWindowSize from "../../Functions/Hooks/useWindowSize";

const Sidebar = () => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isMobile = useWindowSize();

  const navigate = useNavigate();
  const location = useLocation();

  // Get logged in status
  useEffect(() => {
    let login = localStorage.getItem("isLoggedIn");
    if (login != null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  // Change if Navbar is there or not
  useEffect(() => {
    // If we're on the subscription page, make sidebar disappear on mobile
    if (feedType) {
      setSidebar(!isMobile);
    }
  }, [feedType, isMobile]);

  const handleNavClick = (route) => {
    navigate(`/${route}`);
  };

  const sidebarClick = () => {
    setSidebar((prevState) => !prevState);
  };

  return (
    <>
      <div className="l-cSidebar__content">
        {/* Hamburger Menu */}
        {/* <div className="l-cSidebar__toggleSidebar" onClick={sidebarClick}>
          <div
            className="l-cSidebar__toggleSidebar__barOne"
            style={sidebar ? { rotate: "90deg" } : { rotate: "90deg" }}
          ></div>
          <div
            className="l-cSidebar__toggleSidebar__barTwo"
            style={sidebar ? { rotate: "90deg" } : { rotate: "90deg" }}
          ></div>
        </div> */}

        <div style={!sidebar ? { display: "none" } : { display: "contents" }}>
          <div className="l-cSidebar__section section__one">
            <div
              className={
                location.pathname === "/feed"
                  ? "l-cSidebar__link l-cSidebar__link__active"
                  : "l-cSidebar__link"
              }
            >
              <BiHome
                size={20}
                className="sidebar-icon"
                onClick={() => handleNavClick("feed")}
              />
              <Link to={"/feed"}>Home</Link>
            </div>

            {/* <div location.pathname.startsWith("/browse") && location.search.includes("category=sweets")
                  ? "l-cSidebar__link l-cSidebar__link__active"
                  : "l-cSidebar__link"
              }>
              <MdOutlineCookie
                size={20}
                className="sidebar-icon"
                onClick={() => handleNavClick("/browse?category=sweets")}
              />
              <Link to={"/browse?category=sweets"}>Sweets</Link>
            </div> */}

            <div
              className={
                location.pathname.startsWith("/browse") &&
                location.search.includes("category=food")
                  ? "l-cSidebar__link l-cSidebar__link__active"
                  : "l-cSidebar__link"
              }
            >
              <LuApple
                size={20}
                className="sidebar-icon"
                onClick={() => handleNavClick("browse?category=food")}
              />
              <Link to={"/browse?category=food"}>Food</Link>
            </div>

            <div
              className={
                location.pathname.startsWith("/browse") &&
                location.search.includes("category=drinks")
                  ? "l-cSidebar__link l-cSidebar__link__active"
                  : "l-cSidebar__link"
              }
            >
              <FiCoffee
                size={20}
                className="sidebar-icon"
                onClick={() => handleNavClick("browse?category=drinks")}
              />
              <Link to={"/browse?category=drinks"}>Drinks</Link>
            </div>

            {/* <div className={
                location.pathname.startsWith("/browse") &&
                location.search.includes("category=home")
                ? "l-cSidebar__link l-cSidebar__link__active"
                : "l-cSidebar__link"
              }>
              <RiHomeSmile2Line
                size={20}
                className="sidebar-icon"
              />
              <Link to={"/browse?category=home"}>Home Care</Link>
            </div> */}

            <div
              className={
                location.pathname.startsWith("/browse") &&
                location.search.includes("category=beauty")
                  ? "l-cSidebar__link l-cSidebar__link__active"
                  : "l-cSidebar__link"
              }
            >
              <TbPerfume
                size={20}
                className="sidebar-icon"
                onClick={() => handleNavClick("browse?category=beauty")}
              />
              <Link to={"/browse?category=beauty"}>Beauty</Link>
            </div>

            {/* <div
              className={
                location.pathname === "/browseAll"
                ? "l-cSidebar__link l-cSidebar__link__active"
                : "l-cSidebar__link"
              }
            >
              <TbCategoryPlus
                size={20}
                className="sidebar-icon"
              />
              <Link to={"/browseAll"}>All Categories</Link>
            </div> */}

            {/* <div className={
                location.pathname.startsWith("/promotions")
                ? "l-cSidebar__link l-cSidebar__link__active"
                : "l-cSidebar__link"
              }>
              <MdOutlineDiscount
                size={20}
                className="sidebar-icon"
              />
              <Link to={"/promotions"}>Promotions</Link>
            </div> */}
          </div>

          {isLoggedIn ? (
            <div className="l-cSidebar__section section__two">
              <div
                className={
                  location.pathname === "/mySubscriptions"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <MdManageHistory
                  size={20}
                  className="sidebar-icon"
                  onClick={() => handleNavClick("mySubscriptions")}
                />
                <Link to={"/mySubscriptions"}>My Subscriptions</Link>
              </div>

              <div
                className={
                  location.pathname === "/user"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <FaUser size={20} className="sidebar-icon" />
                <Link to={"/user"}>Account</Link>
              </div>

              {/* Wishlist, commented out because it's in account dropdown */}
              <div
                className={
                  location.pathname === "/wishlist"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <RiHeartAddLine size={20} className="sidebar-icon" />
                <Link to={"/wishlist"}>Wishlist</Link>
              </div>

              {/* <div
                className={
                location.pathname === "/chatbox"
                ? "l-cSidebar__link l-cSidebar__link__active"
                  : "l-cSidebar__link"
              }
              >
                <MdOutlineMessage
                  size={20}
                  className="sidebar-icon"
                />
                <Link to={"/chatbox"}>Messages</Link>
              </div> */}

              <div
                className={
                  location.pathname === "/footer/help-center"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <TbHelpSquare size={20} className="sidebar-icon" />
                <Link to={"/footer/help-center"}>Help</Link>
              </div>

              {/* Sign out, commented out because it's in the account dropdown */}
              <div
                className={
                  location.pathname === "/login"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <FaSignOutAlt size={20} className="sidebar-icon" />
                <Link to={"/login"} onClick={customerSignOut}>
                  Sign out
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div
                className={
                  location.pathname === "/footer/help-center"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <TbHelpSquare size={20} className="sidebar-icon" />
                <Link to={"/footer/help-center"}>Help</Link>
              </div>
              <div
                className={
                  location.pathname === "/login"
                    ? "l-cSidebar__link l-cSidebar__link__active"
                    : "l-cSidebar__link"
                }
              >
                <FaUserPlus size={20} className="sidebar-icon" />
                <Link className="l-cSidebar__signout" to={"/login"}>
                  Sign In
                </Link>
              </div>
            </>
          )}
          <div className="l-cSidebar__sellerPlus">
            <h6>Are you a business owner?</h6>
            <Link to={"/businessportal/dashboard"}>Sell with us!</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
