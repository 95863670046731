import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ItemDetailContext } from '../../ItemDetail'
import { getImgById } from '../../../../../Functions/Global/imgFormat'

const ItemImgs = () => {
  const [data, itemId] = useContext(ItemDetailContext)
  const [curImg, setCurImg] = useState(0)
  const [imgsData, setImgsData] = useState([])

  useEffect(() => {
    const getImgData = () => {
      if(data?.images){
        data?.images?.map(async(image,i) => {
          try{
            const res = await getImgById(image)
            let url;
            if(res){
              url = res
            }else{
              url = "error"
            }
            setImgsData(prev => ([
              ...prev,
              url
            ]))
          }catch(err){
            console.log(err)
          }
        })
      }
    }
    getImgData()
  },[data])


  return (
    <div className='p-itemDetail__itemImgs'>
    <div className='p-itemDetail__itemImgs--main'>
      <img src={imgsData[curImg] || null} alt="main item Image" />
    </div>
    <div className='p-itemDetail__itemImgs--smalls'>
      {
        data?.images?.map((imgUrl, i) => {

          return(
            <div 
              className='p-itemDetail__itemImgs--small'
              onClick={() => setCurImg(i)}
            >
              <img src={imgsData[i]} alt={`item image ${i}`} />
            </div>
          )
        })
      }
    </div>
  </div>
  )
}

export default ItemImgs