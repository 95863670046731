import React, { useState, useEffect, useContext } from "react";
import Rating from "@mui/material/Rating";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast, { Toaster } from "react-hot-toast";
import {
  editReview,
  deleteReview,
} from "../../../../CustomerStateManagement/ReviewState";
import Avatar from "../../../AccountDetail/Components/AvatarImage";
import { Loading } from "../../../../../GlobalComponents";
import { getPublicAccountById } from "../../../../CustomerStateManagement/CustomerState";
import { ReviewContext } from "../../ItemDetail";

const ReviewItem = ({ review, onReviewUpdated }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedTitle, setEditedTitle] = useState(review.title);
  const [editedRating, setEditedRating] = useState(review.rating);
  const [editedBody, setEditedBody] = useState(review.body);

  const [titleMessage, setTitleMessage] = useState(null)
  const [bodyMessage, setBodyMessage] = useState(null)
  const [ratingMessage, setRatingMessage] = useState(null)

  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bodyCharCount, setBodyCharCount] = useState(0);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const maxBodyCharLimit = 2500;
  const maxTitleCharLimit = 100;

  const [reviews, setReviews, hasReviewed, setHasReviewed] =
    useContext(ReviewContext);

  const [currentReview, setCurrentReview] = useState(null);
  const accountId = localStorage.getItem("userId");

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    fetchAuthor();

    for (let index in reviews) {
      if (review._id == reviews[index]._id) {
        setCurrentReview(reviews[index]);
      }
    }

    setLoading(false);
  }, [reviews]);

  async function fetchAuthor() {
    const response = await getPublicAccountById(review.accountId);
    setAuthor(response);
  }

  if (loading || currentReview === null || author == null) {
    //fetchAuthor()
    return <Loading />;
  }

  const toggleEditMode = (currentReview) => {
    setEditedTitle(currentReview.title)
    setEditedBody(currentReview.body)
    setEditedRating(currentReview.rating)

    //clear messages
    setTitleMessage(null)
    setBodyMessage(null)
    setRatingMessage(null)
    
    setEditMode(!editMode);
  };

  async function handleEditSubmit() {
    console.log('Edited title', editedTitle)
    console.log('Edited body', editedBody)
    console.log('Edited rating', editedRating)

    //frontend validation
    if (editedTitle.trim() === "" && editedBody.trim() === "" && editedRating === "") {
      setTitleMessage('Please fill out the title for your review.')
      setBodyMessage('Please fill out the body of your review.')
      setRatingMessage('Please fill out the rating of this product.')
      return
    } 

    if (editedTitle.trim() === "" || editedTitle.length > maxTitleCharLimit || editedBody.trim() === "" || editedBody.length > maxBodyCharLimit || editedRating === 0 || editedRating === null) {
      if (editedTitle.trim() === "") {
        setTitleMessage('Please fill out the title for your review.')
      } else if (editedTitle.length > maxTitleCharLimit) {
        setTitleMessage(`Title cannot exceed ${maxTitleCharLimit} characters in length.`)
      } else {
        setTitleMessage(null)
      }
      
      if (editedBody.trim() === "") {
        setBodyMessage('Please fill out the body of your review.')
      } else if (editedBody.length > maxBodyCharLimit) {
        setBodyMessage(`Body of review cannot exceed ${maxBodyCharLimit} characters in length.`)
      } else {
        setBodyMessage(null)
      }

      if (editedRating === 0 || editedRating === null) {
        setRatingMessage('Please add a rating to your review.')
      } else {
        setRatingMessage(null)
      }
      return
    }

    const updatedReview = {
      _id: review._id,
      accountId: review.accountId,
      title: editedTitle,
      rating: editedRating,
      body: editedBody,
      date: Date.now(),
    };

    try {
      const response = await editReview(review._id, updatedReview);

      console.log('Response', response)
      //backend validation
      if (response.msg != undefined){
        if (response.msg.title) {
          setTitleMessage(response.msg.title)
        } else {
          setTitleMessage(null)
        }
        if (response.msg.body) {
          setBodyMessage(response.msg.body)
        } else {
          setBodyMessage(null)
        }
        if (response.msg.rating) {
          setRatingMessage(response.msg.rating)
        } else {
          setRatingMessage(null)
        }
      }
      else {
        setReviews((prevReviews) =>
          prevReviews.map((r) => (r._id === review._id ? response : r)),
        );

        //clear messages
        setTitleMessage(null)
        setBodyMessage(null)
        setRatingMessage(null)

        // Keep `hasReviewed` true after an edit
        setHasReviewed(true); 
        setEditMode(!editMode);
      }
    } catch (error) {
      console.error("Error editing review:", error);
    }
  }

  const handleDelete = () => {
    toast(
      (t) => (
        <div>
          <p>Are you sure you want to delete this review?</p>
          <div>
            <button onClick={() => toast.dismiss(t.id)}>Cancel</button>
            <button onClick={() => confirmDelete(t.id)}>Delete</button>
          </div>
        </div>
      ),
      {
        duration: 4000,
      },
    );
  };

  // Update charcount to make sure title and body are not too long
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    if (newTitle.length <= maxTitleCharLimit) {
      setEditedTitle(newTitle);
      setTitleCharCount(newTitle.length);
    }
  };

  const handleBodyChange = (e) => {
    const newBody = e.target.value;
    if (newBody.length <= maxBodyCharLimit) {
      setEditedBody(newBody);
      setBodyCharCount(newBody.length);
    }
  };

  const confirmDelete = async (toastId) => {
    try {
      await deleteReview(review._id);
      toast.dismiss(toastId);
      setReviews((prevReviews) =>
        prevReviews.filter((r) => r._id !== review._id),
      );
      setHasReviewed(false); // Allow the form to reappear
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  return (
    <div className="reviewItem">
      {editMode ? (
        <div>
          <h6>Edit Your Review</h6> <br></br>
          {ratingMessage ? <label for="rating" style={{color: 'red'}}>{ratingMessage}</label> : <></>}
          <br />
          <Rating
            name="rating"
            className="rating c-starRating"
            value={editedRating}
            sx={{
              // Resizing the icons
              "& .MuiRating-iconFilled": {
                color: "#e5704c",
                fontSize: "20px",
              },
              "& .MuiRating-iconEmpty": {
                color: "lightgray",
                fontSize: "20px",
              },
              "& .MuiRating-iconHover": {
                color: "#e5704c",
                fontSize: "20px",
              },
            }}
            precision={.5}
            onChange={(e, newValue) => setEditedRating(newValue)}
          /> <br />
          {titleMessage ? <label for='review-title' style={{color: 'red'}}>{titleMessage}</label> : <></>}
          <input
            type="text"
            value={editedTitle}
            onChange={handleTitleChange}
            placeholder={review.title}
          />
          {bodyMessage ? <label for='review-body' style={{color: 'red'}}>{bodyMessage}</label> : <></>}
          <textarea
            style={{
              width: "100%",
              border: "1px solid lightgray",
              marginTop: "1em",
            }}
            value={editedBody}
            onChange={handleBodyChange}
            rows={5}
            placeholder={review.body}
          />
          <p className="review-charLimit">
            {bodyCharCount}/{maxBodyCharLimit}
          </p>
          <br></br>
          <button onClick={handleEditSubmit}>Submit</button>
          <button onClick={() => setEditMode(!editMode)}>Cancel</button>
        </div>
      ) : (
        <>
          <h6 className="reviewTitle"
          >
            {review.title}
          </h6>
          <div
            style={{ display: "flex", marginLeft: "auto" }}
          >
            <div style={{ display: "flex" }}>
              <Avatar avatarImg={author.accountDetail?.avatarImg} />
              <p style={{ padding: ".5em" }}>
                {author.firstName} {author.lastName}
              </p>
            </div>

            <Rating
              value={review.rating}
              readOnly
              precision={0.5}
              sx={{
                // Resizing the icons
                "& .MuiRating-iconFilled": {
                  fontSize: "16px",
                },
                "& .MuiRating-iconEmpty": {
                  fontSize: "16px",
                },
                "& .MuiRating-iconHover": {
                  fontSize: "16px",
                },
              }}
              className="rating"
              style={{ marginLeft: "auto" }}
            />
          </div>
          <div>
          <p className="reviewBody">{review.body}</p>
          <br></br>
          <p style={{color: 'gray', fontSize: 'small'}}>{formatDate(review.date)}</p>
          {review.accountId === accountId && (
          <>
              <EditIcon
                className="reviewEditIcon"
                style={{ cursor: "pointer"}}
                onClick={() => toggleEditMode(review)}
              />
              <DeleteIcon
                className="reviewDeleteIcon"
                style={{ cursor: "pointer"}}
                onClick={handleDelete}
              />
          </>
          )} 
          </div>
        </>
      )}
      <Toaster />
    </div>
  );
};

export default ReviewItem;
