import axios from "axios";

// All varibales
const Url = require("../../backendURL");

/*************************************
 * Get Reviews by Item ID
 ***********************************/

export const getReviewsByItemId = async (id) => {
  try {
    const response = await axios.get(`${Url}reviews/item/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Get Reviews by Account ID
 * Used to check if the user has reviewed an object before
 ***********************************/

export const getReviewsByUserId = async (id) => {
  try {
    const response = await axios.get(`${Url}reviews/user/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Get Reviews and related products by Account ID
 ***********************************/
export const aggregateObjectsFromReview = async (id) => {
  try {
    const response = await axios.get(`${Url}reviews/account/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Create Review
 ***********************************/

export const createReview = async (data) => {
  try {
    // Retrieve the JWT token from local storage
    const token = localStorage.getItem("token");

    // Check if the token exists
    if (!token) {
      throw new Error("No token found");
    }

    const res = await axios.post(`${Url}reviews/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 201) {
      return res.data;
    }
  } catch (error) {
    console.error("Error posting data:", error);
    return error.response.data;
    //throw new Error("Failed to post review to server", error);
  }
};

/*************************************
 * Edit Review
 ***********************************/

export const editReview = async (id, data) => {
  try {
    // Retrieve the JWT token from local storage
    const token = localStorage.getItem("token");

    // Check if the token exists
    if (!token) {
      throw new Error("No token found");
    }

    const res = await axios.patch(`${Url}reviews/edit/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.error("Error posting data:", error);
    throw new Error("Failed to edit review");
  }
};
/*************************************
 * Delete Review
 ***********************************/

export const deleteReview = async (id) => {
  try {
    // Retrieve the JWT token from local storage
    const token = localStorage.getItem("token");

    // Check if the token exists
    if (!token) {
      throw new Error("No token found");
    }

    const res = await axios.delete(`${Url}reviews/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return res.data.doc;
    }
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to delete review");
  }
};
