import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { createNewPasswordForForgotten } from "../../../CustomerStateManagement/CustomerState";
import NeedMoreHelp from "../../../../Component/Atom/NeedMoreHelp";

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const { accountId, token } = useParams();
  const navigate = useNavigate();
  const steps = [
    "Please create a new password, using 8 characters, with one upper case and one number.",
    "Re-enter the new password in the second box, then hit 'Submit'. You will be redirected to the login page once the password has been successfully changed. If you log in, you are good to go!, (If you are using our mobile, please go back to the app manually and you can sign-in with the password.)",
  ];

  const submitSecondForm = async (e) => {
    e.preventDefault();
    setProcessing(true);
    if (verification()) {
      const res = await createNewPasswordForForgotten(
        newPassword,
        accountId,
        token,
      );
      if (res) {
        toast.success(
          "Password is correctly updated!\nYou can sign in with the new password now",
          {
            duration: 2000,
          },
        );
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        setProcessing(false);
      } else {
        setProcessing(false);
        toast.error(
          "Password could not updated.\nThis link will be expired 10 minutes after the link is generated.",
          {
            duration: 5000,
          },
        );
      }
    }
  };

  const verification = () => {
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword)) {
      toast.error(
        "Password needs to have more that 8 characters including capital letter, small letter, and number",
      );
      return false;
    }
    if (newPassword !== verifyPassword) {
      toast.error("Password doesn't match with the verify password");
      return false;
    }
    return true;
  };

  return (
    <div className="l-inner">
      <Toaster />
      <div className="p-resetPassword">
        <div className="p-resetPassword__wrapper">
          <h4>Choose a new Password</h4>
          <p>This page will be expired 10 minutes after the email is sent</p>
          <div className="p-resetPassword__instructions">
            {steps.map((step, index) => (
              <h6>
                {index + 1}. {step}
              </h6>
            ))}
          </div>
          <form
            className="p-resetPassword__form"
            onSubmit={(e) => submitSecondForm(e)}
          >
            <div className="c-inputWrapper__vertical" style={{ width: "80%" }}>
              <label className="c-input__label">New Password</label>
              <input
                className="c-input"
                name="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="c-inputWrapper__vertical" style={{ width: "80%" }}>
              <label className="c-input__label">Verify Password</label>
              <input
                className="c-input"
                name="verifyPassword"
                placeholder="Verify Password"
                value={verifyPassword}
                onChange={(e) => setVerifyPassword(e.target.value)}
                required
              />
            </div>
            <button
              className="c-smallButton"
              style={{ marginLeft: "auto" }}
              type="submit"
              disabled={processing}
            >
              {processing ? "Submitting" : "Submit"}
            </button>
          </form>
          <NeedMoreHelp />
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
