import React, { useContext } from "react";
import GreenArrow from "../../Assets/Patterns/swirly_arrow_dark_green.svg";
import { FeedContext } from "../Routes/CustomerRoutes";

const NoResultsPage = ({ title }) => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

  return (
    <div
      className="c-noResults"
      style={sidebar ? { width: "calc(100vw - 200px)" } : { width: "100vw" }}
    >
      <h1 className="c-noResults__rainbow">
        <span>H</span>
        <span>A</span>
        <span>N</span>
        <span>G</span>
        <br></br>
        <span>T</span>
        <span>I</span>
        <span>G</span>
        <span>H</span>
        <span>T</span>
      </h1>

      <p>
        We couldn't find any {title} subscriptions near you.<br></br> New
        businesses are joining us all the time, so check back in again soon, or
        recommend a business to us below!
      </p>
      {/* <img className="c-noResults__arrow" src={GreenArrow} alt="" /> */}
    </div>
  );
};

export default NoResultsPage;
