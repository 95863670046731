import React, { useContext, useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BiSolidStar } from "react-icons/bi";
import StarRating from "./StarRating";
import { ReviewContext } from "../../ItemDetail";


const ReviewSummary = () => {
  const [reviews, setReviews, hasReviewed, setHasReviewed, filtered, setFiltered] =
    useContext(ReviewContext);
  const [loading, setLoading] = useState(true);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [starCounts, setStarCounts] = useState(Array(5).fill(0));
  const [toggle, setToggle] = useState(false);
  const [originalReviews, setOriginalReviews] = useState(reviews);
  const [currentRating, setCurrentRating] = useState(null);

  console.log(reviews)

  useEffect(() => {
    if ((!reviews || reviews.length === 0) && filtered === 0 ) {
      setLoading(false);
      setTotalReviews(0);
      setStarCounts(Array(5).fill(0));
      setAverageRating(0);
      return;
    }

    let totalRating = 0;
    const newStarCounts = Array(5).fill(0);

    reviews.forEach((review) => {
      totalRating += review.rating;
      const truncatedRating = Math.floor(review.rating);
      newStarCounts[truncatedRating - 1] += 1;
    });

    if (toggle == false) {
      setTotalReviews(reviews.length);
      setAverageRating(totalRating / reviews.length);
      setStarCounts(newStarCounts);
    }
    setLoading(false);
  }, [reviews, hasReviewed]);

  if (loading) {
    return <p>Loading...</p>;
  }

  function handleRatingClick(rating) {
    setToggle(!toggle);

    if (toggle === false) {
      setFiltered(rating)
      setOriginalReviews(reviews);
      setCurrentRating(rating);

      setReviews((prevReviews) =>
        prevReviews.filter((review) => review.rating === rating || review.rating === rating + .5),
      );
    } else {
      setFiltered(0)
      setReviews(originalReviews);
      setCurrentRating(0);
    }
  }

  return (
    <Box className="review-summary" sx={{ width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        <h6>Review Summary</h6>
        <div className="c-starRating" style={{ display: "flex" }}>
          <StarRating rating={averageRating.toFixed(1) / 5} />
          <h6 style={{ display: "flex", alignItems: "center" }}>
            {averageRating.toFixed(1)} / 5
          </h6>
        </div>
        <p style={{ fontSize: "small", color: "gray" }}>
          {totalReviews} {totalReviews > 1 ? "reviews" : "review"}
        </p>
      </Typography>
      {starCounts.map((count, index) => {
        const rating = index + 1;
        const percentage = (count / totalReviews) * 100;
        return (
          <Box
            key={rating}
            style={{
              padding: ".5em",
              borderRadius: "0%",
              backgroundColor: currentRating == rating ? "#eaeaea" : "",
              cursor: 'pointer'
            }}
            onClick={() => handleRatingClick(rating)}
          >
            <Box display="flex" alignItems="center" mb={0}>
              <Typography
                variant="body2"
                sx={{ color: "#e5704c", minWidth: "fit-content" }}
              >
                {Array(rating)
                  .fill(0)
                  .map((_, i) => (
                    <BiSolidStar key={i} />
                  ))}
              </Typography>
              <Box
                sx={{ marginLeft: "auto", width: "100%", textAlign: "right" }}
              >
                <Typography variant="body2" color="text.secondary"> 
                  {starCounts[index]}
                </Typography>
              </Box>
            </Box>
            {totalReviews > 0 ? (
            <Box sx={{ width: "100%", color: "#e5704c", mb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={percentage}
                color="inherit"
                style={{height: '.5em', borderRadius: '10px'}}
              />
            </Box> ) : (
              <Box sx={{ width: "100%", color: "#e5704c", mb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={0}
                color="inherit"
                style={{height: '.5em', borderRadius: '10px'}}
              />
            </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ReviewSummary;
