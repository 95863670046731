import React, { useEffect, useState, useContext } from "react";
import { getTopRatedSubscriptions } from "../../../../CustomerStateManagement/FeedState";
import SubscriptionCard from "../SubscriptionComponents/SubscriptionCard";
import { FeedContext } from "../../../../Routes/CustomerRoutes";
import { AccountContext } from "../../../../Routes/CustomerRoutes";
import useUserLocation from "../../../../utilities/getLocation";

const TopTenItems = () => {
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const location = useUserLocation();

  useEffect(() => {
    if (!location?.geometry?.coordinates) return;
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const res = await getTopRatedSubscriptions({
          location: location?.geometry?.coordinates,
        });
        setSubscriptions(res);
      } catch (error) {
        console.error("Error fetching favorite sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, [location]);

  return (
    <>
      {subscriptions?.length > 0 && (
        <div
          className="c-topTenItems"
          style={
            sidebar ? { width: "calc(100vw - 200px)" } : { width: "100vw" }
          }
        >
          <div className="c-topTenItems__title">Top Subscriptions near you</div>

          <div className="c-topTenItems__cards ">
            {subscriptions.map((subscription, index) => (
              <div className="c-topTenItems__cards__box">
                <p className="c-topTenItems__cards__rating">#{index + 1}</p>

                <SubscriptionCard key={index} product={subscription} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TopTenItems;
