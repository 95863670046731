import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX, FiSearch } from "react-icons/fi"; // Added FiSearch

import Searchbar from "../../GlobalComponents/Modules/Searchbar";
import subittLogo from "../../Assets/subittLogo-orange.png";
import CustomerNavLinks from "./CustomerNavLinks";
import NavBarAddress from "./NavBarAddress";
import useWindowSize from "../../Functions/Hooks/useWindowSize";
import { FeedContext } from "../Routes/CustomerRoutes";
import { AccountContext } from "../Routes/CustomerRoutes";
import Sidebar from "./Sidebar";

const CustomerNavBar = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false); // New state for search

  useEffect(() => {
    let login = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(login !== null);
  }, [isLoggedIn, location.pathname]);

  const handleToggle = (type) => {
    if (
      (type === "subscriptions" && !feedType) ||
      (type === "businesses" && feedType)
    ) {
      setFeedType((prevState) => !prevState);
    }
  };

  const handleLogoClick = () => {
    setFeedType(true);
  };

  const handleMenuClick = () => {
    if (searchOpen) {
      setSearchOpen(false);
    }

    if (feedType) {
      setSidebar((prevState) => !prevState);
      setMenuOpen((prevState) => !prevState);
    }
  };

  const handleSearchClick = () => {
    if (sidebar) {
      setSidebar(false);
    }

    setSearchOpen((prevState) => !prevState);
  };

  const isMobile = useWindowSize();

  return (
    <div className="l-cNav">
      {/* Background for navbar */}
      <div className="l-cNav__background"></div>

      {/* Mobile View */}
      {isMobile ? (
        <>
          {/* Hamburger Menu */}
          {feedType && (
            <div className="l-cNav__menuIcon" onClick={() => handleMenuClick()}>
              {menuOpen ? <FiX size={28} /> : <FiMenu size={28} />}
            </div>
          )}

          {/* Logo */}
          <div className="l-cNav__logo" onClick={handleLogoClick}>
            <Link to={"/feed"}>
              <img src={subittLogo} alt="Subitt Logo" />
            </Link>
          </div>

          {/* Search Icon */}
          <div
            className="l-cNav__searchIcon"
            onClick={() => handleSearchClick()}
          >
            <FiSearch size={28} />
          </div>

          {/* Search Modal */}
          {searchOpen && (
            <div className="l-cNav__searchModal">
              <div className="l-cNav__searchModal__header">
                <FiX size={28} onClick={() => setSearchOpen(false)} />
              </div>
              <div className="l-cNav__searchModal__input">
                <Searchbar />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {/* Logo */}
          <div className="l-cNav__logo" onClick={handleLogoClick}>
            <Link to={"/feed"}>
              <img src={subittLogo} alt="Subitt Logo" />
            </Link>
          </div>

          {/* Subscription / Business toggle */}
          <div className="l-cNav__toggle">
            <div
              className="l-cNav__toggle__bubble"
              style={
                feedType
                  ? { left: "0", width: "87.5px" }
                  : { left: "89px", width: "76px" }
              }
            ></div>
            <Link
              to="/feed?type=subscriptions"
              onClick={() => handleToggle("subscriptions")}
            >
              Subscriptions
            </Link>
            <Link
              to="/feed?type=businesses"
              onClick={() => handleToggle("businesses")}
            >
              Businesses
            </Link>
          </div>

          {/* Full Search Bar */}
          <div className="l-cNav__searchBar">
            <Searchbar />
          </div>

          {/* Address */}
          <div className="l-cNav__address">
            <NavBarAddress />
          </div>

          {/* User Account */}
          <div className="l-cNav__icons">
            <CustomerNavLinks isLoggedIn={isLoggedIn} />
          </div>
        </>
      )}

      {/* Mobile Menu Content */}
      {/* {isMobile && menuOpen && (
        <div className="l-cNav__mobileMenu">
          <Link
            to="/feed?type=subscriptions"
            onClick={() => handleToggle("subscriptions")}
          >
            Subscriptions
          </Link>
          <Link
            to="/feed?type=businesses"
            onClick={() => handleToggle("businesses")}
          >
            Businesses
          </Link>
          <NavBarAddress />
          <CustomerNavLinks isLoggedIn={isLoggedIn} />
        </div>
      )} */}
    </div>
  );
};

export default CustomerNavBar;
