/*******************************************************************************************************
 * Function/File Purpose: This file contains all API Calls needed for the Subscription Feed page
 * Author: Aoi Kuriki
 * Date: 1/27/2025
 *
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * ********************************
 */
import axios from "axios";
const Url = require("../../backendURL");

export  const convertMultiImage = async(originalImgs) => {
  // e.preventDefault()
  try {
    const results = await Promise.all(originalImgs.map(async (img, index) => {
      let data = new FormData();
      data.append("file", img, img.name);
      let res = await axios.post(Url + "images/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return { index, result: res.data.file_json };
    }));
    
    // Sort the results based on their original indexes
    const compiledImgs = results.sort((a, b) => a.index - b.index).map(item => item.result);

    return compiledImgs;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export const getImgById = async (id) => {
  try {
    const res = await axios.get(`${Url}images/${id}`, {
      responseType: 'arraybuffer', // Fetch binary data
    });
    const base64 = btoa(
      new Uint8Array(res.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), "")
    ); // Convert ArrayBuffer to base64
    const contentType = res.headers['content-type']; // Get content type
    return `data:${contentType};base64,${base64}`; // Return as data URL
  } catch (err) {
    console.error(err);
    return null; // Handle error gracefully
  }
};
