import React, { useContext, useEffect, useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import toast from "react-hot-toast";
import useImageUpload from "../../../../Admin_Module/Components/functions/ImageUploads";
import { editAccountInfo } from "../../../CustomerStateManagement/CustomerState";
import { AccountContext } from "../../../Routes/CustomerRoutes";

import { FaRegCircle, FaCircle, FaRegSquare, FaSquare } from 'react-icons/fa'
import ImageUploader from "../../../../Admin_Module/Components/functions/ImageUploader";

const Url = require("../../../../backendURL");

const UpdateAvatarImage = () => {
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [userInfo, setUserInfo] = useContext(AccountContext);


  const handleUpload = async () => {
    console.log('image', image)
    if (image != null) {
      try {
      const updatedAccountData = { accountDetail: { avatarImg: image[0] } };
      const res = await editAccountInfo(updatedAccountData);

      console.log("Response", res)

      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        accountDetail: {
          ...prevUserInfo.accountDetail,
          avatarImg: image[0],
        },
      }));

      console.log("User info", userInfo.accountDetail.avatarImg)

      toast.success("Image uploaded and account updated successfully!");
      setImage(null);
    } catch (err) {
      console.log('Error', err)
      toast.error("An error occurred in avatar image during the upload process.");
    }    
  }
  };

  return (
    <div>
      <ImageUploader image={image} setImage={setImage} uploadImage={handleUpload} />
      {image && <div onClick={handleUpload}>Upload Avatar</div>}
    </div>
  );
};

export default UpdateAvatarImage;