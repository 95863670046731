import React, { useState, useEffect, useContext } from "react";
import ReviewItem from "./ReviewCard";
import "../../../../../scss/Object/Components/_button.scss";
import { getReviewsByItemId } from "../../../../CustomerStateManagement/ReviewState";
import {
  getAccountById,
  getPublicAccountById,
} from "../../../../CustomerStateManagement/CustomerState";
import { Loading } from "../../../../../GlobalComponents";
import { ReviewContext } from "../../ItemDetail";


const ReviewList = ({ itemId }) => {
  const [visibleReviewsCount, setVisibleReviewsCount] = useState(3);
  const [loading, setLoading] = useState(true);

  const [reviews, setReviews, hasReviewed, setHasReviewed, filtered, setFiltered] = useContext(ReviewContext);

  useEffect(() => {
    setLoading(false);
  }, [reviews]);

  const loadMoreReviews = () => {
    setVisibleReviewsCount((prevCount) => prevCount + 3);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="reviewListSection">
      {reviews?.length === 0 && filtered != 0 ? (<p>No reviews with {filtered} stars.</p>) : reviews?.length === 0 && filtered === 0 ? (
        <p>No reviews available.</p>
      ) : (
        <div>
          {reviews &&
            reviews
              ?.slice(0, visibleReviewsCount)
              .map((review, index) => (
                <ReviewItem
                  className="reviewItem"
                  key={index}
                  review={review}
                  onReviewUpdated={setReviews}
                />
              ))}
          {reviews && reviews?.length > visibleReviewsCount && (
            <button style={{margin: 'auto', width: '100%'}} className="u-Btn--orange" onClick={loadMoreReviews}>
              Load More Reviews
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewList;
