import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../Routes/CustomerRoutes";

const useUserLocation = () => {
  const [location, setLocation] = useState();
  const [userInfo, setUserInfo] = useContext(AccountContext);

  useEffect(() => {
    if (!userInfo?.addresses) return;

    if (userInfo?.addresses[0]) {
      setLocation(userInfo?.addresses[0]);
    } else {
      setLocation({
        line1: "Salt Lake City",
        geometry: {
          type: "Point",
          coordinates: [-111.891, 40.7608],
        },
      });
    }
  }, [userInfo.addresses]);

  return location;
};

export default useUserLocation;
