import React from 'react'
import SupportTicketForm from '../../Business_Module/Pages/Dashboard/Components/SupportTicketForm'


const NeedMoreHelp = () => {
  return (
    <div className='c-needMoreHelp'>
      <h4 style={{fontWeight:"bold"}}>Need More Help?</h4>
      <h6 style={{marginTop:8,marginBottom:24}}>Contact Us:  admin@subitt.io, or...</h6>
      <SupportTicketForm side='Customer'/>
    </div>
  )
}

export default NeedMoreHelp