import React, { useEffect, useState } from 'react'
import BusinessCard from './Component/BusinessCard'
import { getAllBusinesses } from '../../CustomerStateManagement/CustomerState'

const BusinessList = () => {

  const [businesses, setBusinesses] = useState([])

  useEffect(()=>{
    const getBusiness = async() => {
      const res = await getAllBusinesses()
      setBusinesses(res)
    }
    getBusiness()
  },[])

  return (
    <div className="l-inner">
      <div className='p-businessList__container m-margin__top--40'>
        {
          businesses.map((business,index)=>(
            <BusinessCard business={business} key={index} />
          ))
        }
      </div>
    </div>
  )
}

export default BusinessList