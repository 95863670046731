import React, { useEffect, useState } from 'react'
import ExImg from "../../../Assets/emptyAvatar.webp";
import { useLocation, useNavigate } from 'react-router-dom';
import { DiscountConvert, SubTypesConvert, priceFormat } from '../../utilities/global_function';
import { AiOutlineDelete } from 'react-icons/ai';
import { createAppt } from '../../CustomerStateManagement/CustomerState';
import toast, { Toaster } from 'react-hot-toast';
import { CustomerInterceptApiCall } from '../../utilities/checkCustomerJWT';
import { checkoutMembership, checkoutService, handleFreeTrialMembershipCheckout } from '../../CustomerStateManagement/Item';

const CheckoutPreview = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { itemObject, type, appt, subTypeId, subTypeIndex} = location.state

  const [price, setPrice] = useState(itemObject?.subTypes[subTypeIndex]?.price)
  const [taxAmount,setTaxAmount] = useState(price * (itemObject?.tax /100))
  const[discountAmount,setDiscountAmount] = useState(price * (itemObject?.subTypes[subTypeIndex].couponPercent / 100))
  const [processing, setProcessing] = useState(false)

  const Url = require("../../../backendURL");
  const customerID = localStorage.getItem("userId")

  useEffect(()=> {
    const res = CustomerInterceptApiCall()
    if(!res){
      navigate("/customer/login")
    }
  },[])

  const connectCheckout = () => {
    setProcessing(true)
    if(type == "service"){
      return handleServiceCheckout()
    }
    if(type == "product"){

    }
    if(type == "membership"){
      return handleMembershipCheckout()
    }
  }

  const handleServiceCheckout = async() => {
    const Jwt = localStorage.getItem("token")
    
    try{
      // Creating Appointment object before creating checkout session URL to get appointmentObjectId
      setProcessing(true)
      const dataForAppt = {
        "customer":customerID,
        "business":itemObject.business,
        "service":itemObject._id,
        "dateTime":appt,
        "subType":subTypeId,
        "length":1
      }
      const res = await createAppt(dataForAppt)
        try{
          // Create checkout session URL
          const dataForCheckout = {
            "product":itemObject._id,
            "quantity":1,
            "customer":customerID,
            "appointment":res,
            "price": subTypeId // BE will find price by the id for      dynamic
          }
          const link = await checkoutService(dataForCheckout)
          window.location.href = link
          setProcessing(false)
        }catch(err){
          // Error handling for Appointment Creation
          setProcessing(false)
          toast.error(err)
        }
    }catch(err){
      // Error handling for Subscription Creation
      setProcessing(false)
      toast.error(err)
    }
  }


  const handleMembershipCheckout = async() => {
    const fieldSet = {
      product: itemObject?._id,
      quantity: 16, // need to change
      customer: customerID,
      price: subTypeId,
      }
    try{
      const res = await checkoutMembership(fieldSet)
      window.location.href = res
      setProcessing(false)
    }catch(err){
      console.log(err)
      setProcessing(false)
    }

  }

  const freeTrialCheckout = async() => {
    try{
      console.log("Free Trial Start")
      const fieldSet = {
        product: itemObject?._id,
        quantity: 1, // need to change
        customer: customerID,
        price: subTypeId,
        }
      const res = await handleFreeTrialMembershipCheckout(fieldSet)
      console.log("end",res)
    }catch(err){
      console.log(err)
    }
  } 





  return (
    <div className='l-inner'>
      <Toaster />
      <div className='p-checkoutPreview__container'>
        <section className='p-checkoutPreview__reviewOrder'>
          <div className='p-checkoutPreview__sectionInner'>
            <h4 className='p-checkoutPreview__reviewOrder__title'>Review Order</h4>
            <div className='p-checkoutPreview__reviewOrder__infoLayout'>
              <div className='p-checkoutPreview__reviewOrder__imgWrapper'>
                <img src={`${Url}images/${itemObject?.images[0]}`} alt='selecting subscription image' />
              </div>
              <div className='p-checkoutPreview__reviewOrder__addresses'>
                <p>{itemObject?.businessName}</p>
                {/* <p>291 E 7th E, Ridge #512, Rexburg, ID</p> */}
              </div>
              <div className='p-checkoutPreview__reviewOrder__names'>
                <h5 className='p-checkoutPreview__reviewOrder__names--itemName'>{itemObject?.name}</h5>
                <p className='p-checkoutPreview__reviewOrder__names--description'>{itemObject?.description}</p>
              </div>
              <div className='p-checkoutPreview__reviewOrder__discounts'>
                <p className='p-checkoutPreview__reviewOrder__discounts--total'>Save ${priceFormat(discountAmount)}({itemObject?.couponPercent}%)   Applies {DiscountConvert(itemObject?.subTypes[subTypeIndex].couponInterval)}</p>
                <p className='p-checkoutPreview__reviewOrder__discounts--warning'>*Does not apply for first transaction <br /> Applies after first recurring transaction</p>

              </div>
              <div className='p-checkoutPreview__reviewOrder__types'>
                <p>{type}</p>
                <p>{SubTypesConvert(itemObject?.subTypes[subTypeIndex].interval_count,itemObject?.subTypes[subTypeIndex].interval)}</p>
              </div>
              <div className='p-checkoutPreview__reviewOrder__prices'>
                <p>price</p>
                <h5>${priceFormat(price)}</h5>
              </div>
            </div>
          </div>
        </section>
        <section className='p-checkoutPreview__orderSummery'>
        <div className='p-checkoutPreview__sectionInner'>
          <h5 className='p-checkoutPreview__orderSummery__title'>Order Summary</h5>
          {/* {
            type == "services" && (
            <div className='p-checkoutPreview__orderSummery__appointment'>
            <h6>First Appointment:</h6>
            <h6>November 3, 2023 : 10:00 am</h6>
          </div>
            )
          } */}
          <div className='p-checkoutPreview__orderSummery__priceLine'>
            <h6>{itemObject?.name}</h6>
            <h6>${priceFormat(price)}</h6>
          </div>
          <div className='p-checkoutPreview__orderSummery__priceLine'>
            <h6>Order Subtotal</h6>
            <h6>${priceFormat(price)}</h6>
          </div>
          <div className='p-checkoutPreview__orderSummery__priceLine'>
            <h6>Tax</h6>
          {
            itemObject?.tax ?
            <h6>${priceFormat(taxAmount)}</h6> : <h6>$ 0.00</h6>
          }
          </div>
          <div className='p-checkoutPreview__orderSummery__priceLine'>
            <h6>Order Total</h6>
            {
              itemObject?.tax ?
              <h6>${priceFormat(price + taxAmount)} </h6> :
              <h6>${priceFormat(price)} </h6>

            }
          </div>
        <button className='p-checkoutPreview__button' onClick={connectCheckout} disabled={processing}>{processing? "Redirecting to Stripe":"Checkout"}</button>
        </div>
        </section>
      </div>
      
    </div>
  )
}

export default CheckoutPreview