import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerInterceptApiCall } from "../utilities/checkCustomerJWT";

const ProtectedCustomerRoute = ({ children, path = "home" }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);

  useEffect(() => {
    const fetchUserAuth = async () => {
      const res = await CustomerInterceptApiCall();
      setUser(res);
      if (!res) {
        await localStorage.setItem("lastLoc", path);
        return navigate("/feed");
      }
    };
    fetchUserAuth();
  }, [user]);

  return children;
};

export default ProtectedCustomerRoute;
