import React, { useState, useRef, useContext, useEffect } from "react";
import PromotionCard from "./PromotionCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { AccountContext, FeedContext } from "../../../../Routes/CustomerRoutes";
import { getPromotions } from "../../../../CustomerStateManagement/FeedState";
import useUserLocation from "../../../../utilities/getLocation";

const Promotions = () => {
  const [promos, setPromos] = useState([]);
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const location = useUserLocation();

  useEffect(() => {
    if (!location?.geometry?.coordinates) return;
    let loc = location?.geometry?.coordinates;

    const fetchPromotions = async () => {
      try {
        const data = await getPromotions(loc);
        setPromos(data);
      } catch (err) {
        console.error("Error fetching promotions", err);
      }
    };

    fetchPromotions();
  }, [location]);

  const scrollRef = useRef(null);

  return (
    <div
      className="p-subscriptionFeed__promotions"
      style={promos.length === 0 ? { height: 0 } : {}}
      ref={scrollRef}
    >
        {promos?.map((promo, index) => (
          <PromotionCard key={index} promotion={promo} />
        ))}
    </div>
  );
};

export default Promotions;
