import React, { useContext, useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import {
  editAccountInfo,
  addLocation,
  getAccountById,
  deleteAddress,
  setPrimaryAddress,
} from "../../../CustomerStateManagement/CustomerState";
import { states } from "../../../../Component/Options/States";
import { AccountContext } from "../../../Routes/CustomerRoutes";
import { scrollToTop } from "../../../../GlobalComponents/Utils/scroll";
import { customerAddressValidation } from "../../../../Functions/Validation/customerAddressVaridation";

const ShippingInfo = () => {
  // Account State
  const [userInfo, setUserInfo] = useContext(AccountContext);
  const [newAddress, setNewAddress] = useState({
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "US",
  }); // for create new address
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "US",
  });
  const [addresses, setAddresses] = useState(userInfo?.addresses); // list of Address

  useEffect(() => {
    setAddresses(userInfo?.addresses);
  }, [userInfo]);
  // Internal state
  const [processing, setProcessing] = useState(false);

  // Add New Address
  const addNewAddress = async (e) => {
    e.preventDefault();
    const isFirst = userInfo?.addresses?.length === 0;

    if (customerAddressValidation(newAddress)) {
      try {
        // Updates location
        const response = await addLocation(newAddress);

        if (response.msg === "Location added") {
          toast.success("New address added");
          setAddresses((prevAddresses) => [...prevAddresses, newAddress]);
          setUserInfo((prev) => ({
            ...prev,
            addresses: [...prev.addresses, newAddress],
          }));
          if (!userInfo?.addresses || userInfo?.addresses?.length < 1) {
            swapPrimaryAddress(0);
          }

          setNewAddress({
            name: "",
            line1: "",
            line2: "",
            city: "",
            state: "",
            postal_code: "",
            country: "US",
          });
        } else {
          toast.error(
            "Error in updating address. Please fill out all fields with the corresponding information.",
          );
        }
      } catch (err) {
        toast.error("Unable to update Address.");
      }
    }
  };

  const swapPrimaryAddress = async (index) => {
    try {
      const res = await setPrimaryAddress(index);
      if (res && index === 0) {
        return;
      }
      if (res) {
        let addressesArr = userInfo?.addresses;
        let curPrimary = addressesArr[0];
        let newPrimary = addressesArr[index];
        addressesArr[0] = newPrimary;
        addressesArr[index] = curPrimary;
        setUserInfo((prev) => ({
          ...prev,
          addresses: addressesArr,
        }));
        setAddresses(addressesArr);
        toast.success(
          "Successfully switched primary address. This address will be the center of the search range.",
        );
      } else {
        toast.error("Error in updating primary address.");
      }
    } catch (error) {
      toast.error("Error in updating primary address.");
    }
  };

  // remove
  const removeAddress = async (index) => {
    if (index === 0) {
      toast.error(
        "Sorry you cannot delete primary address, you can create another primary address and delete this address later.",
      );
      return;
    }
    try {
      const res = await deleteAddress(index);
      if (res) {
        const newAddresses = userInfo.addresses.filter((_, i) => {
          return i !== index;
        });
        setUserInfo((prev) => ({
          ...prev,
          addresses: newAddresses,
        }));
        setAddresses(newAddresses);
        toast.success("Successfully removed address");
      } else {
        toast.error("Failed delete address, please try again");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Initiate Form
  const clearAddress = async (e) => {
    e.preventDefault();
    setAddress({
      name: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "US",
    });
  };

  return (
    <div className="p-shippingInfo-address-section">
      <Toaster />
      <div className="p-shippingInfo__newAddressInputContainer">
        <h5 className="p-shippingInfo__sectionTitle">Add a New Address</h5>
        <div
          className="settings-dropdown"
          style={{ border: "1px solid #e8e8e8", borderRadius: 12 }}
        >
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Address Name</label>
            <input
              className="c-input"
              type="text"
              style={{ width: "300px" }}
              placeholder={newAddress?.name}
              value={newAddress?.name}
              onChange={(e) =>
                setNewAddress((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Line1</label>
            <input
              className="c-input"
              type="text"
              style={{ width: "300px" }}
              placeholder={newAddress?.line1}
              value={newAddress?.line1}
              onChange={(e) =>
                setNewAddress((prev) => ({ ...prev, line1: e.target.value }))
              }
            />
          </div>
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Line2 (optional)</label>
            <input
              className="c-input"
              type="text"
              style={{ width: "300px" }}
              placeholder={newAddress?.line2}
              value={newAddress?.line2}
              onChange={(e) =>
                setNewAddress((prev) => ({ ...prev, line2: e.target.value }))
              }
            />
          </div>
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">City</label>
            <input
              className="c-input"
              type="text"
              style={{ width: "300px" }}
              placeholder={newAddress?.city}
              value={newAddress?.city}
              onChange={(e) =>
                setNewAddress((prev) => ({ ...prev, city: e.target.value }))
              }
            />
          </div>
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">State</label>
            <select
              className="c-input"
              style={{ width: "300px" }}
              placeholder={newAddress?.state}
              value={newAddress?.state}
              onChange={(e) =>
                setNewAddress((prev) => ({ ...prev, state: e.target.value }))
              }
            >
              {states.map((state, index) => (
                <option key={index}>{state.label}</option>
              ))}
            </select>
          </div>
          <div className="c-inputWrapper__vertical">
            <label className="c-input__label">Zip code</label>
            <input
              className="c-input"
              type="number"
              style={{ width: "50%" }}
              placeholder={newAddress?.postal_code}
              value={newAddress?.postal_code}
              onChange={(e) =>
                setNewAddress((prev) => ({
                  ...prev,
                  postal_code: e.target.value,
                }))
              }
            />
          </div>

          <div style={{ width: "100%", display: "flex" }}>
            <button
              type="submit"
              className="c-smallButton--black"
              onClick={(e) => clearAddress(e)}
              style={{ marginRight: "auto" }}
            >
              Reset
            </button>
            <button
              type="button"
              className="c-smallButton"
              onClick={(e) => addNewAddress(e)}
              style={{ marginLeft: "auto" }}
              disabled={processing}
            >
              {processing ? "Processing..." : "Register"}
            </button>
          </div>
        </div>
      </div>
      <div className="p-shippingInfo__addressList">
        <h5 className="p-shippingInfo__sectionTitle">Address List: </h5>
        <div
          className={
            addresses?.length > 0 ? "address-list" : "address-list--empty"
          }
        >
          {addresses?.length > 0 ? (
            addresses.map((addressItem, index) => (
              <div className="p-shippingInfo__addressBox">
                {index === 0 && (
                  <p style={{ color: "#C24D00" }}>Primary Address</p>
                )}
                <p>{addressItem?.name || `address - ${index + 1}`}</p>
                <label className="p-shippingInfo__existingAddress">
                  {addressItem?.line1},{" "}
                  {addressItem?.line2 ? `${addressItem?.line2}, ` : ""}
                  {addressItem?.city}, {addressItem?.state}{" "}
                  {addressItem?.postal_code}
                </label>
                {index !== 0 && (
                  <div className="p-shippingInfo__actionButtons">
                    <button
                      className="c-smallButton--black"
                      onClick={() => {
                        removeAddress(index);
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="c-smallButton"
                      style={{ marginLeft: "auto" }}
                      onClick={() => swapPrimaryAddress(index)}
                    >
                      Set as Primary
                    </button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <>
              <h6>You don't have any address registered</h6>
              <p>
                Please register your first address from the left form. It will
                help you to find your ideal subscriptions
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShippingInfo;
