import React from "react"
import ContentLoader from "react-content-loader"

const ItemCardSkelton1 = (props) => (
  <ContentLoader 
    speed={2}
    width={318}
    height={271}
    viewBox="0 0 318 271"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="108" y="126" rx="0" ry="0" width="0" height="210" /> 
    <rect x="0" y="0" rx="0" ry="0" width="318" height="180" /> 
    <rect x="15" y="225" rx="0" ry="0" width="130" height="15" /> 
    <rect x="15" y="255" rx="0" ry="0" width="204" height="15" /> 
    <rect x="15" y="195" rx="0" ry="0" width="185" height="19" />
  </ContentLoader>
)

export default ItemCardSkelton1