import React, { useContext, useEffect, useState } from 'react'
import { ItemDetailContext } from '../../ItemDetail'
import Url from "../../../../../backendURL"
import { useNavigate } from 'react-router-dom'
import { getAllMembershipByBusinessId } from '../../../../CustomerStateManagement/Item'
import SubscriptionCard from "../../../Feed/Components/SubscriptionComponents/SubscriptionCard"

const BusinessInformation = () => {

  const [_, business] = useContext(ItemDetailContext)
  const [items, setItems] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if(!business){return}
    const fetchBusiness = async() => {
      try{
        const res = await getAllMembershipByBusinessId(business?._id)
        console.log(res)
        setItems(res)
      }catch(err){
        console.log(err)
      }
    }
    fetchBusiness()
  },[business])



  return (
    <section className='p-itemDetail__sectionContainer p-itemDetail__businessInfo'>
      <div 
        className='p-itemDetail__storeFrontEntry'
        onClick={()=>navigate(`/customer/customerStoreFront/${business?._id}`)}
        >
        <img src={`${Url}images/${business?.avatarImg}`} alt="business avatar image" />
        <h5>{business?.businessName}<br />
          <p>{business?.location?.line2 != undefined
          ? `${business?.location?.line1} ${business?.location?.line2}, \n ${business?.location?.city}, ${business?.location?.state}, ${business?.location?.zipCode}`
          : `${business?.location?.line1}, \n ${business?.location?.city}, ${business?.location?.state}, ${business?.location?.zipCode}`}</p>
        </h5>
      </div>
      <div className='p-itemDetail__relatedItems'>
        {
          items.map((d,i) => (
            <SubscriptionCard product={d} />
          ))
        }
      </div>
    </section>
  )
}

export default BusinessInformation