import React from "react";
import { useContext, useEffect, useState, useRef } from "react";
import { ChatboxContext } from "./ChatboxContext";
import axios from "axios";
import { io } from "socket.io-client";
import {
  getCustomerToken,
  getBusinessToken,
} from "../../Functions/Auth/getToken";

const SendMessage = () => {
  const Url = require("../../backendURL");

  const [chatDraft, setChatDraft] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageClass, setImageClass] = useState(
    "p-chatbox__sender__imageContainer__subContainer__imageContainer__popup",
  );

  // this is to hold the socketio client and prevent it from updating
  // on component rerenders, it will be set in the onmount use effect
  const socketRef = useRef(null);

  const {
    chatOption,
    userId,
    isBusinessSide,
    onConvos,
    isMobile,
    messages,
    setMessages,
    newRealTimeMessage,
    setNewRealTimeMessage,
  } = useContext(ChatboxContext);

  // run only on initial component mount not every rerender
  useEffect(() => {
    // socket.io realtime chat stuff
    // create socket.io connection only if it doesn't already exist
    if (!socketRef.current) {
      socketRef.current = io(Url);
    }
    socketRef.current.on("connect", () => {});
    // this cleanup function disconnects socket
    return () => {
      socketRef.current.disconnect();
    };
    // xxx don't forget to add empty [] and it ran every time anything changed
  }, []);

  // everytime the conversation changes check if there is a draft
  // for that convo and if so set the input value to it
  useEffect(() => {
    // only run if a chatOption is selected or else you get errors
    if (chatOption) {
      // xxx reminder to myself you need to do anything client side like
      // gettting reference to dom element in use effect so it does it
      // after the frontend renders else you will get an error.
      const inputt = document.querySelector("#messageInput");
      const chatDraftt = localStorage.getItem(`${userId}/${chatOption._id}`);
      if (chatDraftt) {
        inputt.value = chatDraftt;
      } else {
        inputt.value = "";
      }
    }
    // socket server stuff every time a chatoption is selected
    // send message to socekt server to join chatroom the name
    // being the chatOption id... the server will also remove
    // the user from any rooms that they were already in
    socketRef.current.emit("selectedConvo", chatOption);
  }, [chatOption]);

  // save input draft on change
  function messageInputUpdated(event) {
    setChatDraft(event.target.value);
    if (event.target.value === "" || event.target.value === null) {
      localStorage.removeItem(`${userId}/${chatOption._id}`);
    } else {
      localStorage.setItem(`${userId}/${chatOption._id}`, event.target.value);
    }
  }

  // Sends new message, there are two ways to do this, one is for sending messages with an image attached, the other
  // is for messages without an image. Both are sending the message over to socket.io.
  async function sendNewMessage(e) {
    e.preventDefault();
    localStorage.removeItem(`${userId}/${chatOption._id}`);
    const messageInput = document.querySelector("#messageInput");
    const messageInputValue = messageInput.value;
    messageInput.value = "";
    const timeStamp = Date.now();
    const trimmedMessageInputValue = messageInputValue.trim();

    const fileInput = document.getElementById("itemImg").files[0];

    let buyerOrSeller;
    let token;

    if (isBusinessSide) {
      // FRom context state that should be available by the time this function happens
      buyerOrSeller = "seller";
      token = await getBusinessToken();
    } else {
      buyerOrSeller = "buyer";
      token = await getCustomerToken("/chatbox");
    }

    if (fileInput && trimmedMessageInputValue != "") {
      try {
        let data = new FormData();
        data.append("file", fileInput);
        let res = await axios.post(`${Url}images/upload`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (trimmedMessageInputValue != "") {
          const res2 = await fetch(
            `${Url}chatbox/new-message/${buyerOrSeller}/`,
            {
              method: "PATCH",
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                sender: userId,
                text: messageInputValue,
                image_key: res.data.file_json,
                date_now_exclusion: Date.now(),
                seen: false,
                chatbox_id: chatOption._id,
              }),
            },
          );

          if (res.status == "200") {
            socketRef.current.emit("sendMessageToSocServ", {
              sender: userId,
              text: messageInputValue,
              image_key: res.data.file_json,
              date_now_exclusion: timeStamp,
              seen: false,
              chatbox_id: chatOption._id,
              _id: Math.random()
                .toString(36)
                .substring(2, 4 + 2),
            });
          }

          document.getElementById("itemImg").value = null;
          setPreviewImage(null);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        if (trimmedMessageInputValue != "") {
          const res = await fetch(
            `${Url}chatbox/new-message/${buyerOrSeller}/`,
            {
              method: "PATCH",
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                sender: userId,
                text: messageInputValue,
                date_now_exclusion: Date.now(),
                seen: false,
                chatbox_id: chatOption._id,
              }),
            },
          );
          const data = await res.json();
          // real time socketio send message stuff
          // if message was successfully posted to db send it real time to user
          if (res.status == "200") {
            socketRef.current.emit("sendMessageToSocServ", {
              sender: userId,
              text: messageInputValue,
              date_now_exclusion: timeStamp,
              seen: false,
              chatbox_id: chatOption._id,
              _id: Math.random()
                .toString(36)
                .substring(2, 4 + 2),
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // This is for previewing the image.
  const handleClick = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  // This is for deleting the image in the message so that it can be easily cleared
  // if the user changed their mind about sending it.
  function clearFileInput() {
    document.getElementById("itemImg").value = null;
    setPreviewImage(null);
  }

  // For triggering zoom-in animation for when an image is clicked on
  function triggerAnimationZoomIn() {
    setOpen(true);
    setImageClass(
      "p-chatbox__sender__imageContainer__subContainer__imageContainer__popup--zoomIn",
    );
  }

  // For triggering zoom-out animation for when an zoom-in message is clicked out of
  function triggerAnimationZoomOut() {
    setImageClass(
      "p-chatbox__sender__imageContainer__subContainer__imageContainer__popup--zoomOut",
    );
    setTimeout(() => {
      setOpen(false);
    }, 500);
  }

  // get the id of the current active conversation
  // post the message to that conversation

  // socketio real time message receiving at any given time
  if (socketRef.current !== null) {
    socketRef.current.on("MessageFromSocServToAllInRoom", (incomingMSG) => {
      // set shared context storage with new message so that it can
      // be accessed in Messages component and update it's state
      setNewRealTimeMessage(incomingMSG);
    });
  }
  // everytime newrealtime msg changes update the messages
  // state so that it has it at the end and rerenders
  useEffect(() => {
    if (newRealTimeMessage) {
      setMessages([...messages, newRealTimeMessage]);
    }
  }, [newRealTimeMessage]);

  return (
    <main
      className={
        isMobile && onConvos
          ? "p-chatbox__conversations--hide"
          : isBusinessSide
          ? "p-chatbox__sender--seller"
          : "p-chatbox__sender--buyer" // Not mobile (default behavior)
      }
    >
      {chatOption ? (
        <form className="p-chatbox__sender__form" onSubmit={sendNewMessage}>
          <div>
            <p className="p-chatbox__sender__form__div--p1">
              Subscription: {chatOption.subscription_name}
            </p>
            <p className="p-chatbox__sender__form__div--p2">
              {isBusinessSide
                ? `Customer Name: ${chatOption.buyer_name}`
                : `Business Name: ${chatOption.business_name}`}
            </p>
          </div>
          <label className="p-chatbox__sender__form__label">
            <div className="p-chatbox__sender__form__label__fileInput">
              <p>+</p>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id={`itemImg`}
                hidden
                onChange={handleClick}
              />
            </div>
            <textarea
              className="p-chatbox__sender__form__label__input"
              onChange={messageInputUpdated}
              id="messageInput"
              rows={1} // Set the desired number of rows
              maxLength={300}
              placeholder="Message"
              required
            />
            <button
              className={
                isBusinessSide
                  ? "p-chatbox__sender__form__label__button--seller"
                  : "p-chatbox__sender__form__label__button--buyer"
              }
            >
              Send
            </button>
          </label>
          {previewImage ? (
            <div className="p-chatbox__sender__imageContainer__subContainer__imageContainer">
              <img
                onClick={triggerAnimationZoomIn}
                className="p-chatbox__sender__imageContainer__subContainer__imageContainer__image"
                src={previewImage}
                alt="Uploaded preview"
              />
              <div className="p-chatbox__sender__imageContainer__subContainer__imageContainer__closeButtonContainer">
                <button
                  onClick={clearFileInput}
                  className="p-chatbox__sender__imageContainer__subContainer__imageContainer__closeButtonContainer__closeButton"
                >
                  X
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {previewImage && open ? (
            <div onClick={triggerAnimationZoomOut} className="overlay">
              <section
                className="l-popupForm"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  className={imageClass}
                  src={previewImage}
                  alt="Uploaded preview"
                />
              </section>
            </div>
          ) : (
            ""
          )}
        </form>
      ) : (
        <p
          style={{
            textAlign: "center",
            margin: "50px auto",
          }}
        >
          Select A Conversation To Message
        </p>
      )}
    </main>
  );
};

export default SendMessage;
