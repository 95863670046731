export const colorSchema = {
  "service":"#407B1B",
  "product":"#CA0303",
  "membership":"#063CA6",
  "customer":"#e5704c",
  "business":"#383966"
}

export const colorSchemaMedium = {
  "service":"#85D477",
  "product":"#BF7672",
  "membership":"#BDBFF4"
}

export const colorSchemaLight = {
  "service":"#E5EEE8",
  "product":"#DD04041A",
  "membership":"#0250E81A"
}