import React, { useEffect, useState } from 'react'
import SubscriptionCard from './SubscriptionCard'
import { getObjectsForMySubscription } from '../../../CustomerStateManagement/SubscriptionsRoute'

const History = () => {

  const [history, setHistory] = useState([])

  useEffect(() => {
    const fetchHistories = async() => {
      const res = await getObjectsForMySubscription(true)
      console.log(res)
      setHistory(res)
    }
    fetchHistories()
  },[])
  return (
    <section className='l-inner'>
      <h4 className='m-margin__vertical--24'>Cancelled Items</h4>
      {
        history.map((item, index) => (
          <SubscriptionCard data={item} type={item.type} historyPage={true} />
    
        ))
      }
    </section>
  )
}

export default History