/*******************************************************************************************************
 * Function/File Purpose: This file contains all API Calls that related to the Customer Views and Pages
 * Author: Lakeram Narine
 * Date: 6/1/2023
 *
 * **********************************
 * Contributor: Aoi Kuriki
 * Date: 6/7/2023
 * What was Done: Add getProductByBusiness and GetAllBusiness
 * *******************************
 * Constributor: Aoi Kuriki
 * Date: 7/6/2023
 * What was done: I add service controller
 * ********************************
 */

import axios from "axios";
import { getAdminToken, getCustomerToken } from "../../Functions/Auth/getToken";

// All variables
const Url = require("../../backendURL");
const accountId = localStorage.getItem("userId");

/*****************************************************************
Get all Products: When calling this function you have to add this line to wait and recieve the data

   const fetchData = async () => {
      const result = await getAllProducts();
      setAllProducts(result); // your useState variable
      };
      fetchData();
*****************************************************************/

/*---------------------  Service ---------------------*/

/************************************************************
 * Get All Service
 *
 *******************************************************/

/*************************************
 * Get all Services for a Business
 ***********************************/

/*************************************
 * Get a Services By id
 ***********************************/

/*************************************
 * Get all Services by Category
 ***********************************/

/*************************************
 * Get all Services by Category
 ***********************************/

export const getSubCount = async (serviceId, subCountId) => {
  try {
    const response = await axios.get(
      `${Url}services/getSlots/${serviceId}/${subCountId}`,
    );
    if (response.status == 200) {
      const data = response.data;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*---------------------  Product ---------------------*/

/************************************************************
 * Get All Product
 *
 *******************************************************/

/*************************************
 * Get all Products for a Business
 ***********************************/

/*************************************
 * Get Products by ID
 ***********************************/

/*************************************
 * Get all Products by Category
 ***********************************/

/*---------------------  Business ---------------------*/

/*************************************
 * Get all Business
 ***********************************/

export const getAllBusinesses = async () => {
  try {
    const response = await axios.get(`${Url}business`);
    if (response.status == 200) {
      let data = await response.data.docs;
      data = data.filter(
        (business) =>
          business?.avatarImg !== "sample" && business?.avatarImg !== "-1",
      );
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Get all Business
 ***********************************/

export const getAllBusinessesInZipArea = async (zipCode) => {
  try {
    const response = await axios.get(`${Url}business/zipCode/${zipCode}`);
    if (response.status == 200) {
      let businessRes = response.data.docs;
      businessRes = businessRes.filter(
        (business) =>
          business?.avatarImg !== "sample" && business?.avatarImg !== "-1",
      );
      return businessRes;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/************************************************************
 * Get Business by ID
 *
 *******************************************************/
export const getBusinessById = async (id) => {
  try {
    const response = await axios.get(`${Url}business/${id}`);
    if (response.status == 200) {
      const data = await response.data.docs;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/************************************************************
 * Get Business by Query
 *
 *******************************************************/

export const getBusinessByQuery = async (category) => {
  try {
    const response = await axios.get(`${Url}business/search/${category}`);
    if (response.status == 200) {
      const data = await response.data.businesses;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*---------------------  Account ---------------------*/

/*************************************
 * User login
 ***********************************/

export const userLogin = async (data) => {
  try {
    const res = await axios.post(`${Url}accounts/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status == 200) {
      const path = localStorage.getItem("lastLoc");
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      localStorage.setItem("userId", res.data.userId);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("isAdmin", res.data.userRole);
      window.location.replace(path === null ? "/feed" : path);
      if (path) {
        localStorage.removeItem("lastLoc");
      }
    }
  } catch (err) {
    throw err.response.data.err;
  }
};

/*************************************
 * isLoggedIn : Check if a customer is logged in, returns bool
 ***********************************/

export function isLoggedIn() {
  return localStorage.getItem("isLoggedIn");
}

/*************************************
 * Business login
 ***********************************/

export const businessLogin = async (data) => {
  try {
    const res = await axios.post(`${Url}business/login/businessLogin`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status == 200) {
      localStorage.setItem("B-token", res.data.businesstoken);
      localStorage.setItem("B-refreshToken", res.data.businessrefreshToken);
      localStorage.setItem("businessId", res.data.businessId);
      localStorage.setItem("isBusinessLoggedIn", true);

      const liveBusinessIDs = [
        "6531afd928b8dac42753c310",
        "64d3ceba267cecc46acd4a43",
        "65676727b91136b3e30e8250",
        "656e37446edd0f7e72ee4f6c",
        "656f73d45c1917ea3c5d3fba",
        "6573bdb2173951690b90b95e",
        "6577535623626ee1cfe34a75",
        "65b9931f0eac4f192a2f34c8",
        "65baaaa6318b16cdb0db7fca",
        "65cbaa18af8ec2d2fa2ae072",
        "65cfc0ce00f65d42f3c22615",
        "65d64bb507689f33f673e4c0",
        "65d7caa4d2a29b9f2a0f5d18",
        "65df6a7dc35915ed1b67befd",
        "65ea3b4157e3ae11f8508f07",
        "65f374be20ad6eb1dde001d6",
        "65f7a44410eefeb6fa878e45",
        "660ad1683fd3b217ec62cb7b",
      ];

      window.location.replace("/businessportal/dashboard");
      
      // const isStripe = res.data.isStripeApproved;
      // if (isStripe || liveBusinessIDs.includes(res.data.businessId)) {
      // window.location.replace("/businessportal/dashboard");
      // } else {
      //   window.location.replace("/businessportal/storeSetup");
      // }
    }
  } catch (err) {
    throw err.response.data.err;
  }
};

/*************************************
 * forget password first step
 ***********************************/

export const forgetPasswordPostEmail = async (email) => {
  try {
    const res = await axios.post(`${Url}accounts/passwordReset`, {
      email: email,
    });
    if (res.status === 200) {
      return true;
    } else {
      console.log("error on reset Password");
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

/*************************************
 * forget password second step
 ***********************************/

export const createNewPasswordForForgotten = async (
  password,
  paramAccountId,
  paramToken,
) => {
  try {
    const res = await axios.post(
      `${Url}accounts/passwordReset/${paramAccountId}/${paramToken}`,
      { newPassword: password },
    );
    if (res.status === 200) {
      return true;
    } else {
      console.log("error on reset Password");
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

/*************************************
 * get Account By Id
 ***********************************/

export const getAccountById = async () => {
  try {
    const token = await getCustomerToken();
    const response = await axios.get(`${Url}accounts/${accountId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status == 200) {
      const data = await response.data.doc;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Edit Account Information
 ***********************************/

export const editAccountInfo = async (data) => {
  console.log("Data in edit account info", data);
  try {
    const token = await getCustomerToken("/user");
    const res = await axios.patch(`${Url}accounts/edit/${accountId}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response in edit account info", res);

    return res.data.msg;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * AddLocation
 ***********************************/

export const addLocation = async (address) => {
  try {
    const token = await getCustomerToken();
    const res = await axios.patch(
      `${Url}accounts/addNewLocation/${accountId}`,
      { address },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Swap Primary Address
 ***********************************/
export const setPrimaryAddress = async (index) => {
  try {
    const token = await getCustomerToken();
    const res = await axios.patch(
      `${Url}accounts/switchPrimaryLocation/${accountId}`,
      { index },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if(res.status === 200){
      return true;
    }else{
      return false
    }

  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};

/*************************************
 * Delete Address
 ***********************************/
// Primary address shouldn't be deleted
export const deleteAddress = async (index) => {
  try {
    const token = await getCustomerToken();
    const res = await axios.patch(
      `${Url}accounts/removeLocation/${accountId}`,
      { index },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if(res.status === 200){
      return true
    }

    return res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};


/*************************************
 * Edit Password
 ***********************************/

export const editPassword = async (oldPassword, newPassword) => {
  try {
    const token = await getCustomerToken("/user");
    await axios.patch(
      `${Url}accounts/edit-password/${accountId}`,
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error.response.data.err;
  }
};

/*---------------------  Image ---------------------*/

/*************************************
 * Get image from AWS
 ***********************************/

export const getImage = async (imageCode) => {
  try {
    const response = await fetch(`${Url}images/${imageCode}`);
    if (response.status == 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*************************************
 * Edit Stripe Access
 ***********************************/

export const customerStripeAccess = async () => {
  try {
    const token = await getCustomerToken("/user");
    const res = await axios.get(`${Url}accounts/stripePortal/${accountId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status == 200) {
      return res.data.link;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw "Some error happened";
  }
};

/*---------------------  Time Availability ---------------------*/

/*************************************
 * Create Appointment Object
 ***********************************/

export const createAppt = async (data) => {
  try {
    const token = await getCustomerToken();
    const res = await axios.post(`${Url}appointments/add`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status == 200) {
      return res.data.doc;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw "Appointment Creation failed";
  }
};

/*************************************
 * Delete Account
 ***********************************/

export const deleteAccount = async () => {
  try {
    const token = await getCustomerToken("/user");
    const res = await axios.patch(
      `${Url}accounts/delete/${accountId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log("delete", res);
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};

/*************************************
 * get wishlist
 ***********************************/
export const getWishlist = async (req, res) => {
  try {
    const token = await getCustomerToken();
    const response = await axios.get(`${Url}accounts/wishlist/${accountId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      const data = await response.data.account.wishlist;
      return data;
    } else {
      return "Error response was not 200";
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return "There was an error";
  }
};

/*************************************
 * update wishlist
 ***********************************/
export const updateWishlist = async (itemId, date, itemType) => {
  try {
    const token = await getCustomerToken();
    const response = await axios.put(
      `${Url}accounts/wishlist/${accountId}`,
      {
        itemId: itemId,
        date: date,
        itemType: itemType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status == 200) {
      const data = await getWishlist();
      return data;
    } else {
      return "There was an error";
    }
  } catch (error) {
    console.error("Error updating data:", error);
    return [];
  }
};

export const deleteWishlist = async (data) => {
  try {
    const token = await getCustomerToken();
    const response = await axios.patch(
      `${Url}accounts/wishlist/${accountId}`,
      {
        id: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status == 200) {
      return response.data;
    } else {
      return "Error in deletion";
    }
  } catch (error) {
    console.error("Error in deleting data", error);
    return [];
  }
};

/*************************************
 * get any Account By Id
 ***********************************/

export const getPublicAccountById = async (publicAccountId) => {
  try {
    const response = await axios.get(
      `${Url}accounts/public/${publicAccountId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      const data = await response.data.doc;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

/*---------------------  Recommendations ---------------------*/

/*************************************
 * Create Recommendation
 ***********************************/

export const createRecommendation = async (recommendation) => {
  try {
    const response = await axios.post(
      `${Url}recommendations/create`,
      { recommendation },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error creating recommendation:", error);
  }
};

export const customerSignOut = () => {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("userId");
  localStorage.removeItem("token");
  window.location.assign("/login");
};
