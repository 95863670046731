import React, { useEffect } from "react";
import BusinessHero from "./components/BusinessHero";
import { useNavigate } from "react-router-dom";
import FeatureSection from "./components/FeatureSection";
import { CustomerInterceptApiCall } from "../../Customer_Module/utilities/checkCustomerJWT";
import ContactUs from "./components/ContactUs";
import FAQ from "./components/FAQ";
import PromoVid from "./components/PromoVid";
import MotionWrapper from "./components/MotionWrapper";

export default function LandingPage() {
  const navigate = useNavigate();

  const handleNavClick = (route) => {
    navigate(`/${route}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectActiveUser = async () => {
      const customerJWTtoken = await CustomerInterceptApiCall();
      const referer = document.referrer;
      const hostDomain = window.location.hostname;
      const sStr = "^https?://" + hostDomain;
      const rExp = new RegExp(sStr, "i");
      console.log(referer.match(rExp));
      if (customerJWTtoken && !referer.match(rExp)) {
        navigate("/feed");
      }
    };

    redirectActiveUser();
  }, []);

  return (
    <>
      <div className="landingPage">
        <BusinessHero navigate={handleNavClick} />
        <FeatureSection />
        <MotionWrapper>
          <PromoVid />
        </MotionWrapper>
        <FAQ />
        <ContactUs />
      </div>
    </>
  );
}
