import React from "react";
import ReviewList from "./ReviewList";
import NewReview from "./ NewReviewForm";
import ReviewSummary from "./ReviewRatingsSection";

export default function ReviewContainer({ itemId, businessId }) {
  return (
    <div className="reviewsContainer">
      <div className="createReviewSection">
        <NewReview itemId={itemId} businessId={businessId} />
      </div>
      <div className="reviewSummary">
        <ReviewSummary />
      </div>
      <div className="reviewListSection">
        <ReviewList itemId={itemId} />
      </div>
    </div>
  );
}
