import React from "react";

const AccountDetailContainer = ({ subtitle, children }) => {
  return (
    <div className="accountDetailContentContainer">
      <div>
        <p>{subtitle}</p>
      </div>
      {children}
    </div>
  );
};

export default AccountDetailContainer;
