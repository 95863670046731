import React from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import subittBanner from "../../../../Assets/subittBanner.jpg";

const Url = require("../../../../backendURL");

const SearchBusinessCard = ({ business }) => {
  return (
    <Link
      className="c-searchBusinessCard"
      to={`/customerStoreFront/${business?.properties?.id}`}
    >
      <img
        src={`${Url}images/${business?.properties?.banner}`}
        onError={(e) => {
          e.target.src = subittBanner; // Default to our banner
        }}
        alt={`${business?.properties?.title} logo`}
        className="c-searchBusinessCard__image"
      />
      <div className="c-searchBusinessCard__name">
        <h4>{business?.properties?.title}</h4>
        {business?.properties?.sellerPlus ? (
          <p className="c-searchBusinessCard__sellerPlus">+</p>
        ) : (
          <></>
        )}
      </div>
      {business?.properties?.categories && (
        <div className="c-searchBusinessCard__categories">
          {business?.properties?.categories
            ?.slice(0, 3)
            .map((category, index) => (
              <p key={index}>{category}</p>
            ))}
        </div>
      )}
      {/* <p className="c-searchBusinessCard__description">
      {business?.properties?.description}
    </p> */}
      <span className="c-searchBusinessCard__ratings">
        <p>{business?.properties?.averageRating?.toFixed(1)}</p>
        <FaStar color="#ff642d" size={15} />
        <p className="c-searchBusinessCard__ratings__count">
          ({business?.properties?.reviewCount} reviews)
        </p>
      </span>
    </Link>
  );
};

export default SearchBusinessCard;
