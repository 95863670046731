import React from "react";
import { Link } from "react-router-dom";
const Url = require("../../backendURL");

const BrowseBusinessCard = ({ business }) => {
  return (
    <Link
      className="c-browseBusCard"
      to={`/customerStoreFront/${business._id}`}
    >
      <div className="c-browseBusCard__logo">
        <img
          src={`${Url}images/${business.bannerImg}`}
          alt={`${business.businessName} logo`}
        />
      </div>

      <h1>{business.businessName}</h1>
    </Link>
  );
};

export default BrowseBusinessCard;
