const Categories = [
  {
    name: "Trending",
    route: "trending",
    icon: "",
    subcategories: [
      { title: "New Arrivals", route: "new" },
      { title: "Best Selling", route: "new" },
      { title: "Top Rated Selling", route: "new" },
      { title: "Trending", route: "new" },
    ],
  },
  {
    name: "Food",
    icon: "",
    route: "food",
    subcategories: [
      { title: "Restaurants", route: "restaurants" },
      { title: "Coffee", route: "coffee" },
      { title: "Drinks", route: "drinks" },
      { title: "Bakeries", route: "bakeries" },
      { title: "Sweets", route: "sweets" },
      { title: "Specialty Goods", route: "specialty" },
    ],
  },
  {
    name: "Memberships",
    icon: "",
    route: "memberships",
    subcategories: [
      { title: "Gyms", route: "gym" },
      { title: "Zoos", route: "zoo" },
      { title: "Museums", route: "museums" },
    ],
  },

  {
    name: "Home",
    icon: "",
    route: "home",
    subcategories: [
      { title: "Home Goods", route: "home-goods" },
      { title: "Home Care", route: "home-care" },
      { title: "Groceries", route: "groceries" },
      { title: "Childcare", route: "childcare" },
      { title: "Drycleaning", route: "drycleaning" },
      { title: "Baby", route: "baby" },
      { title: "Cleaning", route: "cleaning" },
      { title: "Window Cleaning", route: "window-cleaning" },
      { title: "Pest Control", route: "pest-control" },
      { title: "Landscaping", route: "landscaping" },
      { title: "Lawn Care", route: "lawn-care" },
      { title: "Plant Care", route: "plant-care" },
      { title: "Maintenance", route: "maintenance" },
      { title: "Auto", route: "automotive" },
    ],
  },
  {
    name: "Tech",
    icon: "",
    route: "tech",
    subcategories: [
      { title: "Tech", route: "tech" },
      { title: "Idk different SAAS categories or something", route: "idek" },
    ],
  },
  {
    name: "Pet Care",
    icon: "",
    route: "pets",
    subcategories: [
      { title: "Pet Grooming", route: "pet-grooming" },
      { title: "Pet Food", route: "pet-food" },
    ],
  },
  {
    name: "Recreation",
    icon: "",
    route: "recreation",
    subcategories: [
      { title: "Soccer", route: "soccer" },
      { title: "Golf", route: "golf" },
      { title: "Football", route: "football" },
    ],
  },
  {
    name: "Beauty",
    icon: "",
    route: "beauty",
    subcategories: [
      { title: "Self Care", route: "self-care" },
      { title: "Salon", route: "salon" },
      { title: "Skincare", route: "skincare" },
      { title: "Hair Care", route: "haircare" },
      { title: "Makeup", route: "makeup" },
      { title: "Nails", route: "nails" },
      { title: "Eyebrow", route: "Eyebrow" },
    ],
  },
  {
    name: "Self Care",
    icon: "",
    route: "self-care",
    subcategories: [
      { title: "Fitness", route: "fitness" },
      { title: "Health Care", route: "health-care" },
      { title: "Financial", route: "Financial" },
    ],
  },
  {
    name: "Outdoor",
    icon: "",
    route: "outdoor",
    subcategories: [
      { title: "Hiking", route: "hiking" },
      { title: "Backpacking", route: "backpacking" },
      { title: "Camping", route: "camping" },
    ],
  },
  {
    name: "Art",
    icon: "",
    route: "art",
    subcategories: [{ title: "Supplies", route: "Supplies" }],
  },
];

export default Categories;
