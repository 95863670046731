export const businessSignOut = () => {
  localStorage.removeItem("B-token");
  localStorage.removeItem("B-refreshToken");
  localStorage.removeItem("IsOwner");
  localStorage.removeItem("B-refreshToken");
  localStorage.removeItem("isBusinessLoggedIn");
  localStorage.removeItem("businessId");
  window.location.assign(`/businessportal/registrationPage`);
};

export const customerSignOut = () => {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("userId");
  localStorage.removeItem("token");
  // navigate("/login");
  window.location.reload(false);
};
