/*******************************************************************************************************
 * New Item card with Samantha's design
 * Author: Aoi Kuriki
 * Date: 10/11/2023
 *
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * *******************************
 * Contributor:
 * Date:
 * What was done:
 * ********************************
 */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  monthlyConvert,
  priceFormat,
} from "../../../utilities/global_function";
import HeartButton from "../../../../Component/Atom/HeartButton";
import { getWishlist } from "../../../CustomerStateManagement/CustomerState";

const ItemCard = React.memo(({ item_data, index }) => {
  const navigate = useNavigate();
  const [data, setProduct] = useState(item_data);
  const [imageSrc, setImageSrc] = useState(null);
  const Url = require("../../../../backendURL");
  const [curIndex, setCurIndex] = useState(0);

  const [liked, setLiked] = useState(false);
  const [wishlist, setWishlist] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    findCheapest();
    fetchWishlist();
  }, []);

  const fetchWishlist = async () => {
    const userWishlist = await getWishlist();
    setWishlist(userWishlist);

    for (let index in userWishlist) {
      if (userWishlist[index].itemId === data._id) {
        setLiked(true);
      }
    }
    setLoading(false);
  };

  const findCheapest = () => {
    const cheapestIndex = data?.subTypes.reduce(
      (minIndex, current, index, array) => {
        return current.price < array[minIndex].price ? index : minIndex;
      },
      0,
    );
    setCurIndex(cheapestIndex);
  };

  // Memoize the image source so it doesn't change unnecessarily
  const memoizedImageSrc = useMemo(() => {
    // If image source is already loaded, return it
    if (imageSrc) {
      return imageSrc;
    }
    // If data.images exists, fetch the image source from S3
    if (data?.images && data.images.length > 0) {
      const imageUrl = `${Url}images/${data.images[0]}`;
      // Set the image source in the state
      setImageSrc(imageUrl);
      // Return the fetched image source
      return imageUrl;
    }
    // Return a default image source if no image is available
    return "default-image-url";
  }, [data, imageSrc]);

  const subTypesIntervalPicker = {
    "2week": "Bi-Weekly",
    "1month": "Monthly",
    "2month": "Bi-Monthly",
    "3month": "Three-Monthly",
    "4month": "Four-Monthly",
    "6month": "half-Yearly",
  };

  const convertSubTypeShort = (interval_count, interval) => {
    let abbreviation = "" + interval_count + interval.charAt(0).toUpperCase();
    return abbreviation;
  };

  return (
    <article
      className="p-itemCard__container"
      key={index}
      onClick={() =>
        navigate(
          `/productDetail/${data?.itemType}/${data?.business}/${data?._id}`,
          { state: { chosenIndex: curIndex } },
        )
      }
    >
      <div className="p-itemCard__img">
        {loading ? (
          <p></p>
        ) : (
          <HeartButton
            liked={liked}
            wishlist={wishlist}
            className={"p-itemCard__img--heartButton"}
            data={data}
          />
        )}

        <img src={memoizedImageSrc} alt="itemImage" />
      </div>
      <h6 className="p-itemCard__itemName">{data?.name}</h6>
      <div className="p-itemCard__subTypes--container">
        {data.subTypes.map((s, i) => (
          <div
            className="p-itemCard__subTypes"
            key={i}
            style={i === curIndex ? { backgroundColor: "#e5704c" } : {}}
            onMouseEnter={() => setCurIndex(i)}
            onClick={(e) => {
              e.preventDefault();
              setCurIndex(i);
            }}
          >
            <p style={i === curIndex ? { color: "white" } : {}}>
              {convertSubTypeShort(s.interval_count, s.interval)}
            </p>
          </div>
        ))}
      </div>
      <div className="p-itemCard__checkoutInfo">
        <h6 className="p-itemCard__price">
          $
          {data?.itemType == "product"
            ? (
                priceFormat(data?.subTypes[curIndex].price) - data?.shippingCost
              ).toFixed(2)
            : priceFormat(data?.subTypes[curIndex].price)}
          <label>
            {" "}
            ${" "}
            {priceFormat(
              data?.subTypes[curIndex].price /
                monthlyConvert(
                  data?.subTypes[curIndex]?.interval_count,
                  data?.subTypes[curIndex]?.interval,
                ),
            )}{" "}
            / mo
          </label>
        </h6>
        <label>
          {data?.subTypes[curIndex].couponInterval > 0
            ? `${data?.subTypes[curIndex].couponPercent}% off every ${
                data?.subTypes[curIndex].couponInterval === 1
                  ? ""
                  : data?.subTypes[curIndex].couponInterval
              } transaction${
                data?.subTypes[curIndex].couponInterval === 1 ? "" : "s"
              }`
            : ""}
        </label>
      </div>
      <p className="p-itemCard__subType">
        {
          subTypesIntervalPicker[
            `${item_data.subTypes[curIndex].interval_count}${item_data.subTypes[curIndex].interval}`
          ]
        }{" "}
        Subscription
      </p>
    </article>
  );
});

export default ItemCard;
