import React, { useContext, Suspense } from "react";
import ItemCardSkelton2 from "../../../utilities/ItemCardSkelton2";
import SubscriptionCard from "../../Feed/Components/SubscriptionComponents/SubscriptionCard";
import ItemNotFound from "./ItemNotFound";
import { FeedContext } from "../../../Routes/CustomerRoutes";
const ItemCard = React.lazy(() => import("../../HomePage/Components/ItemCard"));

const SubscriptionSearchResult = ({ subscriptions }) => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

  return (
    <div
      className="p-searchResult__subscriptions"
      style={
        subscriptions?.length > 2
          ? { display: "flex" }
          : { display: "contents" }
      }
    >
      {subscriptions?.length > 0 &&
        subscriptions?.map((i, index) => (
          <Suspense key={index} fallback={<ItemCardSkelton2 />}>
            <SubscriptionCard product={i} />
          </Suspense>
        ))}
    </div>
  );
};

export default SubscriptionSearchResult;
