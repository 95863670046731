import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { getMembershipById } from '../../CustomerStateManagement/Item'
import { getBusinessById } from '../../CustomerStateManagement/CustomerState'
import ItemInformation from './Components/ItemInformation/ItemInformation'
import BusinessInformation from './Components/BusinessInformation/BusinessInformation'
import ReviewContainer from './Components/reviews/ReviewContainer'
import { getReviewsByItemId, getReviewsByUserId } from '../../CustomerStateManagement/ReviewState'

/*
============================================================
Initial Information
==========
File Content: 

Author: Aoi Kuriki
File Created Date: Jun 27, 2025
===================================================
*/
export const ItemDetailContext = createContext()
export const ReviewContext = createContext();

const ItemDetail = ({isPreview=false}) => {

  const { type, businessId, itemId } = useParams()
  const [data, setData] = useState({})
  const [business, setBusiness] = useState({})
  const [loading, setLoading] = useState(true)
  // console.log("Query", type, businessId, itemId)
   // Set up reviews functions
   const [reviews, setReviews] = useState(); // Holds reviews of any specific item, set in ReviewContainer
   const [hasReviewed, setHasReviewed] = useState(); // On item detail page, shows if the user has reviewed a specific item
   const [filtered, setFiltered] = useState(0); // On item detail page, shows a specific message if there are no reviews with a certain filter



  useEffect(() => {

    // console.log(" useEffect",businessId, itemId)
    getItemInformation()
    // Fetch reviews
    
    fetchReviewsByItemId();
    
    setLoading(false)
  },[itemId])
  
  const fetchReviewsByItemId = async () => {
    const result = await getReviewsByItemId(itemId);
    const userReviews = result.filter(
      (review) => review.accountId === localStorage.getItem("userId"),
    );
    const otherReviews = result.filter(
      (review) => review.accountId !== localStorage.getItem("userId"),
    );

    const sortedReviews = [...userReviews, ...otherReviews];
    console.log(sortedReviews)

    //setReviews([...userReviews, ...otherReviews])
    setReviews(sortedReviews);
  };

  async function fetchOriginalReviews() {
    const userId = localStorage.getItem("userId");
    try {
      const response = await getReviewsByUserId(userId);

      // Check if any of the user's reviews has the current itemId
      const hasReviewedItem = response.some(
        (review) => review.itemId === itemId,
      );

      setHasReviewed(hasReviewedItem);
    } catch (error) {
      setHasReviewed(false);
    }
  }

  useEffect(() => {
    fetchOriginalReviews();
    //const userId = localStorage.getItem("userId");
    //setHasReviewed(reviews.some(review => review.accountId === userId));
  }, [reviews]);


  const getItemInformation = useCallback(async() =>{
    // console.log("called GetItem")
    if(itemId && businessId){
      try{
        const dataRes = await getMembershipById(itemId)
        console.log("item data",dataRes)
        setData(dataRes)

        const businessRes = await getBusinessById(businessId)
        console.log(businessRes)
        setBusiness(businessRes)
      }catch(err){
        console.log(err)
      }
    }else{

    }

  },[itemId])


  if(loading) return (<div>Loading</div>)
  return (
    <ItemDetailContext.Provider 
    value={[
      data,
      business,
      type,
      businessId,
      itemId
    ]}>
      <ReviewContext.Provider
      value={[
        reviews,
        setReviews,
        hasReviewed,
        setHasReviewed,
        filtered,
        setFiltered,
      ]}
    >

      <main className='l-inner'>
        <div className="p-itemDetail__wrapper">
        <ItemInformation />
        {/* <BusinessInformation /> */}
        <ReviewContainer itemId={itemId} businessId={businessId} />
        </div>
      </main>
      </ReviewContext.Provider>
    </ItemDetailContext.Provider>
  )
}

export default ItemDetail