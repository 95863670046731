import React, { useContext, useEffect } from "react";
import { FaUser, FaUserAlt } from "react-icons/fa";
import { AccountContext } from "../../../Routes/CustomerRoutes";

const Url = require("../../../../backendURL");

const Avatar = ({avatarImg}) => {

  const getAvatarSrc = () => {
    if (typeof avatarImg === "string") {
      return `${Url}images/${avatarImg}`;
    } else if (avatarImg instanceof File) {
      return URL.createObjectURL(avatarImg);
    }
    return null; // Return null if no avatarImg is available
  };

  const avatarSrc = getAvatarSrc();

  return (
    <div className="avatar-container">
      {avatarImg ? (
        <img src={avatarSrc} alt="User Avatar" className="avatar-img" />
      ) : (
        <FaUser className="default-icon" />
      )}
    </div>
  );
};

export default Avatar;
